export const ALL_LANGUAGES = [
    'en',
    'es',
    'pt',
    'fr',
    'it',
    'de'
] as const;

export type AppLanguage = typeof ALL_LANGUAGES[number];
