import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import { firebaseConfig } from "../config/firebase";

function initFirestore() {
    // log errors
    firebase.firestore.setLogLevel('error');
    // init
    const firestore = firebase.firestore(firebaseApp);
    return firestore;
}

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth(firebaseApp);
export const firestore = initFirestore();
export const db = firebase.database(firebaseApp);
export const storage = firebase.storage().ref();
export const analytics = firebase.analytics(firebaseApp);
export const functions = firebase.functions(firebaseApp);

export async function setLocalPersistence() {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}
