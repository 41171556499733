type ScheduleApiVersion = "v1"; // | "v2"

export interface ITargetSchedule {
    /**
     * API version.
     */
    version?: ScheduleApiVersion;
}

/*
 * V1
 */

export function isScheduleV1(schedule: ITargetSchedule): schedule is ITargetScheduleV1 {
    return schedule.version === "v1";
}

export interface ITargetScheduleV1 extends ITargetSchedule {
    version: "v1";
    schedule: IAlwaysSchedule | IWeekSchedule;
}

export type ScheduleType = "always" | "week";
export interface IScheduleType {
    type: ScheduleType;
}

export interface IAlwaysSchedule extends IScheduleType {
    type: "always";
}

export function newAlwaysTargetSchedule(): ITargetScheduleV1 {
    return {
        version: "v1",
        schedule: {
            type: "always",
        },
    };
}

export function newWeekTargetSchedule(week: Record<WeekDay, IWeekDayInterval>): ITargetScheduleV1 {
    return {
        version: "v1",
        schedule: {
            type: "week",
            week,
        },
    };
}

export function isAlwaysSchedule(s: IScheduleType): s is IAlwaysSchedule {
    return s.type === "always";
}

export type WeekDay = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
export const WEEK_DAYS: WeekDay[] = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export interface IWeekSchedule extends IScheduleType {
    type: "week";
    week: Record<WeekDay, IWeekDayInterval>;
}

export interface IWeekDayInterval {
    start: number; // in milliseconds from midnight (0 is midnight)
    end: number; // in milliseconds from midnight (0 is midnight)
}

// A day with an interval of 0 can be ignored.
export function isEmptyInterval(interval: IWeekDayInterval) {
    return interval.end - interval.start === 0;
}

export const DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
export const ALL_DAY: IWeekDayInterval = { start: 0, end: DAY_IN_MILLIS - 1 };
export const WEEK_DAY_DISABLED_INTERVAL: IWeekDayInterval = { start: 0, end: 0 };

export function isWeekSchedule(s: IScheduleType): s is IWeekSchedule {
    return s.type === "week";
}
