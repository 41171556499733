// prod
const firebaseConfigProd = {
    apiKey: "AIzaSyDvA_bElLPDSIDgkfxS20hV3qzvibMz4j0",
    authDomain: "qrme-dev.firebaseapp.com",
    databaseURL: "https://qrme-dev.firebaseio.com",
    projectId: "qrme-dev",
    storageBucket: "qrme-dev.appspot.com",
    messagingSenderId: "294808319772",
    appId: "1:294808319772:web:5b9a220e066ac9ddebacd9",
    measurementId: "G-1CPBJTQJTB",
};

// dev
const firebaseConfigDev = {
    apiKey: "AIzaSyD47oN7hHUadQrtw5fgJVyLiHnPfQVxMQE",
    authDomain: "esc-dev-1b306.firebaseapp.com",
    databaseURL: "https://esc-dev-1b306.firebaseio.com",
    projectId: "esc-dev-1b306",
    storageBucket: "esc-dev-1b306.appspot.com",
    messagingSenderId: "243783018583",
    appId: "1:243783018583:web:087dc5d3b6b0e4626cdb12",
};

export const firebaseConfig = process.env.REACT_APP_STAGE == "dev" ? firebaseConfigDev : firebaseConfigProd;