import * as React from "react";
import { lazy } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import { auth } from "./services/firebase";
import { getLogger } from "./utils/errors";
import { retry } from "./utils/lazyLoad";

const AuthenticatedApp = lazy(() => retry(() => import("./components/app/authenticatedApp")));
const UnauthenticatedApp = lazy(() => retry(() => import("./components/app/unauthenticatedApp")));

interface IAppState {
    loading: boolean;
    userId?: string;
}

export class App extends React.Component<{}, IAppState> {
    private readonly logger = getLogger(App.name);
    state: IAppState = {
        loading: true,
    };

    componentDidMount = () => {
        auth.onAuthStateChanged(async (userAuth) => {
            if (!!userAuth && !!userAuth.uid) {
                try {
                    this.setState({ userId: userAuth.uid });
                } catch (err) {
                    this.logger.error(err, { userAuth });
                } finally {
                    this.setState({ loading: false });
                }
            } else {
                // redirect to sign-in
                this.setState({ loading: false });
            }
        });
    };

    public render() {
        if (this.state.loading) {
            return this.renderLoading();
        }
        return (
            <React.Suspense fallback={this.renderLoading()}>
                {this.isUserAuthenticated() ? <AuthenticatedApp /> : <UnauthenticatedApp />}
            </React.Suspense>
        );
    }

    private isUserAuthenticated() {
        return !!this.state.userId;
    }

    private renderLoading = () => {
        return <div className="App-spinner">{/* TODO: figure out why reactstrap spinner doesn't work */}</div>;
    };
}
