import { URL } from "../settings";
import { LanguageStrings, RouteDeclaration } from "./lang-en";
import { ptText } from "./siteText/pt";

export const routesPt: RouteDeclaration = {
    "routes.index": "/",
    "routes.faq": "/questoes",
    "routes.contact": "/contato",
    "routes.terms": "/condicoes-de-servico",
    "routes.about": "/sobre",
    /** Unauth Routes */
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/inscrever-se",
    "routes.unauth.sign-in": "/login",
    /** Auth Routes */
    "routes.auth.home": "/inicial",
    "routes.auth.dashboard": "/painel-de-controle",
    "routes.auth.user-profile": "/perfil",
    "routes.auth.sign-out": "/sair",
    "routes.pricing": "/atualizar",
    "routes.auth.checkout.cancel": "/atualizar/cancelada",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.auth.analytics": "/analítica",
    "routes.pro-announcement": "/melhoria",
    "routes.auth.signup-offer": "/oferta-de-inscricao",
    "routes.auth.checkout.success": "/atualizar",
    "routes.auth.verify-email": "/verificar",
    "routes.privacy": "/privacidade",
};

export const routesTextPt: RouteDeclaration = {
    "routes.index": "Página Inicial",
    "routes.faq": "Perguntas Mais Frequentes",
    "routes.contact": "Contate-Nos",
    "routes.terms": "Termos de Serviço",
    "routes.about": "Sobre Nós",
    /** Unauth Routes */
    "routes.unauth.home": "Página Inicial",
    "routes.unauth.sign-up": "Inscrever-se",
    "routes.unauth.sign-in": "Fazer Login",
    /** Auth Routes */
    "routes.auth.home": "Página Inicial",
    "routes.auth.dashboard": "Painel de Controle",
    "routes.auth.user-profile": "Meu Perfil",
    "routes.auth.sign-out": "Sair",
    "routes.pricing": "Atualizar",
    "routes.auth.checkout.success": "Sucesso",
    "routes.auth.checkout.cancel": "Atualização cancelada",
    "routes.pro-announcement": "Anunciando o Pro",
    "routes.auth.signup-offer": "Oferta de inscrição",
    "routes.auth.edit-menu": "Editar página do menu",
    "routes.auth.analytics": "Análise do menu",
    "routes.auth.verify-email": "Verificar e-mail",
    "routes.privacy": "Política de Privacidade",
};

export const pt: Partial<LanguageStrings> = {
    ...routesPt,
    ...ptText,
};
