import { URL } from "../settings";
import { LanguageStrings, RouteDeclaration } from "./lang-en";
import { frText } from "./siteText/fr";

export const routesFr: RouteDeclaration = {
    "routes.index": "/",
    "routes.faq": "/questions",
    "routes.contact": "/contact",
    "routes.terms": "/termes",
    "routes.about": "/a-propos",
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/s-inscrire",
    "routes.unauth.sign-in": "/se-connecter",
    "routes.auth.verify-email": "/verify",
    "routes.auth.home": "/accueil",
    "routes.auth.dashboard": "/tableau-de-bord",
    "routes.auth.user-profile": "/profil",
    "routes.auth.sign-out": "/déconnexion",
    "routes.pricing": "/niveau",
    "routes.pro-announcement": "/upgrade/annonce",
    "routes.auth.signup-offer": "/offre-inscription",
    "routes.auth.checkout.success": "/niveau/succès",
    "routes.auth.checkout.cancel": "/niveau/annulee",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.auth.analytics": "/analytique",
    "routes.privacy": "/confidentialité",
};

export const routesTextFr: RouteDeclaration = {
    "routes.index": "Page d'accueil",
    "routes.faq": "Questions fréquemment posées",
    "routes.contact": "Nous contacter",
    "routes.terms": "Conditions d'utilisation",
    "routes.about": "À propos de l'ESC",
    /** Unauth Routes */
    "routes.unauth.home": "Accueil",
    "routes.unauth.sign-up": "Commencer",
    "routes.unauth.sign-in": "Se connecter",
    /** Auth Routes */
    "routes.auth.verify-email": "Vérifier les Courriels",
    "routes.auth.home": "Page d'accueil",
    "routes.auth.dashboard": "Tableau de bord",
    "routes.auth.user-profile": "Mon profil",
    "routes.auth.sign-out": "Déconnexion",
    "routes.pricing": "Upgrade",
    "routes.auth.signup-offer": "Offre d'inscription",
    "routes.pro-announcement": "Annonce de Pro",
    "routes.auth.checkout.success": "Succès",
    "routes.auth.checkout.cancel": "Mise à Niveau Annulée",
    "routes.auth.edit-menu": "Modifier la Page du Menu",
    "routes.auth.analytics": "Menu Analytique",
    "routes.privacy": "Politique de confidentialité",
};

export const fr: Partial<LanguageStrings> = {
    ...routesFr,
    ...frText,
};
