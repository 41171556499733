import { URL } from "../settings";
import { LanguageStrings, RouteDeclaration } from "./lang-en";
import { deText } from "./siteText/de";

export const routesDe: RouteDeclaration = {
    "routes.index": "/",
    "routes.faq": "/fragen",
    "routes.contact": "/kontakt",
    "routes.terms": "/begriffe",
    "routes.about": "/uber",
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/anmelden",
    "routes.unauth.sign-in": "/einloggen",
    "routes.auth.home": "/zuhause",
    "routes.auth.dashboard": "/instrumententafel",
    "routes.auth.user-profile": "/profil",
    "routes.auth.sign-out": "/ausloggen",
    "routes.pricing": "/aktualisierung",
    "routes.auth.checkout.success": "/aktualisierung/erfolg",
    "routes.auth.checkout.cancel": "/aktualisierung/abgebrochen",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.privacy": "/Privatsphäre",
    "routes.auth.analytics": "/analytics",
    "routes.pro-announcement": "/aktualisierung",
    "routes.auth.signup-offer": "/anmeldeangebot",
    "routes.auth.verify-email": "/überprüfen",
};

export const routesTextDe: RouteDeclaration = {
    "routes.privacy": "Datenschutz-Bestimmungen",
    "routes.index": "Startseite",
    "routes.faq": "Häufig gestellte Fragen",
    "routes.contact": "Kontaktiere uns",
    "routes.terms": "Nutzungsbedingungen",
    "routes.about": "Über ESC",
    "routes.unauth.home": "Startseite",
    "routes.unauth.sign-up": "Loslegen",
    "routes.unauth.sign-in": "Anmelden",
    "routes.auth.verify-email": "Verify Email",
    "routes.auth.home": "Startseite",
    "routes.auth.dashboard": "Instrumententafel",
    "routes.auth.user-profile": "Mein Profil",
    "routes.auth.sign-out": "Ausloggen",
    "routes.pricing": "Aktualisierung",
    "routes.pro-announcement": "Announcing Pro",
    "routes.auth.signup-offer": "Anmeldeangebot",
    "routes.auth.checkout.success": "Erfolg",
    "routes.auth.checkout.cancel": "Upgrade Abgebrochen",
    "routes.auth.edit-menu": "Edit Menu Page",
    "routes.auth.analytics": "Menu Analytics",
};

export const de: Partial<LanguageStrings> = {
    ...routesDe,
    ...deText,
};
