import { OFFERS } from "../../../api/settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";
import { LanguageStrings } from "../lang-en";

export const frText: Partial<LanguageStrings> = {
    

	// items added 2021-11-29 13:56:42.836671
	'nav.navigation-dropdown': `La navigation `,
	'error.contact-us-message': `Désolé, quelque chose s'est mal passé! Souhaitez-vous vous laisser savoir? Nous aimerions le réparer dès que possible. S'il vous plaît contactez-nous directement avec nous à `,
	'home.title.title': `Menus de code QR dynamique `,
	'home.title.subtitle': `Gardez vos clients en sécurité sans contact, menus numériques qui tournent automatiquement. `,
	'home.title.make-code-button': `Faire un code `,
	'home.preview.title': `Simple. Rapide. En sécurité. `,
	'home.preview.subtitle1': `Manger des codes sûrs prend environ deux minutes pour mettre en place. `,
	'home.preview.subtitle2': `Sérieusement, c'est tout. `,
	'home.preview.subtitle3': `Avec notre plate-forme, vous pouvez faire des codes QR qui permettront à votre restaurant de `,
	'home.preview.bullet1.1': `Affichage `,
	'home.preview.bullet1.2': `Menus multiples `,
	'home.preview.bullet1.3': `du même code QR `,
	'home.preview.bullet2.1': `Planifier les menus `,
	'home.preview.bullet2.2': `apparaître sur certains jours ou fois `,
	'home.preview.bullet3.1': `Personnaliser et concevoir `,
	'home.preview.bullet3.2': `vos codes QR et vos pages d'atterrissage de menu pour correspondre à votre marque `,
	'home.preview.banner1.1': `Concevoir `,
	'home.preview.banner1.2': `Ajoutez votre marque `,
	'home.preview.banner2.1': `Menus planifiés `,
	'home.preview.banner2.2': `Tourner automatiquement `,
	'home.preview.banner3.1': `Télécharger `,
	'home.preview.banner3.2': `Hébergez votre menu `,
	'home.desc.title': `Comment ça marche? `,
	'home.desc.desc': `Mangez des codes sûrs vous permet de télécharger plusieurs menus numériques (PDF, JPEG, PNG, etc.) et de les rendre accessibles aux clients via des codes QR numérisés à la table. Cela signifie que les clients réduisent la propagation potentielle des agents pathogènes de toucher des menus physiques. Vous pouvez définir des horaires pour les menus pour faire pivoter automatiquement afin que vous puissiez rester concentré sur ce qui compte le plus `,
	'home.desc.desc-bold': `Courir votre restaurant. `,
	'home.testimonial.text': `Nous avons été en mesure de réduire notre menu papier gaspiller une tonne, voir comme ils sont tous à usage unique. L'impression de menu était supérieure à 150 menus par jour. Maintenant, nous sommes peut-être jusqu'à 100 toutes les deux semaines. `,
	'home.testimonial.name': `- Drew, école publique 818, Los Angeles, États-Unis `,
	'home.photos.title': `Besoin de plus? Go Pro. `,
	'home.photos.body1': `Déverrouiller la pleine puissance de manger des codes sûrs avec le `,
	'home.photos.body2-bold': `Plan professionnel. `,
	'home.photos.body3': `Découvrez Scan Analytics, personnalisez la conception de vos codes QR et concevez les pages d'atterrissage que les clients voient. Faire partie de la solution et aider à arrêter la propagation de `,
	'home.photos.body4': `COVID-19 [FEMININE. `,
	'home.photos.button': `Consultez nos plans `,
	'home.benefits.title': `Le monde a changé. `,
	'home.benefits.subtitle': `Obtenez vos menus avec les temps `,
	'home.benefits.block1.title': `Protégez les clients et le personnel `,
	'home.benefits.block1.body1': `Protégez les clients qui attendent une expérience de salle à manger plus sûre. Protéger le personnel qui `,
	'home.benefits.block1.body2-bold': `risquer leur santé `,
	'home.benefits.block1.body3': `chaque jour pour interagir avec les convives. `,
	'home.benefits.block2.title': `Sauver des arbres, économiser de l'argent et économiser des heures `,
	'home.benefits.block2.body1': `Économisez sur imprimer de nouveaux menus. Imprimer et concevoir vos codes QR `,
	'home.benefits.block2.body2-bold': `une fois que `,
	'home.benefits.block2.body3': `, puis mettez à jour les menus de votre téléphone ou de votre ordinateur à tout moment. `,
	'home.benefits.block3.title': `Tournez automatiquement les menus `,
	'home.benefits.block3.body1': `Affichez le petit-déjeuner, le déjeuner et le dîner des menus sur des horaires hebdomadaires flexibles. Avoir un menu Happy Hour pour les mardi 17h à 19h et le vendredi 5h à 18h? `,
	'home.benefits.block3.body2-bold': `Aucun problème. `,
	'home.benefits.block4.title': `Pages d'atterrissage simples et élégantes pour plusieurs menus `,
	'home.benefits.block4.body1': `Présentez le menu Dîner avec une liste de vins. Apéritiers quotidiens avec les menus hebdomadaires. Nos menus s'adaptent à votre restaurant. `,
	'home.benefits.block4.body2-bold': `Tout d'un code `,
	'home.benefits.block4.body3': `. `,
	'home.support.title': `Merci de nous avoir soutenu! `,
	'home.support.subtitle': `Aidez-nous à garder le monde en sécurité. `,
	'faq.title.largetext': `FAQ `,
	'faq.title.title': `Questions fréquemment posées `,
	'faq.video.title': `Tutoriel rapide `,
	'faq.button.to-dashboard': `Retourner au tableau de bord `,
	'faq.questions.q1': `Qu'est-ce qu'un code QR? `,
	'faq.questions.a1': `Un code de référence rapide (QR) est composé de carrés noirs disposés dans une grille carrée sur un fond blanc, qui peut être lue par un dispositif d'imagerie telle que la caméra sur votre téléphone portable. Nos services permettent à vos clients de relier rapidement le menu de votre restaurant aux codes générés de manière unique pour votre entreprise. Cela crée une méthode sûre et sans contact à vos clients d'accéder à vos menus. `,
	'faq.questions.q2': `Comment mes clients utiliseront-ils mon code QR? `,
	'faq.questions.a2': `La plupart des smartphones modernes ont des lecteurs QR intégrés. Pour la plupart des appareils, les utilisateurs n'auront besoin que d'ouvrir leur appareil photo et de centrer le code (aucune photo requise). Un popup apparaît comme par magie à berger vos clients à votre menu ou à votre site Web. `,
	'faq.questions.q3': `Puis-je afficher plusieurs menus du même code QR à la fois? `,
	'faq.questions.a3': `Oui! Si plusieurs menus sont configurés pour apparaître, il fournira à votre client la possibilité de sélectionner et de naviguer entre eux. Si un seul est configuré pour apparaître, l'utilisateur sera envoyé directement dans ce menu. `,
	'faq.questions.q4': `Puis-je planifier certains menus apparaître à certains moments? `,
	'faq.questions.a4': `Oui! La plate-forme vous permet de configurer des menus à afficher sur un calendrier de complexité arbitraire. Cela signifie, par exemple, que vous pouvez configurer le brunch du week-end, le petit-déjeuner en semaine, une happy hour boivent des offres de boissons et / ou des spécialistes du dîner quotidien pour apparaître à leurs moments appropriés - tous depuis le même code QR! Vous avez également la possibilité de basculer / désactiver les menus du manager ou du tableau de bord du bureau pour les modifications du menu Fly. `,
	'faq.questions.q5': `Le tableau de bord de gestion fonctionne-t-il sur mobile? `,
	'faq.questions.a5': `Ouais! Vous êtes capable de faire des modifications aux planifications, à basculer des menus sur / off, ajoutez et supprimez des menus de votre appareil mobile. `,
	'faq.questions.q6': `Et si mon client n'a pas de smartphone QR Reader? `,
	'faq.questions.a6': `La plupart des appareils modernes ont des lecteurs de code QR intégrés. Cela dit, tous les applications d'Android et iPhone ont des applications de lecture QR qui peuvent être téléchargées gratuitement. Et comme sauvegarde, il est toujours utile d'avoir un menu de rechange disponible au cas où. `,
	'faq.questions.q8': `Et si mon menu change? `,
	'faq.questions.a8': `Vous pouvez ajouter, éditer, mettre à jour et supprimer des menus à tout moment en quelques clics. La plate-forme accepte la plupart des formats photo ainsi que des sources dynamiques telles que l'URL de votre site Web. `,
	'faq.questions.q10': `Puis-je avoir plusieurs codes QR différents définis sur différents restaurants ou planifications de menus? `,
	'faq.questions.a10': `Sûr peut! Il n'y a actuellement aucune limite pour le nombre de codes QR qu'un utilisateur peut générer. `,
	'faq.questions.q11': `Et si je supprime un code? Puis-je le récupérer? `,
	'faq.questions.a11': `Les codes QR peuvent être récupérés tant que la même liaison de vanité est utilisée lors de la recréation. `,
	'faq.questions.q12': `Puis-je modifier l'ordre dans lequel les menus sont montrés aux clients? `,
	'faq.questions.a12': `Absolument! Il suffit de glisser et de déposer des menus dans le tableau de bord pour réorganiser leur ordre d'affichage. `,
	'faq.questions.pro.q1': `Comment puis-je mettre à niveau? `,
	'faq.questions.pro.a1': `Vous pouvez mettre à niveau votre compte en visitant la page de mise à niveau. Notez que vous aurez une chance de revoir tous les frais avant de finaliser les paiements. `,
	'faq.questions.pro.q2': `Puis-je annuler mon abonnement à pro? `,
	'faq.questions.pro.a2': `Oui, à tout moment en visitant la section de facturation de votre profil d'utilisateur. Votre annulation prendra effet immédiatement et vos codes QR ne fonctionneront plus. `,
	'faq.questions.pro.q3': `Combien ça coûte? `,
	'faq.questions.pro.a3': `C'est gratuit! Tant que vous restez dans nos limites d'utilisation généreuses, nos services sont entièrement gratuits. Nous incluons de petites annonces non obstruées pour ce niveau afin que nous puissions vous offrir ce service sans frais. Si vous souhaitez éliminer ces annonces et déverrouillez tout un tas de fonctionnalités utiles, envisagez la mise à niveau vers un compte Pro. `,
	'faq.questions.pro.q4': `Qu'est-ce qui est inclus dans la version de démarrage? `,
	'faq.questions.pro.a4': `Toutes les fonctionnalités de base à afficher votre menu sont incluses dans la version du démarreur! Pour voir les limitations de cette option, veuillez afficher les limites de niveau. `,
	'faq.questions.pro.q5': `Que puis-je personnaliser? `,
	'faq.questions.pro.a5': `Notre option Pro vous permet de personnaliser à peu près tout! Vous pouvez concevoir votre propre code, modifier l'apparence et ajouter des logos personnalisés. Vous pouvez également modifier la page d'atterrissage que vos clients le verront de manière à ce qu'il soit personnalisé pour votre entreprise. `,
	'faq.questions.pro.q6': `Quelles sont les limites? `,
	'faq.questions.pro.a6': `Veuillez consulter la page de mise à niveau pour afficher les limites de niveau de numérisation, les téléchargements de menu, etc. `,
	'faq.questions.pro.q7': `Que se passe-t-il si je dépasse la limite? `,
	'faq.questions.pro.a7': `Vous ne serez pas verrouillé de votre compte si vous dépassez une limite. Cependant, la première fois que vous dépassez votre compte de numérisation autorisé, vous recevrez deux semaines avant que les menus ne soient pausés. Pour continuer, vous devez passer au niveau approprié. `,
	'faq.questions.pro.q8': `Y a-t-il une limite au nombre de codes que je puisse créer? `,
	'faq.questions.pro.a8': `Oui. Veuillez référer les niveaux de la page de mise à niveau. Il est important de noter que les codes archivés comptent vers cette limite. `,
	'faq.questions.pro.q9': `Y a-t-il une limite au nombre de menus que je peux créer? `,
	'faq.questions.pro.a9': `Oui. Veuillez trouver la limite de menu sur la table du plan. Vous pouvez supprimer des menus pour rester en dessous de votre limite de niveau. `,
	'faq.questions.pro.q10': `Y a-t-il une limite au nombre de téléchargements de fichier de menu que je peux faire par code? `,
	'faq.questions.pro.a10': `Oui. Veuillez trouver la limite de téléchargement de fichier sur la table du plan. Même si les menus sont supprimés ultérieurement, ils compteront toujours votre total. `,
	'faq.questions.pro.q11': `Comment fonctionnent les paiements? `,
	'faq.questions.pro.a11': `Pour les frais annuels, vous serez facturé une fois tous les 365 jours à partir des données du premier abonnement. Pour les frais mensuels, vous serez facturé tous les 30 jours à compter des données du premier abonnement. Votre carte de crédit sera chargée automatiquement à moins que vous annuliez votre abonnement. Nous ne sommes pas en mesure de fournir des remboursements partiels pour des périodes inutilisées. Toutes les transactions seront considérées comme définitives. `,
	'faq.questions.pro.q12': `Quelle monnaie puis-je payer? `,
	'faq.questions.pro.a12': `Nos partenaires de paiement prennent en charge plusieurs monnaies, mais tous les prix sont énumérés en dollars américains. `,
	'signup.title.title': `S'inscrire `,
	'signup.title.subtitle': `Ne vous inquiétez pas, votre compte n'expire pas et nous ne voulons pas votre carte de crédit. `,
	'signup.title.welcome': `Bienvenue au club. `,
	'signup.form.email': `E-mail `,
	'signup.form.email.placeholder': `exemple@email.com `,
	'signup.form.password': `Mot de passe `,
	'signup.form.password.placeholder': `Mot de passe `,
	'signup.form.first': `Prénom `,
	'signup.form.first.placeholder': `D'abord `,
	'signup.form.last': `Nom de famille `,
	'signup.form.last.placeholder': `Durer `,
	'signup.form.company': `Société `,
	'signup.form.company.placeholder': `Le meilleur restaurant de la ville `,
	'signup.form.submit': `S'inscrire `,
	'signup.redirect.text': `Déjà membre? S'identifier `,
	'signup.redirect.link': `ici `,
	'signin.title.title': `Bonjour, vieil ami `,
	'signin.title.subtitle': `Connectez-vous, allez-vous? `,
	'signin.form.email': `E-mail `,
	'signin.form.email.placeholder': `exemple@email.com `,
	'signin.form.password': `Mot de passe `,
	'signin.form.password.placeholder': `Mot de passe `,
	'signin.form.submit': `S'identifier `,
	'signin.form.reset-pwd.button': `Réinitialiser mon mot de passe `,
	'signin-form.reset-pwd.confirmation': `Merci! Si un compte existe pour cet e-mail, nous vous avons envoyé un email pour réinitialiser votre mot de passe. `,
	'signin-form.reset-pwd.link': `Mot de passe oublié? `,
	'signin-form.reset-pwd.link.finish': `Retournez à la connexion. `,
	'signin.redirect.text': `Pas un membre existant? S'inscrire `,
	'signin.redirect.link': `ici `,
	'dashboard.title.text1': `Bon travail `,
	'dashboard.title.text2': `Ces codes gardent le monde en bonne santé. `,
	'dashboard.button.text': `Créer un nouveau code QR `,
	'dashboard.button.upgrade': `Passer à Pro `,
	'dashboard.get-started.get-started': `Commencer! - `,
	'dashboard.get-started.get-started2': `Commencez par créer votre premier code QR et le fichier qu'il doit rediriger. `,
	'dashboard.get-started.add-menus': `Ce code ne lie pas encore à des menus! - `,
	'dashboard.get-started.add-menus2': `Cliquez ici pour ajouter votre premier menu. `,
	'dashboard.alerts.cant-verify': `Nous n'avons pas été en mesure de vérifier vos informations d'identification de l'utilisateur. Veuillez vous déconnecter et reconnecter. `,
	'dashboard.table-headers.Order': `Commander `,
	'dashboard.table-headers.Name': `Nom `,
	'dashboard.table-headers.Name.tooltip': `Ceci est le nom de menu des clients verront `,
	'dashboard.table-headers.Active.tooltip': `Définissez ceci pour désactiver ce menu `,
	'dashboard.table-headers.Schedule': `Calendrier `,
	'dashboard.table-headers.Version': `Version `,
	'dashboard.table-headers.Actions': `Actions `,
	'dashboard.pils.Always': `Toujours `,
	'dashboard.pils.Everyday': `Tous les jours `,
	'dashboard.pils.Never': `Jamais `,
	'dashboard.pils.Weekdays': `Jours de la semaine `,
	'dashboard.pils.Weekends': `Fins de semaine `,
	'dashboard.tooltips.header': `Faire en sorte `,
	'dashboard.tooltips.pro-section.if-pro': `Pro `,
	'dashboard.tooltips.pro-section.if-basic': `Mise à niveau pour les fonctionnalités pro `,
	'dashboard.tooltips.test-code': `Cliquez pour tester votre code QR (basé sur l'heure actuelle). `,
	'dashboard.tooltips.view-code': `Vue ou code d'impression `,
	'dashboard.tooltips.add-code': `Ajouter un nouveau menu pour votre code `,
	'dashboard.tooltips.archive-code': `Archiver ce code `,
	'dashboard.tooltips.archive-code.text1': `Êtes-vous sûr de vouloir archiver le code `,
	'dashboard.tooltips.archive-code.text2': `? Ce code et ses menus peuvent être récupérés plus tard tant qu'aucun code actif n'a la même URL (qui est `,
	'dashboard.tooltips.design-code': `Concevez votre code QR `,
	'dashboard.tooltips.design-landing-page': `Page de conception de la page d'atterrissage du code QR `,
	'dashboard.tooltips.view-analytics': `Afficher l'analyse d'utilisation `,
	'dashboard.tooltips.upgrade-to-access': `Mise à niveau pour accéder à cette fonctionnalité `,
	'dashboard.qr-modal.title': `Créer un nouveau code QR dynamique `,
	'dashboard.qr-modal.qr-name-label': `Donnez un nom à votre code QR `,
	'dashboard.qr-modal.qr-name-placeholder': `Menus de nourriture `,
	'dashboard.qr-modal.qr-description-label': `description (facultatif) `,
	'dashboard.qr-modal.qr-description-placeholder': `Menus de nourriture de la semaine, version PDF ... `,
	'print.button.done': `Terminé `,
	'print.button.print-preview': `Aperçu avant impression `,
	'print.toggle.title': `Imprimer uniquement le code QR `,
	'print.toggle.description': `Nous vous avons fourni un beau modèle de 4 'x 6' pour imprimer le code afin que vous puissiez facilement commencer! Si vous préférez imprimer uniquement le code et la concevez-la vous-même, activez ceci. `,
	'print.template.header': `Nous mettons votre santé en premier. `,
	'print.template.subheader': `Scannez pour un menu sans contact. `,
	'print.template.instruction1': `Ouvrez la caméra de votre téléphone et concentrez le code à l'écran. `,
	'print.template.instruction2': `Appuyez sur la popup `,
	'add-target.title': `Créez un nouveau menu pour votre code QR `,
	'add-target.name': `Nom du menu (les clients le verront!) `,
	'add-target.name.placeholder': `Menu de brunch `,
	'add-target.name.desc': `C'est le titre de la face publique que les clients verront! `,
	'add-target.description': `description (facultatif) `,
	'add-target.description.placeholder': `Menus de nourriture de la semaine, version PDF ... `,
	'add-target.description.desc': `C'est la description au public sur laquelle les clients verront! Nous vous recommandons vivement de fournir un. `,
	'add-target.schedule': `Calendrier du menu `,
	'add-target.schedule.dropdown.always': `Toujours utiliser `,
	'add-target.schedule.dropdown.always.desc': `Le menu apparaîtra toujours `,
	'add-target.schedule.dropdown.weekly': `Basé sur un horaire hebdomadaire `,
	'add-target.schedule.dropdown.weekly.desc': `Le code redirigera-t-il si une personne scanne le code QR dans l'horaire hebdomadaire décrit ci-dessous. `,
	'add-target.schedule.dropdown.weekly.toggle': `Basculer tout `,
	'add-target.schedule.dropdown.weekly.sun': `dimanche `,
	'add-target.schedule.dropdown.weekly.mon': `Lundi `,
	'add-target.schedule.dropdown.weekly.tues': `Mardi `,
	'add-target.schedule.dropdown.weekly.wed': `Mercredi `,
	'add-target.schedule.dropdown.weekly.thurs': `jeudi `,
	'add-target.schedule.dropdown.weekly.fri': `vendredi `,
	'add-target.schedule.dropdown.weekly.sat': `samedi `,
	'add-target.link-type.title': `Type de liaison `,
	'add-target.link-type.uploaded': `Un menu téléchargé `,
	'add-target.link-type.uploaded.placeholder': `Téléchargez le fichier Le code QR doit rediriger. `,
	'add-target.link-type.uploaded.link': `Faites glisser et 'déposez le fichier ici ou cliquez sur pour en sélectionner un `,
	'add-target.link-type.uploaded.onDrag': `Déposez le fichier ici `,
	'add-target.link-type.uploaded.desc1': `Ne vous inquiétez pas pour que ce soit parfait maintenant. Vous pouvez éteindre le menu de ce code QR dynamique à tout moment. `,
	'add-target.link-type.uploaded.desc2': `Types de fichiers pris en charge `,
	'add-target.link-type.url': `Une URL de site Web existante `,
	'add-target.link-type.url.desc': `URL externe à rediriger vers `,
	'add-target.link-type.url.website-name': `Nom de site Web `,
	'add-target.link-type.url.website-name.placeholder': `Restaurant Menu Link `,
	'add-target.link-type.url.website-name.desc': `Les clients ne verront pas cela. C'est juste pour une référence plus facile qu'une URL. `,
	'add-target.submit': `Créer `,
	'dashboard.archive.title': `Archiver ce code `,
	'dashboard.archive.text1': `Êtes-vous sûr de vouloir archiver le code `,
	'dashboard.archive.text2': `? Ce code et ses menus peuvent être récupérés plus tard tant qu'aucun code actif n'a la même URL (qui est `,
	'dashboard.archive.cancel-button': `Annuler `,
	'dashboard.archive.archive-button': `Archiver `,
	'dashboard.archived-codes.title': `Codes archivés `,
	'dashboard.archived-codes.name': `NOM DE CODE `,
	'dashboard.archived-codes.url': `URL de code `,
	'dashboard.archived-codes.safe-recover': `Sûr de récupérer? `,
	'dashboard.archived-codes.recover': `SE REMETTRE `,
	'profile.title': `Profil de l'utilisateur `,
	'profile.section.email': `E-mail `,
	'profile.section.email.desc': `Requis pour les mises à jour de courrier électronique ou de mot de passe. `,
	'profile.section.current-password': `Mot de passe actuel `,
	'profile.section.current-password.placeholder': `Mot de passe actuel `,
	'profile.section.current-password.desc': `Requis pour les mises à jour de courrier électronique ou de mot de passe. `,
	'profile.section.new-password': `nouveau mot de passe `,
	'profile.section.new-password.placeholder': `nouveau mot de passe `,
	'profile.section.new-password.desc': `Requis pour les mises à jour de courrier électronique ou de mot de passe. Doit avoir 6 caractères de plus. `,
	'profile.section.confirm-new-password': `Confirmer le nouveau mot de passe `,
	'profile.section.confirm-new-password.placeholder': `Confirmer le nouveau mot de passe `,
	'profile.section.confirm-new-password.desc': `Requis pour les mises à jour de courrier électronique ou de mot de passe. `,
	'profile.section.first-name': `Prénom `,
	'profile.section.last-name': `Nom de famille `,
	'profile.submit': `Mettre à jour `,
	'profile.subtext': `Revenir à la `,
	'profile.subtext-link': `tableau de bord `,
	'profile.billing.section-title': `Facturation `,
	'profile.billing.redirect-button': `Visitez le portail de facturation `,
	'contact.title': `Nous contacter `,
	'contact.name': `votre nom `,
	'contact.email': `Adresse e-mail `,
	'contact.email.placeholder': `you@email.com `,
	'contact.phone': `Téléphone `,
	'contact.company': `Nom de l'entreprise ou du restaurant `,
	'contact.subject.placeholder': `Bonjour! `,
	'contact.message': `Un message `,
	'contact.message.placeholder': `Dites-nous comment nous pouvons aider `,
	'contact.send': `Envoyer `,
	'contact.send.tooltip': `Je ne peux pas attendre votre message `,
	'loading.20': `Chargement... `,
	'loading.40': `Chargement de vos codes ... `,
	'loading.80': `Détails du code de chargement ... `,
	'loading.100': `Terminé! `,
	'dashboard.prompt.get-started': `Comment puis-je commencer? `,
	'dashboard.sign-out': `Déconnexion `,
	'verify-email.title': `Veuillez vérifier votre email pour un lien de vérification `,
	'verify-email.subtext1': `Si vous n'êtes pas capable de le voir, veuillez vérifier votre dossier SPAM. Si vous devez mettre à jour votre email, vous pouvez le faire `,
	'verify-email.subtext2': `ici `,
	'verify-email.subtext3': `Si vous croyez que votre email est correct et que vous n'avez toujours pas reçu la confirmation que vous pouvez le renvoyer en cliquant sur `,
	'verify-email.error': `Désolé, quelque chose s'est mal passé! S'il vous plaît contactez-nous afin que nous puissions résoudre ce problème. `,
	'verify-email.resend': `Resent avec succès! `,
	'landing-page.default-subtitle': `Visitez tout lien ci-dessous. Il suffit de cliquer sur votre navigateur pour revenir ici. `,
	'landing-page.pro.edit-button': `Éditer `,
	'checkout.cancel.title': `Mise à niveau annulée `,
	'checkout.cancel.subtitle': `Aucune action n'a été prise `,
	'checkout.cancel.button': `Retourner `,
	'checkout.success.title': `Bienvenue sur Pro! `,
	'checkout.success.subtitle1': `Vous avez progressé avec succès, félicitations! `,
	'checkout.success.subtitle2': `C'est un engagement envers une expérience de salle à manger plus sûre. `,
	'checkout.success.subtitle3': `Merci de nous faire confiance avec vos menus numériques. `,
	'checkout.success.button': `Voir votre tableau de bord Pro `,
	'upgrade.title.1': `Trouvez le `,
	'upgrade.title.2': `plan parfait `,
	'upgrade.title.3': `Pour votre entreprise `,
	'upgrade.free.name': `Libérer `,
	'upgrade.free.priceNote': `Pas de carte de crédit nécessaire `,
	'upgrade.free.label1': `Créer ${MAX_CODES_PER_TIER["basic"]} Menus de code QR dynamique `,
	'upgrade.free.label2': `${MAX_SCANS_PER_TIER["basic"]} Scanes mensuelles `,
	'upgrade.free.tooltip2': `Mesuré en fonction des numérisations sur tous les codes QR au cours du mois en cours `,
	'upgrade.free.label3': `Jusqu'à ${MAX_TARGETS_PER_TIER["basic"]} menus du même code `,
	'upgrade.free.tooltip3': `Définissez plusieurs menus à afficher du même code QR. `,
	'upgrade.free.label4': `Rotation automatique du menu `,
	'upgrade.free.tooltip4': `Configurez un horaire hebdomadaire. Menu de brunch uniquement le dimanche matin? Aucun problème. `,
	'upgrade.starter.name': `Entrée `,
	'upgrade.starter.priceNote': `Lors de la sélection d'un plan annuel. Un plan mensuel est disponible pour `,
	'upgrade.starter.label1': `Créer ${MAX_CODES_PER_TIER["starter"]} Menus de code QR dynamique `,
	'upgrade.starter.label2': `${MAX_SCANS_PER_TIER["starter"]} scans mensuels `,
	'upgrade.starter.tooltip2': `Mesuré en fonction des numérisations sur tous les codes QR au cours du mois en cours `,
	'upgrade.starter.label3': `Jusqu'à ${MAX_TARGETS_PER_TIER["starter"]} menus du même code `,
	'upgrade.starter.tooltip3': `Définissez plusieurs menus à afficher du même code QR. `,
	'upgrade.starter.label4': `Rotation automatique du menu `,
	'upgrade.starter.tooltip4': `Configurez un horaire hebdomadaire. Menu de brunch uniquement le dimanche matin? Aucun problème. `,
	'upgrade.pro.name': `Pro `,
	'upgrade.pro.priceNote': `Lors de la sélection d'un plan annuel. Un plan mensuel est disponible pour `,
	'upgrade.pro.label1': `Toutes les fonctionnalités gratuites `,
	'upgrade.pro.tooltip1': `Obtenez toutes les fonctionnalités gratuites sans les annonces! `,
	'upgrade.pro.label2': `Créer ${MAX_CODES_PER_TIER["pro"]} Menus de code QR dynamique `,
	'upgrade.pro.label3': `${MAX_SCANS_PER_TIER["pro"]} Scanes mensuelles `,
	'upgrade.pro.tooltip3': `Mesuré en fonction des numérisations sur tous les codes QR au cours du mois en cours `,
	'upgrade.pro.label4': `Toujours sans publicité `,
	'upgrade.pro.tooltip4': `Vos clients ne verront jamais des annonces. Merci de nous avoir soutenu! `,
	'upgrade.pro.label5': `Codes QR de marque `,
	'upgrade.pro.tooltip5': `Personnalisez les couleurs de chaque QRCode, améliorez la qualité de la reconnaissance de balayage et ajoutez un logo! `,
	'upgrade.pro.label6': `Menu de marque Pages d'atterrissage `,
	'upgrade.pro.tooltip6': `Personnalisez la conception de la page d'atterrissage de chaque code pour correspondre à la marque de votre restaurant. `,
	'upgrade.pro.label7': `Analyse analytique `,
	'upgrade.pro.tooltip7': `Suivre combien de clients numérisent votre code et quels menus ils sélectionnent `,
	'upgrade.pro.label8': `Soutien dédié `,
	'upgrade.pro.tooltip8': `Atteignez le support le plus rapide avec une plate-forme de contact uniquement pour les utilisateurs professionnels `,
	'upgrade.enterprise.name': `Entreprise `,
	'upgrade.enterprise.label1': `Menus de code QR dynamique illimité `,
	'upgrade.enterprise.label2': `Tous les avantages de Pro `,
	'upgrade.enterprise.tooltip2': `Tous des professionnels sans limitations `,
	'upgrade.enterprise.label3': `Développement personnalisé et fonctionnalités `,
	'upgrade.enterprise.tooltip3': `Obtenez le maximum de vos codes QR avec le développement de fonctionnalités personnalisés `,
	'upgrade.enterprise.label4': `Soutien VIP `,
	'upgrade.enterprise.tooltip4': `Accès direct à l'équipe Eat Safe Codes `,
	'upgrade.misc.perMonth': `par mois `,
	'upgrade.misc.enterprise': `Tendre la main pour déterminer la meilleure offre pour votre entreprise `,
	'upgrade.misc.after-trial': `Après essai gratuit, annuler ou modifier le plan à tout moment `,
	'upgrade.misc.current-plan': `Plan actuel `,
	'upgrade.buttons.free.purchase': `Sélectionnez un plan gratuit `,
	'upgrade.buttons.free.signup': `Inscription gratuite! `,
	'upgrade.buttons.starter.purchase': `Commencez avec Starter `,
	'upgrade.buttons.starter.annual': `Acheter plan annuel `,
	'upgrade.buttons.starter.monthly': `Acheter un plan mensuel `,
	'upgrade.buttons.pro.purchase': `Commencez avec Pro `,
	'upgrade.buttons.pro.annual': `Acheter plan annuel `,
	'upgrade.buttons.pro.monthly': `Acheter un plan mensuel `,
	'upgrade.buttons.signup-offer.monthly': `Commencer l'essai gratuit `,
	'upgrade.buttons.enterprise': `Nous contacter `,
	'signup-offer.title': `Avant de vous emmener dans la version gratuite, essayez notre option la plus populaire gratuitement pour ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} jours! `,
	'signup-offer.desc.p1': `Vous n'êtes pas sûr si Pro vous convient? Nous comprenons. En tant que nouveau membre, veuillez profiter d'un mois gratuit de fonctionnalités pro et de limites de numérisation beaucoup plus élevées. Sortez le maximum de votre code QR aujourd'hui, y compris des pages de débarquement de marque, Scan Analytics, etc. `,
	'signup-offer.desc.p2-a': `Notez que si vous ne démarrez pas d'essai gratuit et que vous continuez avec la version gratuite, vous obtiendrez toujours `,
	'signup-offer.desc.p2-b': `Jours d'expériences publicitaires `,
	'signup-offer.btn.decline': `Non merci, emmène-moi à la version gratuite. `,
	'signup-offer.starter.title': `Essayez notre plan professionnel le plus populaire gratuitement pour ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} jours! `,
	'signup-offer.starter.desc.p1': `Vous n'êtes pas sûr si Pro vous convient? Nous comprenons. En tant que nouveau membre, veuillez profiter d'un mois gratuit de fonctionnalités pro et de limites de numérisation beaucoup plus élevées. Sortez le maximum de votre code QR aujourd'hui, y compris des pages de débarquement de marque, Scan Analytics, etc. `,
	'signup-offer.starter.desc.p2-a': `En essayant le plan de démarrage, vous manquerez `,
	'signup-offer.starter.desc.p2-b': `Jours d'expériences publicitaires `,
	'signup-offer.starter.btn.decline': `J'aimerais essayer le plan de démarrage `,
	'pro-announcement.header.1': `Annonçant `,
	'pro-announcement.btn1': `En savoir plus sur Pro `,
	'pro-announcement.p1': `Bonnes nouvelles - nous grandissons! Nous voulons sincèrement remercier de vous aider à faire de nous le fournisseur de menus numériques n ° 1 et à faire des restaurants un espace plus sûr pour dîner dans ces moments difficiles. Nous sommes ravis d'annoncer qu'aujourd'hui, nous publions notre nouveau Pro Niveau avec une tonne de nouvelles fonctionnalités! `,
	'pro-announcement.p2': `Le Pro Tier soutiendra nos coûts d'exploitation et nous permettra de mieux servir nos utilisateurs dédiés. Nous espérons que vous remarquerez plusieurs fonctionnalités hautement demandées lorsque vous mettez à niveau, comme la conception personnalisable de codes QR et de pages de débarquement de menu. Attendez-vous à voir plus de fonctionnalités destinées aux membres professionnels. `,
	'pro-announcement.p3': `Pour le coût d'une Entreté par mois, vous pouvez passer à Pro aujourd'hui. Un abonnement annuel est également disponible à une réduction de 20%. Manger en toute sécurité n'est pas une tendance - vos clients s'attendront à un menu plus sûr pendant une longue période et nous serons là pour fournir une expérience de haute qualité. `,
	'pro-announcement.p4': `Chaque niveau aura également des limites sur le total des codes QR et des analyses mensuelles. Veuillez consulter ces limites afin que vous puissiez décider si Pro vous convient. Le 4 octobre, les limites d'utilisation de chaque niveau seront activées. Ne vous inquiétez pas - si vous dépassiez déjà la limite, vos codes ne seront pas supprimés et votre code QR continuera de fonctionner. `,
	'pro-announcement.p5': `De plus, le 4 octobre, nous allons introduire des annonces discressives sur les pages de débarquement du menu dans le niveau libre. `,
	'pro-announcement.p6': `Si vous souhaitez supprimer ces annonces de la vue de votre client, débloquez de nouvelles fonctionnalités précieuses et investissez dans la plate-forme SA bien sûr, envisagez la mise à niveau! Pour plus de détails sur la tarification, les fonctionnalités et les limites, visitez notre page de tarification à `,
	'pro-announcement.p7': `Nous sommes tellement reconnaissants pour votre investissement en nous et au-delà d'excité pour ce nouveau chapitre des codes de sécurité. Envoyez-nous des commentaires ou dites simplement bonjour à tout moment à `,
	'banner.free-trial.text': `Obtenez un mois gratuit de Pro! `,
	'checkout.canceled': `Checket annulé `,
	'checkout.no-action': `Aucune action n'a été prise `,
	'pro-features.1.title': `Codes QR de marque `,
	'pro-features.1.description': `Les codes QR n'ont pas à regarder la même chose. Faites vous démarquer et impressionner vos clients. Ajouter des couleurs, améliorer la correction d'erreur et même ajouter le logo de votre entreprise dans le code. `,
	'pro-features.2.title': `Menu de marque Pages d'atterrissage `,
	'pro-features.2.description': `Lorsque plusieurs menus sont affichés, fournissez aux clients une page d'atterrissage de marque qui correspond à vos thèmes et à vos images. `,
	'pro-features.3.title': `Analyse de code `,
	'pro-features.3.description': `Surveillez la fréquence à laquelle vos codes sont numérisés avec des métriques d'analyse horaire par heure. `,
	'pro-features.4.title': `Limites d'utilisation pro `,
	'pro-features.4.description': `Arrêtez de vous inquiéter de frapper votre limite. Numérisez jusqu'à 100 000 fois par an. Besoin de plus? Parlons-enons et voyons aujourd'hui. `,
	'pro.success.welcome': `Bienvenue à `,
	'pro.success.congrats': `Vous avez mis à niveau avec succès - Félicitations! `,
	'pro.success.body': `S'inscrire à Pro est un engagement envers une expérience de salle à manger plus sûre et nous voulons vous soutenir dans cet engagement à chaque étape de la voie. En tant que membre du PRO, nous nous concentrons sur vous. Les membres professionnels méritent une expérience abordable et constamment améliorant, et nous espérons commencer à noter qu'aujourd'hui avec plusieurs fonctionnalités que vous venez de déverrouillez. `,
	'pro.success.button': `Voir votre tableau de bord Pro `,
	'pro.limit.header': `Vous avez atteint votre limite. `,
	'pro.limit.upgrade': `Vous devrez effectuer une mise à niveau pour faire cela. `,
	'pro.limit.button': `Mettre à jour maintenant `,
	'menu-landing.message': `Il ne semble pas y avoir un menu disponible en ce moment. `,
	'menu-landing.scans1': `Vous avez dépassé votre limite d'utilisation de `,
	'menu-landing.scans2': `scanne au cours des 30 derniers jours. Vous avez actuellement `,
	'menu-landing.scans3': `scanne au cours des 30 derniers jours. S'il vous plaît contactez-nous si vous avez besoin d'une limite de niveau supérieure! `,
	'menu-landing.scans4': `scanne au cours des 30 derniers jours. Mettez à niveau vers Pro pour une limite d'utilisation supérieure! `,
	'menu-landing.exceeeded': `Vous avez dépassé la limite de numérisation pour votre niveau. `,
	'edit-code.EC_DESCRIPTION': `Activez un code QR de fidélité plus élevé pour une correction d'erreur améliorée en cas de dommages au code. `,
	'edit-code.label.foreground': `Couleur de premier plan `,
	'edit-code.label.background': `Couleur de l'arrière plan `,
	'edit-code.label.errorMessage': `Erreur lors de la création d'une URL d'objet à partir du logo téléchargé `,
	'edit-code.label.customize': `Personnalisez votre style de code. `,
	'edit-code.quality.M': `Standard `,
	'edit-code.squares': `Carrés `,
	'edit-code.dots': `Points `,
	'edit-code.padding': `Rembourrage `,
	'edit-code.logo.title': `Ajoutez votre logo d'entreprise dans votre code! `,
	'edit-code.logo.warning': `Fichier doit être une image (.png, .jpg, .jpeg) et avoir une taille maximale de 2 Mo `,
	'edit-code.logo.sizeTitle': `Taille du logo `,
	'edit-code.logo.opacityTitle': `Logo opacité `,
	'edit-code.saveError': `Quelque chose a mal tourné en sauvegarde la conception de code personnalisée `,
	'edit-code.button.save': `sauvegarder `,
	'edit-code.button.back': `Retourner `,
	'landing-editor.label.background': `Fond `,
	'landing-editor.label.primary': `Texte primaire `,
	'landing-editor.label.menu': `Menu `,
	'landing-editor.label.menuHover': `Menu (sur Hover / Click) `,
	'landing-editor.label.menuTitle': `Titre du menu `,
	'landing-editor.label.menuDesc': `Description du menu `,
	'landing-editor.label.header': `Entête `,
	'landing-editor.label.desc': `La description `,
	'landing-editor.placeholder.desc': `Visitez tout lien ci-dessous. Il suffit de cliquer sur votre navigateur pour revenir ici. `,
	'landing-editor.label.font': `Police de caractère `,
	'landing-editor.label.logo': `Logo `,
	'landing-editor.formTextBlocks.logo': `Téléchargez un logo de 1 Mo ou moins! `,
	'landing-editor.button.save': `sauvegarder `,
	'landing-editor.button.done': `Terminé `,
	'pro-landing.back': `Retour au tableau de bord `,
	'pro-landing.edit': `Éditer `,
	'upgrade.btn.why-pro': `Pourquoi choisir Pro? `,
	'upgrade.desc.discover': `Découvrir `,
	'upgrade.desc.desc': `Avec notre nouveau `,
	'upgrade.desc.desc2': `Vous pouvez personnaliser l'expérience de votre client pour correspondre à votre entreprise. En plus des limites accrues mentionnées ci-dessus, vous pouvez déverrouiller les fonctionnalités suivantes. `,
	'upgrade.desc.bullet1': `Codes QR personnalisés `,
	'upgrade.desc.bullet1.desc': `Ajoutez votre logo, personnalisez le look, et plus encore! `,
	'upgrade.desc.bullet2': `Pages client personnalisées `,
	'upgrade.desc.bullet2.desc': `Créez une expérience personnalisée que vos clients verront quand ils scanneront. `,
	'upgrade.desc.bullet3': `Analytique `,
	'upgrade.desc.bullet3.desc': `Comprenez mieux votre client avec des analyses détaillées pour déterminer quand et comment votre code est utilisé. `,
	'usage-meter.desc.you-have-scanned': `Vos codes ont eu `,
	'usage-meter.desc.times': `Total des analyses ce mois-ci `,
	'usage-meter.desc.low': `Bon travail. `,
	'usage-meter.desc.med': `Avez-vous assez de scanns à terminer pour terminer le mois? Mettez à niveau pour plus. `,
	'usage-meter.desc.high': `Vous n'avez pas beaucoup de scanns à gauche. Mise à niveau pour vous assurer que vous n'êtes pas épuisé! `,
	'usage-meter.desc.max': `Oh non! Vous avez utilisé toutes les analyses disponibles pour ce mois. Vos menus n'apparaîtront plus lorsque vos codes sont numérisés. Mise à niveau maintenant pour augmenter immédiatement votre limite de numérisation ou attendre le mois prochain. `,
	'usage-meter.title': `Niveau d'utilisateur `,

	// items added 2021-11-30 10:48:16.955357
	'dashboard.qr-modal.qr-url-label': `Ajoutez une URL personnalisée unique pour générer votre code QR `,
	'dashboard.qr-modal.qr-desc1': `Cette URL identifie de manière unique votre code et ne peut être utilisé qu'une fois par compte. Toute modification de cette URL changera le code QR lui-même. `,
	'dashboard.qr-modal.qr-desc2': `Seules des lettres, des chiffres, des traits d'union et de soulignement sont autorisés. `,
	'dashboard.qr-modal.just-a-sec': `Juste une seconde... `,
	'dashboard.qr-modal.create': `Créer `,
	'dashboard.qr-modal.upload-success': `Téléchargé avec succès! `,

	// items added 2021-12-01 18:52:46.782837
	'dashboard.button.upgrade-account': `Mettre à jour maintenant`,
	'signup-offer.btn.no-thanks': `Non merci, je vais décider plus tard.`,
	'banner.free-month.text': `Obtenez un mois gratuit!`,
	'menu-landing.limited': `Compte est limité, veuillez mettre à niveau`,

	// items added 2021-12-15 14:19:12.293335
	'upgrade.free.label0': `La marque ESC sur les pages d'atterrissage`,
	'upgrade.starter.label0': `Pas de marque ESC sur la page d'atterrissage`,
	'upgrade.pro.label0': `Pas de marque ESC sur la page d'atterrissage`,
	'upgrade.buttons.manage-account': `Gérer son compte`,
	'edit-code.quality.Q': `Haut`,

	// items added 2021-12-26 16:29:36.890441
	'print.button.download': `Télécharger`,
	'print.templates.title': `Concevoir visuellement QR Imprimer des modèles`,
	'print.button.upgrade': `Mettez à niveau vers Pro pour concevoir des modèles QR`,
	'print.templates.description': `Nous vous avons fourni de beaux modèles de 4 'x 6' pour imprimer le code afin que vous puissiez facilement commencer! Faites facilement votre propre design avec Visual Builder.`,

	// items added 2021-12-26 22:24:36.926562
	'home.preview.banner4.1': `Imprimer la conception QR`,
	'home.preview.banner4.2': `Aucun concepteur nécessaire!`,
	'upgrade.pro.label9': `Concepteur visuel pour imprimer qr`,
	'upgrade.pro.tooltip9': `Choisissez parmi les modèles QR de prêt Prêt pour imprimer et le personnaliser facilement à vos besoins, aucun concepteur nécessaire!`,

	// items added 2021-12-26 22:33:28.269720

	// items added 2022-05-16 12:52:20.335084
	'upgrade.free.label5': `Financé par la publicité`,
};