import { LANGUAGE_MAP } from "./constants";
import { ALL_LANGUAGES, AppLanguage } from "./types";

export function isAppLanguage(lang: string) {
    return !!LANGUAGE_MAP[lang];
}

export function getAppLanguage(lang: string): AppLanguage | undefined {
    return LANGUAGE_MAP[lang];
}

/**
 * Custom language maps
 */
export function appLanguageFactory<T>(generator: (lang: AppLanguage) => T): Record<AppLanguage, T> {
    const map = {} as Record<AppLanguage, T>;
    ALL_LANGUAGES.forEach((lang) => (map[lang] = generator(lang)));
    return map;
}
