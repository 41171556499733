import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss?v=1.1.0";
import "./assets/demo/demo.css";
import "react-input-range/lib/css/index.css";
import ErrorBoundary from "./ErrorBoundry";

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);
