import { URL } from "../settings";
import { LanguageStrings, RouteDeclaration } from "./lang-en";
import { esText } from "./siteText/es";

export const routesEs: RouteDeclaration = {
    "routes.index": "/",
    "routes.faq": "/preguntas",
    "routes.contact": "/contactanos",
    "routes.terms": "/condiciones-de-servicio",
    "routes.about": "/sobre",
    /** Unauth Routes */
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/registrar",
    "routes.unauth.sign-in": "/entrar",
    /** Auth Routes */
    "routes.auth.signup-offer": "/oferta-de-registro",
    "routes.auth.home": "/inicio",
    "routes.auth.dashboard": "/tablero",
    "routes.auth.user-profile": "/perfil",
    "routes.auth.sign-out": "/salir",
    "routes.pricing": "/actualizar",
    "routes.pro-announcement": "/upgrade/announcement",
    "routes.auth.checkout.success": "/actualizar/exito",
    "routes.auth.checkout.cancel": "/actualizar/cancelada",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.privacy": "/privacidad",
    "routes.auth.analytics": "/analítica",
    "routes.auth.verify-email": "/verificar",
};

export const routesTextEs: RouteDeclaration = {
    "routes.index": "Inicio",
    "routes.faq": "Preguntas Más Frecuentes",
    "routes.contact": "¡Contáctenos!",
    "routes.terms": "Condiciones De Servicio",
    "routes.about": "Sobre Nosotros",
    /** Unauth Routes */
    "routes.unauth.home": "Inicio",
    "routes.unauth.sign-up": "Registrar",
    "routes.unauth.sign-in": "Iniciar Sesión",
    /** Auth Routes */
    "routes.auth.signup-offer": "Oferta de Registro",
    "routes.auth.home": "Inicio",
    "routes.auth.dashboard": "Tablero",
    "routes.auth.user-profile": "Mi Perfil",
    "routes.auth.sign-out": "Salir",
    "routes.pricing": "Actualizar",
    "routes.auth.checkout.success": "Éxito",
    "routes.auth.checkout.cancel": "Actualización Cancelada",
    "routes.privacy": "Política de privacidad",
    "routes.pro-announcement": "Anunciando Pro",
    "routes.auth.edit-menu": "Página de menú de edición",
    "routes.auth.analytics": "Análisis de menú",
    "routes.auth.verify-email": "Verificar correo electrónico",
};

export const es: Partial<LanguageStrings> = {
    ...routesEs,
    ...esText,
};
