import { Notifier } from "@airbrake/browser";
import { isLocalhost } from "./urls";

const ignorableErrorMessages = [
    "QuotaExceededError",
    "Network Error",
    "cancelled", // Google Analytics intermittent failures (https://github.com/glasperfan/esc/issues/92)
    "because the client is offline",
    "installations/app-offline",
    "IDBDatabase",
    "IDBFactory.open()",
    "called in an invalid security context",
    "A mutation operation was attempted on a database that did not allow mutations.",
    "The transaction was aborted, so the request cannot be fulfilled."
];

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

export function getAirbrake() {
    const notifier = new Notifier({
        projectId: 276289,
        projectKey: "fd8757a39afa4aa9d65209eec080c49b",
        environment: isLocalhost ? "test" : "production",
    });

    notifier.addFilter((notice) => {
        const { error } = notice;
        
        // if there's no error, don't bother
        if (!error) {
            return null;
        }

        // ignore Error: internal
        // This comes from Functions (https://firebase.google.com/docs/functions/callable#handle_errors)
        if (error.message.includes("internal")) {
            return null;
        }

        // If chunks are failing to load, we've likely deployed a new version. 
        // The user is navigating an old version, tries to lazy load a module, and it's no longer available.
        // We expect some of these for now as new versions of the app are deployed
        if (chunkFailedMessage.test(error.message)) {
            return null;
        }

        // ignore ignorable errors
        if (ignorableErrorMessages.some(msg => error.message.includes(msg))) {
            return null;
        }

        // only log production errors
        if (notice.context?.environment !== "production") {
            return null;
        }

        return notice;
    });
    return notifier;
}

export const airbrake = getAirbrake();

type Severity = "debug" | "info" | "notice" | "warning" | "error" | "critical" | "alert" | "emergency";

const toError = (s: string | Error) => (s instanceof Error ? s : Error(s));

class Logger {
    constructor(private component: string) {}

    debug = (error: string | Error | any, params: { [param: string]: any }) => this.report(toError(error), "debug", params);
    info = (error: string | Error | any, params: { [param: string]: any }) => this.report(toError(error), "info", params);
    warn = (error: string | Error | any, params: { [param: string]: any }) => this.report(toError(error), "warning", params);
    error = (error: string | Error | any, params: { [param: string]: any }) => this.report(toError(error), "error", params);
    alert = (error: string | Error | any, params: { [param: string]: any }) => this.report(toError(error), "alert", params);

    private report(error: Error, severity: Severity, params: { [param: string]: any }) {
        const { component } = this;
        if (isLocalhost) {
            console.log(error, {
                severity,
                component,
                params,
            });
        } else {
            airbrake.notify({
                error,
                context: {
                    severity,
                    component,
                },
                params,
            });
        }
    }
}

export function getLogger(component: string) {
    return new Logger(component);
}
