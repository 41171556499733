import { OFFERS } from "../../settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";

export const baseStringsEnglish = {
    // Nav
    "nav.navigation-dropdown": "Navigation",
    // Error
    "error.contact-us-message": "So sorry, something went wrong! Would you mind letting us know? We'd like to fix it ASAP. Please get in touch with us directly at ",
    // Home - Title
    "home.title.title": "Dynamic QR Code Menus",
    "home.title.subtitle": "Keep your customers safe with no-contact, digital menus that rotate automatically.",
    "home.title.make-code-button": "Make a Code",
    //Home - preview
    "home.preview.title": "Simple. Quick. Safe.",
    "home.preview.subtitle1": "Eat Safe Codes takes about two minutes to set up.",
    "home.preview.subtitle2": "Seriously, that's it.",
    "home.preview.subtitle3": "With our platform, you can make QR codes that will allow your restaurant to:",
    "home.preview.bullet1.1": "Display",
    "home.preview.bullet1.2": "multiple menus",
    "home.preview.bullet1.3": "from the same QR code",
    "home.preview.bullet2.1": "Schedule menus",
    "home.preview.bullet2.2": "to appear on certain days or times",
    "home.preview.bullet3.1": "Customize and design",
    "home.preview.bullet3.2": "your QR codes and menu landing pages to match your brand",
    "home.preview.banner1.1": "Design",
    "home.preview.banner1.2": "Add your brand",
    "home.preview.banner2.1": "Scheduled Menus",
    "home.preview.banner2.2": "rotate automatically",
    "home.preview.banner3.1": "Upload",
    "home.preview.banner3.2": "Host your Menu",
    "home.preview.banner4.1": "Print QR Design",
    "home.preview.banner4.2": "No designer needed!",
    // Home - Description
    "home.desc.title": "How does it work?",
    "home.desc.desc": "Eat Safe Codes allows you to upload multiple digital menus (PDF, JPEG, PNG, etc.) and make them accessible to customers via QR codes scanned at the table. This means customers reduce the potential spread of pathogens from touching physical menus. You can set schedules for menus to automatically rotate so that you can stay focused on what matters most:",
    "home.desc.desc-bold": "running your restaurant.",
    // Testimonial
    "home.testimonial.text": '"We’ve been able to reduce our paper menu waste a ton, seeing as they’re all single use. Menu printing used to be over 150 menus a day. Now we’re down to maybe 100 every two weeks."',
    "home.testimonial.name": "- Drew, Public School 818, Los Angeles, USA",
    // Home - Photo desc Component
    "home.photos.title": "Need more? Go Pro.",
    "home.photos.body1": "Unlock the full power of Eat Safe Codes with the ",
    "home.photos.body2-bold": "Pro plan.",
    "home.photos.body3": "Discover scan analytics, customize the design of your QR codes, and design the landing pages that customers see. Be a part of the solution and help stop the spread of",
    "home.photos.body4": "COVID-19.",
    "home.photos.button": "Check Out Our Plans",
    // Home - Benefits
    "home.benefits.title": "The world has changed.",
    "home.benefits.subtitle": "Get your menus with the times",
    // Home- Block 1
    "home.benefits.block1.title": "Protect Customers & Staff",
    "home.benefits.block1.body1": "Protect customers who expect a safer dining experience. Protect staff who ",
    "home.benefits.block1.body2-bold": "risk their health",
    "home.benefits.block1.body3": "every day to interact with diners.",
    // Home - Block 2
    "home.benefits.block2.title": "Save trees, save money, and save hours",
    "home.benefits.block2.body1": "Save on printing new menus. Print and design your QR codes",
    "home.benefits.block2.body2-bold": "once",
    "home.benefits.block2.body3": ", then update the menus from your phone or computer at any time.",
    // Home - Block 3
    "home.benefits.block3.title": "Automatically rotate menus",
    "home.benefits.block3.body1": "Display breakfast, lunch, and dinner menus on flexible weekly schedules. Have a happy hour menu for Tuesday 5-7pm and Friday 5-8pm? ",
    "home.benefits.block3.body2-bold": "No problem.",
    // Home - Block 4
    "home.benefits.block4.title": "Simple, elegant landing pages for multiple menus",
    "home.benefits.block4.body1": "Present the dinner menu with a wine list. Daily appetizers with the weekly menus. Our menus adapt with your restaurant.",
    "home.benefits.block4.body2-bold": "All from one code",
    "home.benefits.block4.body3": ".",
    // Support
    "home.support.title": "Thank you for supporting us!",
    "home.support.subtitle": "Help us keep the world safe.",

    // FAQ
    "faq.title.largetext": "FAQ",
    "faq.title.title": "Frequently Asked Questions",
    "faq.video.title": "Quick Tutorial",
    "faq.button.to-dashboard": "Return to dashboard",
    // Questions
    "faq.questions.q1": "What’s a QR code?",
    "faq.questions.a1": "A quick reference (QR) code consists of black squares arranged in a square grid on a white background, which can be read by an imaging device such as the camera on your mobile phone. Our services allow your customers to quickly link your restaurant’s menu to codes uniquely generated for your business. This creates a safe, touch-free method for your customers to access your menus.",
    "faq.questions.q2": "How will my customers use my QR code?",
    "faq.questions.a2": "Most modern smartphones have QR Readers built in. For most devices, users will only need to open their camera and center the code (no photo required). A popup will magically appear to shepherd your customers to your menu or website.",
    "faq.questions.q3": "Can I display multiple menus from the same QR code at once?",
    "faq.questions.a3": "Yes! If multiple menus are configured to appear, it will provide your customer the option to select and navigate between them. If only one is configured to appear, the user will be sent directly to that menu.",
    "faq.questions.q4": "Can I schedule certain menus to appear at certain times?",
    "faq.questions.a4": "Yes! The platform allows you to configure menus to display on a schedule of arbitrary complexity. This means, for example, that you can configure weekend brunch, weekday breakfast, happy hour drink deals, and/ or daily dinner specials to appear at their appropriate times--all from the same QR code! You also have the ability to toggle menus on/ off from the manager’s mobile or desktop dashboard for on the fly menu changes.",
    "faq.questions.q5": "Does the management dashboard work on mobile?",
    "faq.questions.a5": "Yup! You are able to make edits to schedules, toggle menus on/ off, add, and delete menus from your mobile device.",
    "faq.questions.q6": "What if my customer doesn’t have a smartphone QR reader?",
    "faq.questions.a6": "Most modern devices have QR code readers built in. That said, all Android and iPhone have QR reader apps that can be downloaded for free. And as a backup, it's always helpful to have a spare menu available just in case. ",
    "faq.questions.q8": "What if my menu changes?",
    "faq.questions.a8": "You are able to add, edit, update, and delete menus at any time with just a few clicks. The platform accepts most photo formats as well as dynamic sources such as your website’s url.",
    "faq.questions.q10": "Can I have multiple different QR codes set to different restaurants or menu schedules? ",
    "faq.questions.a10": "Sure can! There is currently no limit for the number of QR codes a user can generate.",
    "faq.questions.q11": "What if I delete a code? Can I recover it? ",
    "faq.questions.a11": "QR codes can be recovered as long as the same vanity link is used when recreated.",
    "faq.questions.q12": "Can I change the order in which menus are shown to customers?",
    "faq.questions.a12": "Definitely! Just drag and drop menus in the dashboard to rearrange their display order.",

    // Pro Questions
    "faq.questions.pro.q1": "How do I upgrade?",
    "faq.questions.pro.a1": "You can upgrade your account by visiting the upgrade page. Note that you will have a chance to review all charges before finalizing payments.",
    "faq.questions.pro.q2": "Can I cancel my subscription to Pro?",
    "faq.questions.pro.a2": "Yes, at any time by visiting the Billing section of your User Profile. Your cancellation will take effect immediately and your QR codes will no longer work.",
    "faq.questions.pro.q3": "What does it cost?",
    "faq.questions.pro.a3": "It's free! So long as you stay within our generous usage limits, our services are entirely free. We do include small, unobtrusive ads for this tier so that we can offer you this service at no cost. If you wish to eliminate these ads and unlock a whole bunch of useful features, consider upgrading to a Pro account. ",
    "faq.questions.pro.q4": "What’s included in the starter version? ",
    "faq.questions.pro.a4": "All of the basic functionality to display your menu is included in the starter version! To see the limitations of this option, please view the tier limits.",
    "faq.questions.pro.q5": "What can I customize?",
    "faq.questions.pro.a5": "Our Pro option allows you to customize just about everything! You can design your own code, edit the appearance and add custom logos. You can also edit the landing page your customers will see so that it is customized for your business.",
    "faq.questions.pro.q6": "What are the limits? ",
    "faq.questions.pro.a6": "Please see the upgrade page to view the tier limits for scan, menu uploads, and more.",
    "faq.questions.pro.q7": "What happens if I exceed the limit?",
    "faq.questions.pro.a7": "You will not be locked out of your account if you exceed a limit. However, the first time you exceed your allowed scan count you will be given two weeks before the menus will be paused. In order to continue, you must upgrade to the appropriate tier.",
    "faq.questions.pro.q8": "Is there a limit to how many codes I can create?",
    "faq.questions.pro.a8": "Yes. Please reference the tiers on the upgrade page. It’s important to note that archived codes count towards this limit.",
    "faq.questions.pro.q9": "Is there a limit to how many menus I can create?",
    "faq.questions.pro.a9": "Yes. Please find the menu limit on the plan table. You can delete menus to stay below your tier limit.",
    "faq.questions.pro.q10": "Is there a limit to how many menu file uploads I can make per code?",
    "faq.questions.pro.a10": "Yes. Please find the file upload limit on the plan table. Even if menus are later deleted, they will still count toward your overall total.",
    "faq.questions.pro.q11": "How do payments work?",
    "faq.questions.pro.a11": "For yearly charges you will be charged once every 365 days from the data of the first subscription. For monthly charges, you will be charged every 30 days from the data of the first subscription. Your credit card will be charged automatically unless you cancel your subscription. We are not able to provide partial refunds for unused periods. All transactions will be considered final.",
    "faq.questions.pro.q12": "What currency can I pay in?",
    "faq.questions.pro.a12": "Our payment partners support multiple currencies, but all prices are listed in United States Dollars.",

    // sign up
    "signup.title.title": "Sign Up",
    "signup.title.subtitle": "Don't worry, your account doesn't expire, and we don't want your credit card.",
    "signup.title.welcome": "Welcome to the club.",
    // form
    "signup.form.email": "Email",
    "signup.form.email.placeholder": "example@email.com",
    "signup.form.password": "Password",
    "signup.form.password.placeholder": "Password",
    "signup.form.first": "First Name",
    "signup.form.first.placeholder": "First",
    "signup.form.last": "Last Name",
    "signup.form.last.placeholder": "Last",
    "signup.form.company": "Company",
    "signup.form.company.placeholder": "The Best Restaurant in Town",
    "signup.form.submit": "Sign Up",
    "signup.redirect.text": "Already a member? Sign in",
    "signup.redirect.link": "here",
    // sign in
    "signin.title.title": "Hello, old friend",
    "signin.title.subtitle": "Sign in, would ya?",
    // form
    "signin.form.email": "Email",
    "signin.form.email.placeholder": "example@email.com",
    "signin.form.password": "Password",
    "signin.form.password.placeholder": "Password",
    "signin.form.submit": "Sign In",
    "signin.form.reset-pwd.button": "Reset My Password",
    "signin-form.reset-pwd.confirmation": "Thanks! If an account exists for this email, we've emailed you a link to reset your password.",
    "signin-form.reset-pwd.link": "Forget your password?",
    "signin-form.reset-pwd.link.finish": "Go back to sign-in.",
    "signin.redirect.text": "Not an existing member? Sign up",
    "signin.redirect.link": "here",

    // Dashboard
    "dashboard.title.text1": "Good work",
    "dashboard.title.text2": "These codes are keeping the world healthy.",
    "dashboard.button.text": "Create a New QR Code",
    "dashboard.button.upgrade": "Upgrade to Pro",
    "dashboard.button.upgrade-account": "Upgrade Now",
    "dashboard.account.disabled": "limited",

    // Dashboard - Get Started
    "dashboard.get-started.get-started": "Get started! - ",
    "dashboard.get-started.get-started2": "Start by creating your first QR code and the file that it should redirect to.",
    "dashboard.get-started.add-menus": "This code doesn't link to any menus yet! - ",
    "dashboard.get-started.add-menus2": "Click here to add your first menu.",

    "dashboard.alerts.cant-verify": "We weren't able to verify your user credentials. Please log out and re-log in. ",

    // Dashboard - Menu Table
    "dashboard.table-headers.Order": "Order",
    "dashboard.table-headers.Name": "Name",
    "dashboard.table-headers.Name.tooltip": "This is the menu name patrons will see",
    "dashboard.table-headers.Active": "Active",
    "dashboard.table-headers.Active.tooltip": "Set this to OFF to disable this menu",
    "dashboard.table-headers.Schedule": "Schedule",
    "dashboard.table-headers.Version": "Version",
    "dashboard.table-headers.Actions": "Actions",

    // Dashboard - Schedule
    "dashboard.pils.Always": "Always",
    "dashboard.pils.Everyday": "Everyday",
    "dashboard.pils.Never": "Never",
    "dashboard.pils.Weekdays": "Weekdays",
    "dashboard.pils.Weekends": "Weekends",

    // Dashboard - Tooltips
    "dashboard.tooltips.header": "Manage",
    "dashboard.tooltips.pro-section.if-pro": "Pro",
    "dashboard.tooltips.pro-section.if-basic": "Upgrade for Pro Features",
    "dashboard.tooltips.test-code": "Click to test your QR Code (based on the current time).",
    "dashboard.tooltips.view-code": "View or Print Code",
    "dashboard.tooltips.add-code": "Add a new menu for your code",
    "dashboard.tooltips.archive-code": "Archive This Code",
    "dashboard.tooltips.archive-code.text1": "Are you sure you want to archive the code",
    "dashboard.tooltips.archive-code.text2": "? This code and its menus can be recovered later as long as no active code has the same url (which is",
    "dashboard.tooltips.design-code": "Design your QR code",
    "dashboard.tooltips.design-landing-page": "Design QR code landing page",
    "dashboard.tooltips.view-analytics": "View usage analytics",
    "dashboard.tooltips.upgrade-to-access": "Upgrade to access this feature",

    // Dashboard - QR Modal
    "dashboard.qr-modal.title": "Create a New Dynamic QR Code",
    "dashboard.qr-modal.qr-name-label": "Give your QR code a name",
    "dashboard.qr-modal.qr-name-placeholder": "Food Menus",
    "dashboard.qr-modal.qr-description-label": "Description (optional)",
    "dashboard.qr-modal.qr-description-placeholder": "Weeknight food menus, PDF version...",
    "dashboard.qr-modal.qr-url-label": "Add a unique personalized url to generate your QR code",
    "dashboard.qr-modal.qr-desc1": "This url uniquely identifies your code, and can only be used once per account. Any change to this url will change the QR code itself.",
    "dashboard.qr-modal.qr-desc2": "Only letters, numbers, hyphens and underscores are allowed.",
    "dashboard.qr-modal.just-a-sec": "Just a sec...",
    "dashboard.qr-modal.create": "Create",
    "dashboard.qr-modal.upload-success": "Successfully uploaded!",

    // Print Code
    "print.button.done": "Done",
    "print.button.print-preview": "Print Preview",
    "print.toggle.title": "Print only QR Code",
    "print.toggle.description": "We've provided you a nice 4' x 6' template for printing out the code so you can easily get started! If you'd rather print just the code and design it yourself, enable this.",
    "print.button.download": "Download",
    "print.templates.title": "Visually design QR print from templates",
    "print.button.upgrade": "Upgrade to Pro to design QR templates",
    "print.templates.description": "We've provided you a nice 4' x 6' templates for printing out the code so you can easily get started! Easily make your own design with visual builder.",

    // Print Code Template
    // Finish translation: https://github.com/glasperfan/esc/issues/172
    "print.template.header": "We're putting your health first.",
    "print.template.subheader": "Scan for a touch-free menu.",
    "print.template.instruction1": "Open your phone's camera and center the code in the display.",
    "print.template.instruction2": "Tap the popup",

    // Create New
    "add-target.title": "Create a new menu for your QR code",
    "add-target.name": "Name of the menu (customers will see this!)",
    "add-target.name.placeholder": "Brunch Menu",
    "add-target.name.desc": "This is the public-facing title that customers will see!",
    "add-target.description": "Description (optional)",
    "add-target.description.placeholder": "Weeknight food menus, PDF version...",
    "add-target.description.desc": "This is the public-facing description that customers will see! We highly recommend providing one.",
    "add-target.schedule": "Menu Schedule",
    // Schedule Form
    "add-target.schedule.dropdown.always": "Always Use",
    "add-target.schedule.dropdown.always.desc": "Menu will always appear",
    "add-target.schedule.dropdown.weekly": "Based on a Weekly Schedule",
    "add-target.schedule.dropdown.weekly.desc": "The code will redirect to this if someone scans the QR code within the weekly schedule described below.",
    "add-target.schedule.dropdown.weekly.toggle": "Toggle All",
    "add-target.schedule.dropdown.weekly.sun": "Sunday",
    "add-target.schedule.dropdown.weekly.mon": "Monday",
    "add-target.schedule.dropdown.weekly.tues": "Tuesday",
    "add-target.schedule.dropdown.weekly.wed": "Wednesday",
    "add-target.schedule.dropdown.weekly.thurs": "Thursday",
    "add-target.schedule.dropdown.weekly.fri": "Friday",
    "add-target.schedule.dropdown.weekly.sat": "Saturday",
    "add-target.link-type.title": "Link Type",
    // upload
    "add-target.link-type.uploaded": "An Uploaded Menu",
    "add-target.link-type.uploaded.placeholder": "Upload the file the QR code should redirect to.",
    "add-target.link-type.uploaded.link": "Drag n' drop the file here, or click to select one",
    "add-target.link-type.uploaded.onDrag": "Drop the file here",
    "add-target.link-type.uploaded.desc1": "Don't worry about getting this perfect now. You can switch out the menu of this dynamic QR code at any time.",
    "add-target.link-type.uploaded.desc2": "Supported file types: PNG, JPG/JPEG, PDF. Max size: 5MB. Smaller sizes generally means faster downloads for your customers.",
    // url
    "add-target.link-type.url": "An Existing Website URL",
    "add-target.link-type.url.desc": "External URL to redirect to",
    "add-target.link-type.url.placeholder": "https://your-restaurant.com/menu",
    "add-target.link-type.url.website-name": "Website Name",
    "add-target.link-type.url.website-name.placeholder": "Restaurant Menu Link",
    "add-target.link-type.url.website-name.desc": "Customers will not see this. It's just for easier reference than a URL.",

    "add-target.submit": "Create",

    // Delete
    "dashboard.archive.title": "Archive This Code",
    "dashboard.archive.text1": "Are you sure you want to archive the code",
    "dashboard.archive.text2": "? This code and its menus can be recovered later as long as no active code has the same url (which is",
    "dashboard.archive.cancel-button": "Cancel",
    "dashboard.archive.archive-button": "Archive",
    "dashboard.archived-codes.title": "Archived Codes",
    "dashboard.archived-codes.name": "CODE NAME",
    "dashboard.archived-codes.url": "CODE URL",
    "dashboard.archived-codes.safe-recover": "SAFE TO RECOVER?",
    "dashboard.archived-codes.recover": "RECOVER",

    // User Profile
    "profile.title": "User Profile",
    "profile.section.email": "Email",
    "profile.section.email.desc": "Required for email or password updates.",
    "profile.section.current-password": "Current Password",
    "profile.section.current-password.placeholder": "Current Password",
    "profile.section.current-password.desc": "Required for email or password updates.",
    "profile.section.new-password": "New Password",
    "profile.section.new-password.placeholder": "New Password",
    "profile.section.new-password.desc": "Required for email or password updates. Must be 6 characters of more.",
    "profile.section.confirm-new-password": "Confirm New Password",
    "profile.section.confirm-new-password.placeholder": "Confirm New Password",
    "profile.section.confirm-new-password.desc": "Required for email or password updates.",
    "profile.section.first-name": "First Name",
    "profile.section.last-name": "Last Name",
    "profile.submit": "Update",
    "profile.subtext": "Go back to the",
    "profile.subtext-link": "dashboard",

    // Profile - Billing
    "profile.billing.section-title": "Billing",
    "profile.billing.redirect-button": "Visit Billing Portal",

    // Contact Us
    "contact.title": "Contact Us",
    "contact.name": "Your Name",
    "contact.email": "Email address",
    "contact.email.placeholder": "you@email.com",
    "contact.phone": "Phone",
    "contact.company": "Company or Restaurant Name",
    "contact.company.placeholder": "Optional",
    "contact.subject": "Subject",
    "contact.subject.placeholder": "Hello there!",
    "contact.message": "Message",
    "contact.message.placeholder": "Tell us how we can help",
    "contact.send": "Send",
    "contact.send.tooltip": "Can't wait for your message",

    // Dashboard - Miscellaneous
    "loading.20": "Loading...",
    "loading.40": "Loading your codes...",
    "loading.80": "Loading code details...",
    "loading.100": "Done!",
    "dashboard.prompt.get-started": "How do I get started?",
    "dashboard.sign-out": "Sign out",

    // Verify Email
    "verify-email.title": "Please Check Your Email For A Verification Link",
    "verify-email.subtext1": "If you are not able to see it, please check your spam folder. If you need to update your email you can do so",
    "verify-email.subtext2": "here",
    "verify-email.subtext3": "If you believe your email is correct and you have still not recevied the confirmation you can resend it by clicking",
    "verify-email.error": "Sorry, something went wrong! Please contact us so we can fix this.",
    "verify-email.resend": "Successfully Resent!",

    // Menu Landing Page
    "landing-page.default-title": "Welcome!",
    "landing-page.default-subtitle": "Visit any link below. Just click Back in your browser to return here.",
    "landing-page.pro.edit-button": "Edit",

    // Checkout Cancel
    "checkout.cancel.title": "Upgrade canceled",
    "checkout.cancel.subtitle": "No action has been taken",
    "checkout.cancel.button": "Go Back",

    // Checkout Success
    "checkout.success.title": "Welcome to Pro!",
    "checkout.success.subtitle1": "You have successfully upgraded, congratulations!",
    "checkout.success.subtitle2": "This is a commitment to safer dining experience.",
    "checkout.success.subtitle3": "Thank you for trusting us with your digital menus.",
    "checkout.success.button": "View Your Pro Dashboard",

    // Upgrade page
    "upgrade.title.1": "Find the",
    "upgrade.title.2": "perfect plan",
    "upgrade.title.3": " for your business",

    "upgrade.free.name": "Free",
    "upgrade.free.priceNote": "No credit card required",
    "upgrade.free.label0": `ESC branding on landing pages`,
    "upgrade.free.label1": `Create ${MAX_CODES_PER_TIER["basic"]} dynamic QR code menus`,
    "upgrade.free.label2": `${MAX_SCANS_PER_TIER["basic"]} monthly scans`,
    "upgrade.free.tooltip2": "Measured based on scans over all QR codes in the current month",
    "upgrade.free.label3": `Up to ${MAX_TARGETS_PER_TIER["basic"]} menus from the same code`,
    "upgrade.free.tooltip3": "Set multiple menus to display from the same QR code.",
    "upgrade.free.label4": "Automatic menu rotation",
    "upgrade.free.tooltip4": "Set up a weekly schedule. Brunch menu only on Sunday mornings? No problem.",
    "upgrade.free.label5": "Ad supported",

    "upgrade.starter.name": "Starter",
    "upgrade.starter.priceNote": "When selecting an annual plan. A monthly plan is available for",
    "upgrade.starter.label0": `No ESC branding on landing page`,
    "upgrade.starter.label1": `Create ${MAX_CODES_PER_TIER["starter"]} dynamic QR code menus`,
    "upgrade.starter.label2": `${MAX_SCANS_PER_TIER["starter"]} monthly scans`,
    "upgrade.starter.tooltip2": "Measured based on scans over all QR codes in the current month",
    "upgrade.starter.label3": `Up to ${MAX_TARGETS_PER_TIER["starter"]} menus from the same code`,
    "upgrade.starter.tooltip3": "Set multiple menus to display from the same QR code.",
    "upgrade.starter.label4": "Automatic menu rotation",
    "upgrade.starter.tooltip4": "Set up a weekly schedule. Brunch menu only on Sunday mornings? No problem.",

    "upgrade.pro.name": "Pro",
    "upgrade.pro.priceNote": "When selecting an annual plan. A monthly plan is available for",
    "upgrade.pro.label0": `No ESC branding on landing page`,
    "upgrade.pro.label1": "All free features",
    "upgrade.pro.tooltip1": "Get all the free features without the ads!",
    "upgrade.pro.label2": `Create ${MAX_CODES_PER_TIER["pro"]} Dynamic QR Code menus`,
    "upgrade.pro.label3": `${MAX_SCANS_PER_TIER["pro"]} monthly scans`,
    "upgrade.pro.tooltip3": "Measured based on scans over all QR codes in the current month",
    "upgrade.pro.label4": "Always Ad-Free",
    "upgrade.pro.tooltip4": "Your customers will never see ads. Thank you for supporting us!",
    "upgrade.pro.label5": "Branded QR codes",
    "upgrade.pro.tooltip5": "Customize the colors of each QRCode, improve scan recognition quality, and add a logo!",
    "upgrade.pro.label6": "Branded menu landing pages",
    "upgrade.pro.tooltip6": "Customize the design of the landing page of every code to match your restaurant's brand.",
    "upgrade.pro.label7": "Scan analytics",
    "upgrade.pro.tooltip7": "Track how many patrons are scanning your code and which menus they select",
    "upgrade.pro.label8": "Dedicated support",
    "upgrade.pro.tooltip8": "Reach the fastest support with a contact platform just for Pro users",
    "upgrade.pro.label9": "Visual designer for print QR",
    "upgrade.pro.tooltip9": "Choose from ready made QR templates for print and easily customize it to your needs, no designer needed!",

    "upgrade.enterprise.name": "Enterprise",
    "upgrade.enterprise.label1": "Unlimited dynamic QR code menus",
    "upgrade.enterprise.label2": "All the benefits of Pro",
    "upgrade.enterprise.tooltip2": "All of Pro with no limitations",
    "upgrade.enterprise.label3": "Custom development and features",
    "upgrade.enterprise.tooltip3": "Get the most out of your QR codes with custom feature development",
    "upgrade.enterprise.label4": "VIP support",
    "upgrade.enterprise.tooltip4": "Direct access to the Eat Safe Codes Team",

    "upgrade.misc.perMonth": "per month",
    "upgrade.misc.enterprise": "Reach out to determine the best deal for your business",
    "upgrade.misc.after-trial": "after free trial, cancel or change plan anytime",
    "upgrade.misc.current-plan": "Current Plan",

    "upgrade.buttons.free.purchase": "Select Free Plan",
    "upgrade.buttons.free.signup": "Sign up for free!",
    "upgrade.buttons.starter.purchase": "Get started with Starter",
    "upgrade.buttons.starter.annual": "Buy Annual Plan",
    "upgrade.buttons.starter.monthly": "Buy Monthly Plan",
    "upgrade.buttons.pro.purchase": "Get started with Pro",
    "upgrade.buttons.pro.annual": "Buy Annual Plan",
    "upgrade.buttons.pro.monthly": "Buy Monthly Plan",
    "upgrade.buttons.signup-offer.monthly": "Start Free Trial",
    "upgrade.buttons.enterprise": "Contact Us",
    "upgrade.buttons.manage-account": "Manage Account",

    // Signup Offer
    "signup-offer.title": `Before we take you to the Free version, try our Most Popular option free for ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} days!`,
    "signup-offer.desc.p1": "Not sure if Pro is right for you? We understand. As a new member, please enjoy a FREE month of Pro features and much higher scan limits. Get the most out of your QR code today, including branded landing pages, scan analytics, and more.",
    "signup-offer.desc.p2-a": "Note that if you do not start a free trial and continue with the free version, you will still get",
    "signup-offer.desc.p2-b": "days of Ad-Free experiences",
    "signup-offer.btn.decline": "No thanks, take me to the free version.",
    "signup-offer.starter.title": `Try our Most Popular PRO plan free for ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} days!`,
    "signup-offer.starter.desc.p1": "Not sure if Pro is right for you? We understand. As a new member, please enjoy a FREE month of Pro features and much higher scan limits. Get the most out of your QR code today, including branded landing pages, scan analytics, and more.",
    "signup-offer.starter.desc.p2-a": "By trying the Starter plan you'll be missing out",
    "signup-offer.starter.desc.p2-b": "days of Ad-Free experiences",
    "signup-offer.starter.btn.decline": "I'd like to try the Starter plan",
    "signup-offer.btn.no-thanks": "No thanks, I'll decide later.",

    // Pro Announcement
    "pro-announcement.header.1": "Announcing ",
    "pro-announcement.btn1": "Learn about Pro",
    "pro-announcement.p1": "Great news—we’re growing up! We want to sincerely thank you for helping make us the #1 digital menu provider and making restaurants a safer space to dine in these difficult times. We’re excited to announce that today we are releasing our new Pro tier with a ton of new features!",
    "pro-announcement.p2": "The Pro tier will support our operating costs and allow us to better serve our dedicated users. We hope you notice several highly requested features when you upgrade, like customizable design of QR codes and menu landing pages. Expect to see more features aimed towards Pro members.",
    "pro-announcement.p3": "For the cost of an entreé per month, you can upgrade to Pro today. An annual subscription is also available at a 20% discount. Eating safely is not a trend—your customers will expect a safer menu for a long time to come, and together we’ll be there to provide the high-quality experience.",
    "pro-announcement.p4": "Each tier will also have limits on the total QR codes and monthly scans. Please review these limits so you can decide whether Pro is right for you. On October 4th, the usage limits for each tier will be enabled. Don’t worry—if you already exceed the limit, your codes will not be deleted and your QR code will continue to function.",
    "pro-announcement.p5": "Additionally, on October 4th, we will be introducing unobtrusive advertisements on the menu landing pages in the Free tier.",
    "pro-announcement.p6": "If you wish to remove these ads from your customer’s view, unlock valuable new features, and invest in the Eat Safe platform, consider upgrading! For full details on pricing, features and limits, visit our Pricing page at ",
    "pro-announcement.p7": "We are so thankful for your investment in us and beyond excited for this new chapter of Eat Safe Codes. Send us feedback or just say hello anytime at ",

    // Free Trial Banner
    "banner.free-trial.text": "Get a free month of Pro!",
    "banner.free-month.text": "Get a free month!",

    // canceled page
    "checkout.canceled": "Checkout canceled",
    "checkout.no-action": "No action has been taken",

    // checkout success page
    "pro-features.1.title": "Branded QR Codes",
    "pro-features.1.description": "QR Codes don't have to look the same. Make yours stand out and impress your customers. Add colors, improve error correction, and even add your company logo into the code.",
    "pro-features.2.title": "Branded Menu Landing Pages",
    "pro-features.2.description": "When multiple menus are shown, provide customers with a branded landing page that matches your themes and image.",
    "pro-features.3.title": "Code Analytics",
    "pro-features.3.description": "Monitor how often your codes are being scanned with hour-by-hour scan metrics.",
    "pro-features.4.title": "Pro Usage Limits",
    "pro-features.4.description": "Stop worrying about hitting your limit. Scan up to 100,000 times every year. Need more? Let's talk and figure it out today.",
    "pro.success.welcome": "Welcome to",
    "pro.success.congrats": "You have successfully upgraded - congratulations!",
    "pro.success.body": "Signing up for Pro is a commitment to a safer dining experience, and we want to support you in that commitment every step of the way. As a Pro member, we are focused on you. Pro members deserve an affordable and constantly improving experience, and we hope you'll start to notice that today with several features that you have just unlocked.",
    "pro.success.button": "View Your Pro Dashboard",

    //tier limit
    "pro.limit.header": "You have reached your limit.",
    "pro.limit.upgrade": "You will need to upgrade to do this.",
    "pro.limit.button": "Upgrade Now",

    //menu landing
    "menu-landing.message": "There's doesn't seem to be a menu available right now.",
    "menu-landing.scans1": "You've exceeded your usage limit of",
    "menu-landing.scans2": "scans in the last 30 days. You currently have",
    "menu-landing.scans3": "scans in the last 30 days. Please contact us if you need a higher tier limit!",
    "menu-landing.scans4": "scans in the last 30 days. Upgrade to Pro for a higher usage limit!",
    "menu-landing.exceeeded": "You've exceeded the scan limit for your tier.",
    "menu-landing.limited": "Account is limited, please upgrade",

    //customize code
    "edit-code.EC_DESCRIPTION": "Enable a higher-fidelity QR code for improved error correction in case of damage to the code.",
    "edit-code.label.foreground": "Foreground Color",
    "edit-code.label.background": "Background Color",
    "edit-code.label.errorMessage": "Error creating object URL from uploaded logo",
    "edit-code.label.error": "Error Correction",
    "edit-code.label.customize": "Customize your code style.",
    "edit-code.quality.M": "Standard",
    "edit-code.quality.Q": "High",
    "edit-code.quality.H": "Best",
    "edit-code.squares": "Squares",
    "edit-code.dots": "Dots",
    "edit-code.padding": "Padding",
    "edit-code.logo.title": "Add your business logo into your code!",
    "edit-code.logo.warning": "File must be an image (.png, .jpg, .jpeg) and have a maximum size of 2MB",
    "edit-code.logo.sizeTitle": "Logo Size",
    "edit-code.logo.opacityTitle": "Logo Opacity",
    "edit-code.saveError": "Something went wrong saving the custom code design",
    "edit-code.button.save": "Save",
    "edit-code.button.back": "Go Back",

    //landing page editor
    "landing-editor.label.background": "Background",
    "landing-editor.label.primary": "Primary Text",
    "landing-editor.label.menu": "Menu",
    "landing-editor.label.menuHover": "Menu (on hover/click)",
    "landing-editor.label.menuTitle": "Menu Title",
    "landing-editor.label.menuDesc": "Menu Description",
    "landing-editor.label.header": "Header",
    "landing-editor.placeholder.header": "Welcome!",
    "landing-editor.label.desc": "Description",
    "landing-editor.placeholder.desc": "Visit any link below. Just click Back in your browser to return here.",
    "landing-editor.label.font": "Font",
    "landing-editor.label.logo": "Logo",
    "landing-editor.formTextBlocks.logo": "Upload a logo of 1MB or less!",
    "landing-editor.button.save": "Save",
    "landing-editor.button.done": "Done",

    //pro landing page
    "pro-landing.back": "Back to Dashboard",
    "pro-landing.edit": "Edit",

    //pro description
    "upgrade.btn.why-pro": "Why Choose Pro?",
    "upgrade.desc.discover": "Discover",
    "upgrade.desc.desc": "With our new",
    "upgrade.desc.desc2": "you can customize your customer's experience to match your business. In addition to the increased limits mentioned above, you can unlock the following features:",
    "upgrade.desc.bullet1": "Customized QR Codes:",
    "upgrade.desc.bullet1.desc": "Add your logo, customize the look, and more!",
    "upgrade.desc.bullet2": "Customized Customer Pages:",
    "upgrade.desc.bullet2.desc": "Create a custom experience that your customers will see when they scan.",
    "upgrade.desc.bullet3": "Analytics:",
    "upgrade.desc.bullet3.desc": "Understand your customer better with detailed analytics to determine when and how your code is being used.",

    // usage meter
    "usage-meter.desc.you-have-scanned": "Your codes have had",
    "usage-meter.desc.times": "total scans this month",
    "usage-meter.desc.low": "Nice work.",
    "usage-meter.desc.med": "Do you have enough scans left to finish the month? Upgrade for more.",
    "usage-meter.desc.high": "You don't have many scans left. Upgrade to ensure you don't run out!",
    "usage-meter.desc.max": "Oh no! You have used all available scans for this month. Your menus will no longer appear when your codes are scanned. Upgrade now to immediately increase your scan limit or wait until next month.",
    "usage-meter.title": "User Tier",
};
