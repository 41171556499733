import { IQrCode } from "./models/qrCode";
import { IQrTarget } from "./models/qrTarget";
import { IQrTargetVersion } from "./models/qrTargetVersion";

export interface ICodeTreeV2 {
    codes: { [codeId: string]: IQrCode };
    targets: { [targetId: string]: IQrTarget };
    versions: { [versionId: string]: IQrTargetVersion };
}

const emptyCodeTree = (): ICodeTreeV2 => ({
    codes: {},
    targets: {},
    versions: {},
});

const clone = (tree: ICodeTreeV2): ICodeTreeV2 => JSON.parse(JSON.stringify(tree)); 

const addCode = (tree: ICodeTreeV2, code: IQrCode) => {
    const updated = clone(tree);
    return {
        ...updated,
        codes: {
            ...updated.codes,
            [code.id]: code,
        },
    };
};

const updateCode = (tree: ICodeTreeV2, codeId: string, update: Partial<IQrCode>) => {
    const updated = clone(tree);
    if (!(codeId in updated.codes)) {
        return updated;
    }
    return {
        ...updated,
        codes: {
            ...updated.codes,
            [codeId]: {
                ...updated.codes[codeId],
                ...update,
            }
        }
    }
}

// Delete is just a flag.
const archiveCode = (tree: ICodeTreeV2, codeId: string) => updateCode(tree, codeId, { isDeleted: true });

const addTarget = (tree: ICodeTreeV2, target: IQrTarget) => {
    const updated = clone(tree);
    return {
        ...updated,
        targets: {
            ...updated.targets,
            [target.id]: target,
        },
    };
};

const updateTarget = (tree: ICodeTreeV2, targetId:  string, update: Partial<IQrTarget>) => {
    const updated = clone(tree);
    return {
        ...updated,
        targets: {
            ...updated.targets,
            [targetId]: {
                ...updated.targets[targetId],
                ...update,
            }
        },
    };
};

export function updateTargetOrder(tree: ICodeTreeV2, targetIdToOrder: { [targetId: string]: number }) {
    const updated = clone(tree);
    for (const targetId in targetIdToOrder) {
        updated.targets[targetId].order = targetIdToOrder[targetId];
    }
    return updated;
}

const deleteTarget = (tree: ICodeTreeV2, targetId: string) => updateTarget(tree, targetId, { isDeleted: true });

const addVersion = (tree: ICodeTreeV2, version: IQrTargetVersion) => {
    const updated = clone(tree);
    return {
        ...updated,
        versions: {
            ...updated.versions,
            [version.id]: version,
        },
    };
};

const updateVersion = (tree: ICodeTreeV2, versionId: string, update: Partial<IQrTargetVersion>) => {
    const updated = clone(tree);
    return {
        ...updated,
        versions: {
            ...updated.versions,
            [versionId]: {
                ...updated.versions[versionId],
                ...update,
            }
        }
    }
}

export const CodeTreeAction = {
    addCode,
    updateCode,
    archiveCode,
    addTarget,
    updateTarget,
    updateTargetOrder,
    deleteTarget,
    addVersion,
    updateVersion,
};

const allCodes = (tree: ICodeTreeV2) => Object.values(tree.codes);

// #isDeleted is an optional flag, so it's undefined or false
const activeCodes = (tree: ICodeTreeV2) => allCodes(tree).filter(c => !c.isDeleted);

const deletedCodes = (tree: ICodeTreeV2) => allCodes(tree).filter(c => c.isDeleted);

const hasNoCodes = (tree: ICodeTreeV2) => allCodes(tree).length === 0;

const urlIdentifiers = (tree: ICodeTreeV2) => allCodes(tree).map(c => c.identifier);

const activeUrlIdentifiers = (tree: ICodeTreeV2) => activeCodes(tree).map(c => c.identifier);

/**
 * Does NOT include deleted targets.
 */
const targetsForAllCodes = (tree: ICodeTreeV2) => Object.values(tree.targets).filter(t => !t.isDeleted);

const deletedTargetsForAllCodes = (tree: ICodeTreeV2) => Object.values(tree.targets).filter(t => !!t.isDeleted);

/**
 * Does NOT include deleted targets.
 */
const targetsOfCode = (tree: ICodeTreeV2, codeId: string) => targetsForAllCodes(tree).filter(t => t.codeId === codeId);

/**
 * Active = enabled.
 */
const activeTargetsForAllCodes = (tree: ICodeTreeV2) => targetsForAllCodes(tree).filter(t => t.isActive);

/**
 * Active = enabled.
 */
const activeTargetsOfCode = (tree: ICodeTreeV2, codeId: string) => targetsOfCode(tree, codeId).filter(t => t.isActive);

const allVersions = (tree: ICodeTreeV2) => Object.values(tree.versions);

const versionsOfTarget = (tree: ICodeTreeV2, targetId: string) => allVersions(tree).filter(v => v.targetId === targetId);

const latestVersionForTarget = (tree: ICodeTreeV2, targetId: string) => versionsOfTarget(tree, targetId).sort((v1, v2) => v1.version - v2.version).pop();

// returns 0 if there are no versions
const latestVersionNumberForTarget = (tree: ICodeTreeV2, targetId: string) => latestVersionForTarget(tree, targetId)?.version || 0;

/**
 * Returns the order to be used for a newly created target. 
 * New targets are positioned last. In the event that middle targets were deleted, 
 * we make sure that a new target would have the highest order of all non-deleted targets.
 */
const newTargetOrder = (tree: ICodeTreeV2, codeId: string) => {
    const targets = targetsOfCode(tree, codeId);
    const orders = targets.map((t) => t.order || 0);
    // Math.max(...[]) === -Infinity
    const maxOrderOfTargets = orders.length === 0 ? 0 : Math.max(...orders);
    // new targets are positioned last
    // in the event that middle targets were deleted, we make sure that a new target
    // would have the highest order of all non-deleted targets
    return maxOrderOfTargets + 1;
}

export const CodeTreeSelector = {
    allCodes,
    activeCodes,
    deletedCodes,
    hasNoCodes,
    urlIdentifiers,
    activeUrlIdentifiers,
    targetsForAllCodes,
    deletedTargetsForAllCodes,
    activeTargetsForAllCodes,
    targetsOfCode,
    activeTargetsOfCode,
    allVersions,
    versionsOfTarget,
    latestVersionForTarget,
    latestVersionNumberForTarget,
    newTargetOrder,
}

export const CodeTree = {
    Selector: CodeTreeSelector,
    Action: CodeTreeAction,
    EMPTY: emptyCodeTree(),
    empty: emptyCodeTree,
    clone: clone,
}
