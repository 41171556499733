import { OFFERS } from "../../../api/settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";
import { LanguageStrings } from "../lang-en";

export const deText: Partial<LanguageStrings> = {
    

	// items added 2021-11-29 13:37:10.424698
	'nav.navigation-dropdown': `Navigation `,
	'error.contact-us-message': `Es tut mir leid, dass etwas schief gelaufen ist! Möchten Sie uns wissen lassen? Wir möchten es so schnell wie möglich reparieren. Bitte melden Sie sich direkt mit uns an `,
	'home.title.title': `Dynamische QR-Code-Menüs `,
	'home.title.subtitle': `Halten Sie Ihre Kunden mit nicht kontaktlosen, digitalen Menüs sicher, die sich automatisch drehen. `,
	'home.title.make-code-button': `Einen Code machen `,
	'home.preview.title': `Einfach. Schnell. Sicher. `,
	'home.preview.subtitle1': `Essen Sie sichere Codes dauert etwa zwei Minuten, um eingerichtet zu werden. `,
	'home.preview.subtitle2': `Ernsthaft, das ist es. `,
	'home.preview.subtitle3': `Mit unserer Plattform können Sie QR-Codes erstellen, die Ihrem Restaurant zulassen `,
	'home.preview.bullet1.1': `Anzeige `,
	'home.preview.bullet1.2': `mehrere Menüs `,
	'home.preview.bullet1.3': `von demselben QR-Code `,
	'home.preview.bullet2.1': `Zeitplan-Menüs `,
	'home.preview.bullet2.2': `an bestimmten Tagen oder Zeiten erscheinen `,
	'home.preview.bullet3.1': `Fertigen Sie an und entwerfen `,
	'home.preview.bullet3.2': `Ihre QR-Codes und Menü-Landing-Seiten an Ihre Marke `,
	'home.preview.banner1.1': `Entwurf `,
	'home.preview.banner1.2': `Fügen Sie Ihre Marke hinzu `,
	'home.preview.banner2.1': `Geplante Menüs `,
	'home.preview.banner2.2': `automatisch drehen `,
	'home.preview.banner3.1': `Hochladen `,
	'home.preview.banner3.2': `Hostieren Sie Ihr Menü `,
	'home.desc.title': `Wie funktioniert es? `,
	'home.desc.desc': `Ermöglicht sich, dass sichere Codes mehrerer digitaler Menüs (PDF, JPEG, PNG usw.) hochladen und mit Kunden über QR-Codes, die an der Tabelle gescannt sind, zugänglich machen. Dies bedeutet, dass Kunden die potenzielle Ausbreitung von Krankheitserregern reduzieren, indem sie physische Menüs berührt werden. Sie können den Zeitplänen für Menüs einstellen, um automatisch zu drehen, damit Sie sich darauf konzentrieren können, was am meisten wichtig ist `,
	'home.desc.desc-bold': `Ihr Restaurant laufen lassen. `,
	'home.testimonial.text': `Wir konnten unser Papiermenü reduzieren, um eine Tonne abzuschalten, zu sehen, wie sie alle einzelnen Gebrauch sind. Der Menüdruck war früher über 150 Menüs pro Tag. Jetzt sind wir alle zwei Wochen auf 100 Jahre alt. `,
	'home.testimonial.name': `- Drew, Public School 818, Los Angeles, USA `,
	'home.photos.title': `Brauche mehr? Go Pro. `,
	'home.photos.body1': `Entsperren Sie die volle Kraft von Eat Safe Codes mit dem `,
	'home.photos.body2-bold': `Pro-Plan. `,
	'home.photos.body3': `Ermitteln Sie Scananalytik, passen Sie das Design Ihrer QR-Codes an und entwerfen Sie die Landing-Seiten, die Kunden sehen. Ein Teil der Lösung sein und helfen, die Verbreitung von zu stoppen `,
	'home.photos.body4': `COVID-19. `,
	'home.photos.button': `Schauen Sie sich unsere Pläne an `,
	'home.benefits.title': `Die Welt hat sich geändert. `,
	'home.benefits.subtitle': `Holen Sie sich Ihre Menüs mit den Zeiten `,
	'home.benefits.block1.title': `Kunden und Mitarbeiter schützen `,
	'home.benefits.block1.body1': `Schützen Sie Kunden, die eine sicherere Esserfahrung erwarten. Mitarbeiter schützen, die `,
	'home.benefits.block1.body2-bold': `riskieren ihre Gesundheit `,
	'home.benefits.block1.body3': `jeden Tag, um mit Gästen zu interagieren. `,
	'home.benefits.block2.title': `Bäume retten, Geld sparen und Stunden sparen `,
	'home.benefits.block2.body1': `Speichern Sie beim Drucken neuer Menüs. Drucken und entwerfen Sie Ihre QR-Codes `,
	'home.benefits.block2.body2-bold': `wenn `,
	'home.benefits.block2.body3': `, aktualisieren Sie die Menüs jederzeit von Ihrem Telefon oder Computer. `,
	'home.benefits.block3.title': `Automatisch menüs drehen `,
	'home.benefits.block3.body1': `Zeige Frühstück, Mittag- und Abendessen mit flexiblen wöchentlichen Zeitplänen. Haben Sie ein Happy Hour-Menü für Dienstag, 17.00 Uhr und Freitag von 5-8 Uhr? `,
	'home.benefits.block3.body2-bold': `Kein Problem. `,
	'home.benefits.block4.title': `Einfache, elegante Landing-Seiten für mehrere Menüs `,
	'home.benefits.block4.body1': `Präsentieren Sie das Abendmenü mit einer Weinkarte. Tägliche Vorspeisen mit den wöchentlichen Menüs. Unsere Menüs passen sich mit Ihrem Restaurant an. `,
	'home.benefits.block4.body2-bold': `Alles aus einem Code `,
	'home.benefits.block4.body3': `. `,
	'home.support.title': `Danke, dass Sie uns unterstützt haben! `,
	'home.support.subtitle': `Helfen Sie uns, die Welt sicher zu halten. `,
	'faq.title.largetext': `FAQ `,
	'faq.title.title': `Häufig gestellte Fragen `,
	'faq.video.title': `Schnelles Tutorial. `,
	'faq.button.to-dashboard': `Rückkehr zum Dashboard `,
	'faq.questions.q1': `Was ist ein QR-Code? `,
	'faq.questions.a1': `Ein Kurzreferenzcode (QR) -Code besteht aus schwarzen Quadraten, die in einem quadratischen Gitter auf einem weißen Hintergrund angeordnet sind, der von einem Bildgebungsgerät wie der Kamera auf Ihrem Mobiltelefon gelesen werden kann. Unsere Dienste ermöglichen es Ihren Kunden, das Menü Ihres Restaurants schnell zu verknüpfen, um für Ihr Unternehmen eindeutig generiert zu werden. Dadurch erstellt Ihre Kunden eine sichere, berührungslose Methode, um auf Ihre Menüs zuzugreifen. `,
	'faq.questions.q2': `Wie werden meine Kunden meinen QR-Code verwenden? `,
	'faq.questions.a2': `Die meisten modernen Smartphones verfügen über QR-Leser, die eingebaut sind. Bei den meisten Geräten müssen Benutzer ihre Kamera nur öffnen und den Code zentrieren (kein Foto erforderlich). Ein Popup wird magisch anscheinend Ihre Kunden in Ihrem Menü oder Ihrer Website schufen. `,
	'faq.questions.q3': `Kann ich mehrere Menüs aus demselben QR-Code auf einmal anzeigen? `,
	'faq.questions.a3': `Jawohl! Wenn mehrere Menüs zum Auftreten konfiguriert sind, bietet es Ihrem Kunden die Option, zwischen ihnen auszuwählen und zu navigieren. Wenn nur eins konfiguriert ist, wird der Benutzer direkt an dieses Menü gesendet. `,
	'faq.questions.q4': `Kann ich bestimmte Menüs zu bestimmten Zeiten einplanen? `,
	'faq.questions.a4': `Jawohl! Mit der Plattform können Sie Menüs konfigurieren, um auf einem Zeitplan der willkürlichen Komplexität anzuzeigen. Dies bedeutet zum Beispiel, dass Sie beispielsweise Wochenendbrunch, Wochentagsfrühstück, Happy Hour-Getränkeangebote, und / oder tägliche Abendessen, zu ihren angemessenen Zeiten erscheinen können - alles aus demselben QR-Code! Sie haben auch die Möglichkeit, den Mobilfunkmobil- oder Desktop-Dashboard des Managers auf dem Mobiltelefon des Managers ein- / auszuschalten. `,
	'faq.questions.q5': `Funktioniert das Management-Dashboard auf dem Handy? `,
	'faq.questions.a5': `Jep! Sie können Änderungen an Zeitplänen erstellen, die Menüs ein- und ausschalten, hinzufügen, hinzufügen und von Ihrem mobilen Gerät hinzufügen und löschen. `,
	'faq.questions.q6': `Was ist, wenn mein Kunde keinen Smartphone-QR-Leser hat? `,
	'faq.questions.a6': `Die meisten modernen Geräte verfügen über QR-Code-Leser, die eingebaut sind. Das heißt, alle Android und des iPhone haben QR-Reader-Apps, die kostenlos heruntergeladen werden können. Und als Backup ist es immer hilfreich, ein Ersatzmenü nur für den Fall verfügbar zu sein. `,
	'faq.questions.q8': `Was ist, wenn sich mein Menü ändert? `,
	'faq.questions.a8': `Sie können Menüs jederzeit mit nur wenigen Klicks hinzufügen, bearbeiten, update hinzufügen, bearbeiten, aktualisieren und löschen. Die Plattform akzeptiert die meisten Fotodate sowie dynamische Quellen wie die URL Ihrer Website. `,
	'faq.questions.q10': `Kann ich mehrere verschiedene QR-Codes haben, die auf verschiedene Restaurants oder Menüpläne eingestellt sind? `,
	'faq.questions.a10': `Sicher kann! Derzeit gibt es keine Begrenzung für die Anzahl der QR-Codes, die ein Benutzer generieren kann. `,
	'faq.questions.q11': `Was ist, wenn ich einen Code lösche? Kann ich es wiederherstellen? `,
	'faq.questions.a11': `QR-Codes können wiederhergestellt werden, solange die gleiche Eitelkeitslink beim Erstellen verwendet wird. `,
	'faq.questions.q12': `Kann ich die Reihenfolge ändern, in der Menüs den Kunden angezeigt werden? `,
	'faq.questions.a12': `Bestimmt! Ziehen Sie einfach die Menüs in das Dashboard, um ihre Anzeigebührung neu anzuordnen. `,
	'faq.questions.pro.q1': `Wie aktualisiere ich? `,
	'faq.questions.pro.a1': `Sie können Ihr Konto aktualisieren, indem Sie die Seite Upgrade besuchen. Beachten Sie, dass Sie eine Chance haben, alle Gebühren vor dem Abschluss von Zahlungen zu überprüfen. `,
	'faq.questions.pro.q2': `Kann ich mein Abonnement auf Pro abbrechen? `,
	'faq.questions.pro.a2': `Ja, jederzeit, indem Sie den Abrechnungsabschnitt Ihres Benutzerprofils besuchen. Ihre Stornierung wird sofort wirksam, und Ihre QR-Codes funktionieren nicht mehr. `,
	'faq.questions.pro.q3': `Was kostet es? `,
	'faq.questions.pro.a3': `Es ist kostenlos! Solange Sie in unseren großzügigen Nutzungsgrenzen bleiben, sind unsere Dienstleistungen völlig kostenlos. Wir umfassen kleine, unauffällige Anzeigen für diese Ebene, damit wir Ihnen diesen Service kostenlos anbieten können. Wenn Sie diese Anzeigen beseitigen möchten, und entsperren Sie eine ganze Reihe nützlicher Funktionen, um das Upgrade auf ein Pro-Konto in Betracht zu ziehen. `,
	'faq.questions.pro.q4': `Was ist in der Starter-Version enthalten? `,
	'faq.questions.pro.a4': `Alle grundlegenden Funktionen, die Sie zum Anzeigen Ihres Menüs anzeigen, ist in der Starter-Version enthalten! Um die Einschränkungen dieser Option zu sehen, sehen Sie sich die Stufe Grenzwerte an. `,
	'faq.questions.pro.q5': `Was kann ich anpassen? `,
	'faq.questions.pro.a5': `Mit unserer Pro-Option können Sie fast alles anpassen! Sie können Ihren eigenen Code entwerfen, das Erscheinungsbild bearbeiten und benutzerdefinierte Logos hinzufügen. Sie können auch die Landing-Seite bearbeiten, die Ihre Kunden sehen werden, sodass sie für Ihr Unternehmen angepasst werden. `,
	'faq.questions.pro.q6': `Was sind die Grenzen? `,
	'faq.questions.pro.a6': `Bitte sehen Sie sich die Seite "Upgrade" an, um die Limits für Scan-Uploads und mehr anzuzeigen. `,
	'faq.questions.pro.q7': `Was passiert, wenn ich das Limit überschreite? `,
	'faq.questions.pro.a7': `Sie werden nicht aus Ihrem Konto gesperrt, wenn Sie ein Limit überschreiten. Beim ersten Mal, wenn Sie Ihre erlaubte Scan-Zählung nicht überschreiten, erhalten Sie zwei Wochen, bevor die Menüs angehalten werden. Um fortzufahren, müssen Sie auf die entsprechende Ebene ein Upgrade aufrüsten. `,
	'faq.questions.pro.q8': `Gibt es ein Limit, wie viele Codes, die ich erstellen kann? `,
	'faq.questions.pro.a8': `Jawohl. Bitte verweisen Sie auf die Ebenen auf der Upgrade-Seite. Es ist wichtig zu beachten, dass archivierte Codes zu diesem Limit zählen. `,
	'faq.questions.pro.q9': `Gibt es ein Limit, wie viele Menüs, die ich erstellen kann? `,
	'faq.questions.pro.a9': `Jawohl. Finden Sie das Menülimit auf der Planungstabelle. Sie können Menüs löschen, um unter Ihrem Tierlimit zu bleiben. `,
	'faq.questions.pro.q10': `Gibt es ein Limit, wie viele Menüdateien-Uploads pro Code erstellen kann? `,
	'faq.questions.pro.a10': `Jawohl. Finden Sie den Datei-Upload-Limit auf der Planungstabelle. Auch wenn die Menüs später gelöscht werden, zählen sie immer noch auf Ihre Gesamtgesagte. `,
	'faq.questions.pro.q11': `Wie funktionieren Zahlungen? `,
	'faq.questions.pro.a11': `Für jährliche Gebühren werden Sie alle 365 Tage von den Daten des ersten Abonnements berechnet. Für monatliche Gebühren werden Sie alle 30 Tage von den Daten des ersten Abonnements berechnet. Ihre Kreditkarte wird automatisch berechnet, es sei denn, Sie stornieren Ihr Abonnement. Wir können keine teilweisen Erstattungen für nicht verwendete Zeiträume anbieten. Alle Transaktionen werden als endgültig angesehen. `,
	'faq.questions.pro.q12': `Welche Währung kann ich bezahlen? `,
	'faq.questions.pro.a12': `Unsere Zahlungspartner unterstützen mehrere Währungen, aber alle Preise sind in den USA-Dollar aufgeführt. `,
	'signup.title.title': `Anmeldung `,
	'signup.title.subtitle': `Keine Sorge, Ihr Konto läuft nicht ab, und wir wollen nicht Ihre Kreditkarte. `,
	'signup.title.welcome': `Willkommen im Klub. `,
	'signup.form.email': `Email `,
	'signup.form.email.placeholder': `Beispiel@Email.com. `,
	'signup.form.password': `Passwort `,
	'signup.form.password.placeholder': `Passwort `,
	'signup.form.first': `Vorname `,
	'signup.form.first.placeholder': `Zuerst `,
	'signup.form.last': `Nachname `,
	'signup.form.last.placeholder': `Zuletzt `,
	'signup.form.company': `Gesellschaft `,
	'signup.form.company.placeholder': `Das beste Restaurant in der Stadt `,
	'signup.form.submit': `Anmeldung `,
	'signup.redirect.text': `Schon ein Mitglied? Einloggen `,
	'signup.redirect.link': `Hier `,
	'signin.title.title': `Hallo alter Freund `,
	'signin.title.subtitle': `Anmelden, würde ya? `,
	'signin.form.email': `Email `,
	'signin.form.email.placeholder': `Beispiel@Email.com. `,
	'signin.form.password': `Passwort `,
	'signin.form.password.placeholder': `Passwort `,
	'signin.form.submit': `Einloggen `,
	'signin.form.reset-pwd.button': `Setze mein Passwort zurück `,
	'signin-form.reset-pwd.confirmation': `Vielen Dank! Wenn ein Konto für diese E-Mail vorhanden ist, senden Sie Ihnen einen Link, um Ihr Passwort zurückzusetzen. `,
	'signin-form.reset-pwd.link': `Passwort vergessen? `,
	'signin-form.reset-pwd.link.finish': `Geh zurück, um sich anmelden zu können. `,
	'signin.redirect.text': `Kein bestehendes Mitglied? Anmelden `,
	'signin.redirect.link': `Hier `,
	'dashboard.title.text1': `Gute Arbeit `,
	'dashboard.title.text2': `Diese Codes behalten die Welt gesund. `,
	'dashboard.button.text': `Erstellen Sie einen neuen QR-Code `,
	'dashboard.button.upgrade': `Upgrade auf Pro. `,
	'dashboard.get-started.get-started': `Loslegen! - `,
	'dashboard.get-started.get-started2': `Beginnen Sie mit dem Erstellen Ihres ersten QR-Codes und der Datei, auf die sie umleiten soll. `,
	'dashboard.get-started.add-menus': `Dieser Code verlinkt noch nicht auf alle Menüs! - `,
	'dashboard.get-started.add-menus2': `Klicken Sie hier, um Ihr erstes Menü hinzuzufügen. `,
	'dashboard.alerts.cant-verify': `Wir konnten Ihre Anmeldeinformationen nicht überprüfen. Bitte loggen Sie sich heraus und loggen Sie sich erneut an. `,
	'dashboard.table-headers.Order': `Befehl `,
	'dashboard.table-headers.Name': `Name `,
	'dashboard.table-headers.Name.tooltip': `Dies ist der Menüname-Gönner werden sehen `,
	'dashboard.table-headers.Active': `Aktiv `,
	'dashboard.table-headers.Active.tooltip': `Setzen Sie das ein, um dieses Menü zu deaktivieren `,
	'dashboard.table-headers.Schedule': `Zeitlicher Ablauf `,
	'dashboard.table-headers.Version': `Ausführung `,
	'dashboard.table-headers.Actions': `Aktionen `,
	'dashboard.pils.Always': `Immer `,
	'dashboard.pils.Everyday': `Jeden Tag `,
	'dashboard.pils.Never': `Niemals `,
	'dashboard.pils.Weekdays': `Wochentage `,
	'dashboard.pils.Weekends': `Wochenenden `,
	'dashboard.tooltips.header': `Verwalten `,
	'dashboard.tooltips.pro-section.if-pro': `Profi `,
	'dashboard.tooltips.pro-section.if-basic': `Upgrade für Pro-Funktionen `,
	'dashboard.tooltips.test-code': `Klicken Sie hier, um Ihren QR-Code zu testen (basierend auf der aktuellen Uhrzeit). `,
	'dashboard.tooltips.view-code': `Anzeigen oder Druckencode `,
	'dashboard.tooltips.add-code': `Fügen Sie ein neues Menü für Ihren Code hinzu `,
	'dashboard.tooltips.archive-code': `Archivieren Sie diesen Code `,
	'dashboard.tooltips.archive-code.text1': `Sind Sie sicher, dass Sie den Code archivieren möchten `,
	'dashboard.tooltips.archive-code.text2': `? Dieser Code und seine Menüs können später wiederhergestellt werden, solange kein aktiver Code dieselbe URL hat (was ist `,
	'dashboard.tooltips.design-code': `Gestalten Sie Ihren QR-Code `,
	'dashboard.tooltips.design-landing-page': `Design-QR-Code-Landing-Seite `,
	'dashboard.tooltips.view-analytics': `Nutzungsanalyse anzeigen. `,
	'dashboard.tooltips.upgrade-to-access': `Upgrade, um auf diese Funktion zuzugreifen `,
	'print.button.done': `Fertig `,
	'print.button.print-preview': `Druckvorschau `,
	'print.toggle.title': `Nur QR-Code drucken `,
	'print.toggle.description': `Wir haben Ihnen eine schöne 4'X 6-Vorlage zur Verfügung gestellt, um den Code auszudrucken, damit Sie problemlos anfangen können! Wenn Sie lieber nur den Code ausdrucken und sich selbst entwerfen möchten, aktivieren Sie dies. `,
	'print.template.header': `Wir setzen zuerst Ihre Gesundheit. `,
	'print.template.subheader': `Scannen Sie nach einem touchfreien Menü. `,
	'print.template.instruction1': `Öffnen Sie die Kamera Ihres Telefons und zentrieren Sie den Code im Display. `,
	'print.template.instruction2': `Tippen Sie auf das Popup `,
	'add-target.title': `Erstellen Sie ein neues Menü für Ihren QR-Code `,
	'add-target.name': `Name des Menüs (Kunden werden das sehen!) `,
	'add-target.name.placeholder': `Brunch-Menü. `,
	'add-target.name.desc': `Dies ist der publizierte Titel, den Kunden sehen werden! `,
	'add-target.description': `Beschreibung (optional) `,
	'add-target.description.placeholder': `Weeknight Nahrungsmittelmenüs, PDF-Version ... `,
	'add-target.description.desc': `Dies ist die öffentliche Beschreibung, die Kunden sehen werden! Wir empfehlen dringend, einen anbieten zu können. `,
	'add-target.schedule': `Menüplan `,
	'add-target.schedule.dropdown.always': `Verwenden Sie immer `,
	'add-target.schedule.dropdown.always.desc': `Das Menü erscheint immer `,
	'add-target.schedule.dropdown.weekly': `Basierend auf einem wöchentlichen Zeitplan `,
	'add-target.schedule.dropdown.weekly.desc': `Der Code wird dazu weitergeleitet, wenn jemand den QR-Code innerhalb des unten beschriebenen wöchentlichen Zeitplans scannt. `,
	'add-target.schedule.dropdown.weekly.toggle': `Toggle All. `,
	'add-target.schedule.dropdown.weekly.sun': `Sonntag `,
	'add-target.schedule.dropdown.weekly.mon': `Montag `,
	'add-target.schedule.dropdown.weekly.tues': `Dienstag `,
	'add-target.schedule.dropdown.weekly.wed': `Mittwoch `,
	'add-target.schedule.dropdown.weekly.thurs': `Donnerstag `,
	'add-target.schedule.dropdown.weekly.fri': `Freitag `,
	'add-target.schedule.dropdown.weekly.sat': `Samstag `,
	'add-target.link-type.title': `Linktyp. `,
	'add-target.link-type.uploaded': `Ein hochgeladenes Menü. `,
	'add-target.link-type.uploaded.placeholder': `Laden Sie die Datei hoch, den der QR-Code umlädt. `,
	'add-target.link-type.uploaded.link': `Ziehen Sie die Datei hier, oder klicken Sie auf, um einen auszuwählen `,
	'add-target.link-type.uploaded.onDrag': `Lassen Sie die Datei hier fallen `,
	'add-target.link-type.uploaded.desc1': `Mach dir keine Sorgen, das jetzt perfekt zu sein. Sie können das Menü dieses dynamischen QR-Codes jederzeit ausschalten. `,
	'add-target.link-type.uploaded.desc2': `Unterstützte Dateitypen `,
	'add-target.link-type.url': `Eine bestehende Website-URL `,
	'add-target.link-type.url.desc': `Externe URL zur Weiterleitung zu `,
	'add-target.link-type.url.website-name': `Webseiten-Name `,
	'add-target.link-type.url.website-name.placeholder': `Restaurant Menü Link. `,
	'add-target.link-type.url.website-name.desc': `Die Kunden sehen das nicht. Es ist nur für eine einfachere Referenz als eine URL. `,
	'add-target.submit': `Schaffen `,
	'dashboard.archive.title': `Archivieren Sie diesen Code `,
	'dashboard.archive.text1': `Sind Sie sicher, dass Sie den Code archivieren möchten `,
	'dashboard.archive.text2': `? Dieser Code und seine Menüs können später wiederhergestellt werden, solange kein aktiver Code dieselbe URL hat (was ist `,
	'dashboard.archive.cancel-button': `Abbrechen `,
	'dashboard.archive.archive-button': `Archiv `,
	'dashboard.archived-codes.title': `Archivierte Codes. `,
	'dashboard.archived-codes.name': `CODE NAME `,
	'dashboard.archived-codes.url': `Code-URL. `,
	'dashboard.archived-codes.safe-recover': `Sicher sich erholen? `,
	'dashboard.archived-codes.recover': `GENESEN `,
	'profile.title': `Benutzerprofil `,
	'profile.section.email': `Email `,
	'profile.section.email.desc': `Erforderlich für E-Mail- oder Passwortaktualisierungen. `,
	'profile.section.current-password': `Aktuelles Passwort `,
	'profile.section.current-password.placeholder': `Aktuelles Passwort `,
	'profile.section.current-password.desc': `Erforderlich für E-Mail- oder Passwortaktualisierungen. `,
	'profile.section.new-password': `Neues Kennwort `,
	'profile.section.new-password.placeholder': `Neues Kennwort `,
	'profile.section.new-password.desc': `Erforderlich für E-Mail- oder Passwortaktualisierungen. Muss 6 Zeichen mehr sein. `,
	'profile.section.confirm-new-password': `Bestätige neues Passwort `,
	'profile.section.confirm-new-password.placeholder': `Bestätige neues Passwort `,
	'profile.section.confirm-new-password.desc': `Erforderlich für E-Mail- oder Passwortaktualisierungen. `,
	'profile.section.first-name': `Vorname `,
	'profile.section.last-name': `Nachname `,
	'profile.submit': `Aktualisieren `,
	'profile.subtext': `Geh zurück zum `,
	'profile.subtext-link': `Armaturenbrett `,
	'profile.billing.section-title': `Billing `,
	'profile.billing.redirect-button': `Besuchen Sie das Abrechnungsportal `,
	'contact.title': `Kontaktiere uns `,
	'contact.name': `Dein Name `,
	'contact.email': `E-Mail-Addresse `,
	'contact.email.placeholder': `you@email.com. `,
	'contact.phone': `Telefon `,
	'contact.company': `Firma oder Restaurantname `,
	'contact.company.placeholder': `Optional `,
	'contact.subject': `Gegenstand `,
	'contact.subject.placeholder': `Hallo! `,
	'contact.message': `Nachricht `,
	'contact.message.placeholder': `Erzählen Sie uns, wie wir helfen können `,
	'contact.send': `Senden `,
	'contact.send.tooltip': `Ich kann nicht auf Ihre Nachricht warten `,
	'loading.20': `Wird geladen... `,
	'loading.40': `Laden Ihrer Codes ... `,
	'loading.80': `Code laden Details ... `,
	'loading.100': `Fertig! `,
	'dashboard.prompt.get-started': `Wie fange ich an? `,
	'dashboard.sign-out': `Ausloggen `,
	'verify-email.title': `Bitte überprüfen Sie Ihre E-Mail für einen Überprüfungslink `,
	'verify-email.subtext1': `Wenn Sie es nicht sehen können, überprüfen Sie bitte Ihren Spam-Ordner. Wenn Sie Ihre E-Mail-Adresse aktualisieren müssen, können Sie dies tun `,
	'verify-email.subtext2': `Hier `,
	'verify-email.subtext3': `Wenn Sie glauben, dass Ihre E-Mail korrekt ist und Sie noch nicht die Bestätigung erhalten haben, können Sie sie erneut senden, indem Sie auf klicken `,
	'verify-email.error': `Entschuldigung, etwas ging schief! Bitte kontaktieren Sie uns, damit wir das beheben können. `,
	'verify-email.resend': `Erfolgreicher! `,
	'landing-page.default-title': `Willkommen! `,
	'landing-page.default-subtitle': `Besuchen Sie einen beliebigen Link unten. Klicken Sie einfach in Ihren Browser zurück, um hier zurückzukehren. `,
	'landing-page.pro.edit-button': `Bearbeiten `,
	'checkout.cancel.title': `Upgrade storniert `,
	'checkout.cancel.subtitle': `Es wurde keine Aktion angenommen `,
	'checkout.cancel.button': `Geh zurück `,
	'checkout.success.title': `Willkommen auf Pro! `,
	'checkout.success.subtitle1': `Sie haben erfolgreich aktualisiert, Glückwunsch! `,
	'checkout.success.subtitle2': `Dies ist ein Engagement für sichereres Essenserfahrung. `,
	'checkout.success.subtitle3': `Vielen Dank, dass Sie uns mit Ihren digitalen Menüs vertraut haben. `,
	'checkout.success.button': `Sehen Sie sich Ihr Pro-Armaturenbrett an `,
	'upgrade.title.1': `Finde das `,
	'upgrade.title.2': `perfekter Plan `,
	'upgrade.title.3': `für dein Geschäft `,
	'upgrade.free.name': `Kostenlos `,
	'upgrade.free.priceNote': `Keine Kreditkarte benötigt `,
	'upgrade.free.label1': `Erstellen Sie ${MAX_CODES_PER_TIER["basic"]} dynamische QR-Code-Menüs `,
	'upgrade.free.label2': `${MAX_SCANS_PER_TIER["basic"]} Monatliche Scans `,
	'upgrade.free.tooltip2': `Gemessen anhand von scans über alle QR-Codes im aktuellen Monat `,
	'upgrade.free.label3': `Bis zu ${MAX_TARGETS_PER_TIER["basic"]} Menüs aus demselben Code `,
	'upgrade.free.tooltip3': `Setzen Sie mehrere Menüs, um aus demselben QR-Code anzuzeigen. `,
	'upgrade.free.label4': `Automatische Menürotation. `,
	'upgrade.free.tooltip4': `Ein wöchentlicher Zeitplan einrichten. Brunch-Menü nur am Sonntagmorgen? Kein Problem. `,
	'upgrade.starter.name': `Anlasser `,
	'upgrade.starter.priceNote': `Bei Auswahl eines Jahresplans. Ein monatlicher Plan ist für verfügbar `,
	'upgrade.starter.label1': `Erstellen Sie ${MAX_CODES_PER_TIER["starter"]} dynamische QR-Code-Menüs `,
	'upgrade.starter.label2': `${MAX_SCANS_PER_TIER["starter"]} Monatliche Scans `,
	'upgrade.starter.tooltip2': `Gemessen anhand von scans über alle QR-Codes im aktuellen Monat `,
	'upgrade.starter.label3': `Bis zu ${MAX_TARGETS_PER_TIER["starter"]} Menüs aus demselben Code `,
	'upgrade.starter.tooltip3': `Setzen Sie mehrere Menüs, um aus demselben QR-Code anzuzeigen. `,
	'upgrade.starter.label4': `Automatische Menürotation. `,
	'upgrade.starter.tooltip4': `Ein wöchentlicher Zeitplan einrichten. Brunch-Menü nur am Sonntagmorgen? Kein Problem. `,
	'upgrade.pro.name': `Profi `,
	'upgrade.pro.priceNote': `Bei Auswahl eines Jahresplans. Ein monatlicher Plan ist für verfügbar `,
	'upgrade.pro.label1': `Alle kostenlosen Funktionen `,
	'upgrade.pro.tooltip1': `Holen Sie sich alle kostenlosen Funktionen ohne Anzeigen! `,
	'upgrade.pro.label2': `Erstellen Sie ${MAX_CODES_PER_TIER["pro"]} dynamische QR-Code-Menüs `,
	'upgrade.pro.label3': `${MAX_SCANS_PER_TIER["pro"]} Monatliche Scans `,
	'upgrade.pro.tooltip3': `Gemessen anhand von scans über alle QR-Codes im aktuellen Monat `,
	'upgrade.pro.label4': `Immer ad-frei `,
	'upgrade.pro.tooltip4': `Ihre Kunden sehen niemals Anzeigen. Danke, dass Sie uns unterstützt haben! `,
	'upgrade.pro.label5': `Marken-QR-Codes `,
	'upgrade.pro.tooltip5': `Passen Sie die Farben jedes QRCODE an, verbessern Sie die Qualität der Scanerkennung und fügen Sie ein Logo hinzu! `,
	'upgrade.pro.label6': `Marken-Menü-Landing-Seiten `,
	'upgrade.pro.tooltip6': `Passen Sie das Design der Landing-Seite jedes Codes an, der mit der Marke Ihres Restaurants entspricht. `,
	'upgrade.pro.label7': `Analytik scannen. `,
	'upgrade.pro.tooltip7': `Verfolgen Sie, wie viele Gönner Ihren Code scannen und welche Menüs sie auswählen `,
	'upgrade.pro.label8': `Engagierte Unterstützung `,
	'upgrade.pro.tooltip8': `Erreichen Sie den schnellsten Support mit einer Kontaktplattform, nur für Pro-Benutzer `,
	'upgrade.enterprise.name': `Unternehmen `,
	'upgrade.enterprise.label1': `Unbegrenzte dynamische QR-Code-Menüs `,
	'upgrade.enterprise.label2': `Alle Vorteile von Pro `,
	'upgrade.enterprise.tooltip2': `Alles pro ohne Einschränkungen `,
	'upgrade.enterprise.label3': `Kundenspezifische Entwicklung und Funktionen `,
	'upgrade.enterprise.tooltip3': `Holen Sie sich das Beste aus Ihren QR-Codes mit Custom Feature-Entwicklung `,
	'upgrade.enterprise.label4': `VIP-Unterstützung `,
	'upgrade.enterprise.tooltip4': `Direkter Zugang zum EAT-Safe Codes-Team `,
	'upgrade.misc.perMonth': `pro Monat `,
	'upgrade.misc.enterprise': `Erreichen Sie, um das beste Angebot für Ihr Unternehmen zu bestimmen `,
	'upgrade.misc.after-trial': `Nach dem kostenlosen Testen, stornieren oder ändern Sie den Plan jederzeit `,
	'upgrade.misc.current-plan': `Derzeitiger Plan `,
	'upgrade.buttons.free.purchase': `Wähle einen freien Plan aus `,
	'upgrade.buttons.free.signup': `Melde dich kostenlos an! `,
	'upgrade.buttons.starter.purchase': `Beginnen Sie mit dem Starter `,
	'upgrade.buttons.starter.annual': `Jahresplan kaufen. `,
	'upgrade.buttons.starter.monthly': `Monatlicher Plan kaufen. `,
	'upgrade.buttons.pro.purchase': `Beginnen Sie mit Pro `,
	'upgrade.buttons.pro.annual': `Jahresplan kaufen. `,
	'upgrade.buttons.pro.monthly': `Monatlicher Plan kaufen. `,
	'upgrade.buttons.signup-offer.monthly': `Kostenlos testen `,
	'upgrade.buttons.enterprise': `Kontaktiere uns `,
	'signup-offer.title': `Bevor wir Sie zur kostenlosen Version bringen, probieren Sie unsere beliebteste Option kostenlos für ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} Tage! `,
	'signup-offer.desc.p1': `Nicht sicher, ob Pro für Sie richtig ist? Wir verstehen. Als neues Mitglied genießen Sie bitte einen kostenlosen Monat mit Pro-Funktionen und viel höhere Scan-Grenzwerte. Holen Sie sich heute das Beste aus Ihrem QR-Code, einschließlich Markenlandeseiten, Scananalysen und mehr. `,
	'signup-offer.desc.p2-a': `Beachten Sie, dass Sie, wenn Sie keine kostenlose Testversion beginnen und mit der kostenlosen Version fortfahren, immer noch erhalten `,
	'signup-offer.desc.p2-b': `Tage der werbefreien Erfahrungen `,
	'signup-offer.btn.decline': `Nein, danke, bring mich zur kostenlosen Version. `,
	'signup-offer.starter.title': `Testen Sie unseren beliebtesten pro Plan kostenlos für ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} Tage! `,
	'signup-offer.starter.desc.p1': `Nicht sicher, ob Pro für Sie richtig ist? Wir verstehen. Als neues Mitglied genießen Sie bitte einen kostenlosen Monat mit Pro-Funktionen und viel höhere Scan-Grenzwerte. Holen Sie sich heute das Beste aus Ihrem QR-Code, einschließlich Markenlandeseiten, Scananalysen und mehr. `,
	'signup-offer.starter.desc.p2-a': `Wenn Sie den Starter-Plan ausprobieren, werden Sie verpassen `,
	'signup-offer.starter.desc.p2-b': `Tage der werbefreien Erfahrungen `,
	'signup-offer.starter.btn.decline': `Ich würde gerne den Starter-Plan ausprobieren `,
	'pro-announcement.header.1': `Ankündigung `,
	'pro-announcement.btn1': `Erfahren Sie mehr über Pro. `,
	'pro-announcement.p1': `Tolle Nachrichten - wir wachsen auf! Wir möchten Ihnen aufrichtig danken, dass Sie den digitalen Menüanbieter # 1 digital machen und Restaurants einen sichereren Platz zum Speisen in diesen schwierigen Zeiten machen. Wir freuen uns, dass wir ankündigen, dass wir heute unsere neue Pro-Ebene mit einer Tonne neuer Funktionen freigeben! `,
	'pro-announcement.p2': `Der Pro-Tier unterstützt unsere Betriebskosten und ermöglichen es uns, unseren dedizierten Benutzern besser zu dienen. Wir hoffen, dass Sie mehrere hochgefragte Funktionen beim Upgrade bemerken, wie anpassbares Design von QR-Codes und Menülandeseiten. Erwarten Sie, weitere Funktionen zu sehen, die an Pro-Mitglieder abzielen. `,
	'pro-announcement.p3': `Für die Kosten eines Entreés pro Monat können Sie heute auf Pro aufrüsten. Ein Jahresabonnement ist auch mit einem Rabatt von 20% erhältlich. Das Essen sicher ist nicht ein Trend - Ihre Kunden erwarten lange Zeit ein sichereres Menü, um zu kommen, und zusammen sind wir da, um die hochwertige Erfahrung zu bieten. `,
	'pro-announcement.p4': `Jede Stufe hat auch Grenzen auf den gesamten QR-Codes und monatlichen Scans. Bitte überprüfen Sie diese Grenzwerte, sodass Sie entscheiden können, ob Pro für Sie richtig ist. Am 4. Oktober werden die Nutzungsgrenzen für jede Ebene aktiviert. Keine Sorge - Wenn Sie das Limit bereits überschreiten, werden Ihre Codes nicht gelöscht und Ihr QR-Code funktioniert weiter. `,
	'pro-announcement.p5': `Zusätzlich werden wir am 4. Oktober unauffällige Anzeigen auf der Menülandeseiten in der freien Ebene einführen. `,
	'pro-announcement.p6': `Wenn Sie diese Anzeigen aus der Ansicht Ihres Kunden entfernen möchten, schalten Sie wertvolle neue Funktionen frei und investieren Sie in die EAT SAFE-Plattform, um ein Upgrade aufzunehmen! Für weitere Informationen zu den Preisen, Funktionen und Limits finden Sie auf unserer Pricing-Seite bei `,
	'pro-announcement.p7': `Wir sind so dankbar für Ihre Investition in uns und darüber hinaus aufgeregt für dieses neue Kapitel von Eat Safe-Codes. Senden Sie uns ein Feedback oder sagen Sie einfach nur Hallo `,
	'banner.free-trial.text': `Erhalten Sie einen kostenlosen Monat von Pro! `,
	'checkout.canceled': `Kasse storniert `,
	'checkout.no-action': `Es wurde keine Aktion angenommen `,
	'pro-features.1.title': `Marken-QR-Codes `,
	'pro-features.1.description': `QR-Codes müssen nicht gleich aussehen. Machen Sie Ihre Kunden hervor und beeindrucken Sie Ihre Kunden. Fügen Sie Farben hinzu, verbessern Sie die Fehlerkorrektur und fügen Sie Ihr Firmenlogo sogar in den Code hinzu. `,
	'pro-features.2.title': `Marken-Menü-Landing-Seiten `,
	'pro-features.2.description': `Wenn mehrere Menüs angezeigt werden, geben Sie den Kunden eine Markenzeitseite an, die Ihren Themen und dem Bild entspricht. `,
	'pro-features.3.title': `Code Analytics. `,
	'pro-features.3.description': `Überwachen Sie, wie oft Ihre Codes mit stunden- Stunden-Scan-Metriken gescannt werden. `,
	'pro-features.4.title': `Pro-Nutzungslimits. `,
	'pro-features.4.description': `Hör auf, dir Sorgen zu machen, dein Limit zu treffen. Scannen Sie jedes Jahr bis zu 100.000 Mal. Brauche mehr? Lass uns reden und noch heute herausfinden. `,
	'pro.success.welcome': `Willkommen zu `,
	'pro.success.congrats': `Sie haben erfolgreich aufgerüstet - Herzlichen Glückwunsch! `,
	'pro.success.body': `Die Anmeldung für Pro ist ein Engagement für ein sichereres Dining-Erlebnis, und wir möchten Sie in diesem Verpflichtung jedes Schritt des Weges unterstützen. Als Pro-Mitglied konzentrieren wir uns auf Sie. Pro-Mitglieder verdienen ein erschwingliches und ständig verbessertes Erlebnis, und wir hoffen, dass Sie anfangen, das heute mit mehreren Funktionen zu bemerken, die Sie gerade nicht gesperrt haben. `,
	'pro.success.button': `Sehen Sie sich Ihr Pro-Armaturenbrett an `,
	'pro.limit.header': `Sie haben Ihr Limit erreicht. `,
	'pro.limit.upgrade': `Sie müssen das Upgrade dazu benötigen, dies zu tun. `,
	'pro.limit.button': `Jetzt upgraden `,
	'menu-landing.message': `Es scheint jetzt kein Menü zu geben, das jetzt verfügbar ist. `,
	'menu-landing.scans1': `Sie haben Ihre Nutzungsgrenze von übertroffen `,
	'menu-landing.scans2': `scannt in den letzten 30 Tagen. Zur Zeit haben Sie `,
	'menu-landing.scans3': `scannt in den letzten 30 Tagen. Bitte kontaktieren Sie uns, wenn Sie ein höheres Tierlimit benötigen! `,
	'menu-landing.scans4': `scannt in den letzten 30 Tagen. Upgrade auf Pro für ein höheres Nutzungslimit! `,
	'menu-landing.exceeeded': `Sie haben das Scanglimit für Ihre Ebene überschritten. `,
	'edit-code.EC_DESCRIPTION': `Aktivieren Sie einen QR-Code mit einem höheren Fidelity-QR für eine verbesserte Fehlerkorrektur bei Beschädigungen des Codes. `,
	'edit-code.label.foreground': `Vordergrundfarbe `,
	'edit-code.label.background': `Hintergrundfarbe `,
	'edit-code.label.errorMessage': `Fehler beim Erstellen der Objekt-URL aus dem hochgeladenen Logo `,
	'edit-code.label.customize': `Passen Sie Ihren Code-Stil an. `,
	'edit-code.quality.M': `Standard `,
	'edit-code.quality.Q': `Hoch `,
	'edit-code.quality.H': `Am besten `,
	'edit-code.squares': `Quadrate `,
	'edit-code.dots': `Dots `,
	'edit-code.padding': `Polsterung `,
	'edit-code.logo.title': `Fügen Sie Ihr Business-Logo in Ihren Code hinzu! `,
	'edit-code.logo.warning': `Die Datei muss ein Bild (.png, .jpg ,.jpeg) sein und eine maximale Größe von 2 MB haben `,
	'edit-code.logo.sizeTitle': `Logo-Größe `,
	'edit-code.logo.opacityTitle': `Logo-Deckkraft `,
	'edit-code.saveError': `Etwas ging schief, das benutzerdefinierte Code-Design zu sparen `,
	'edit-code.button.save': `Speichern `,
	'edit-code.button.back': `Geh zurück `,
	'landing-editor.label.background': `Hintergrund `,
	'landing-editor.label.primary': `Primärtext `,
	'landing-editor.label.menu': `Speisekarte `,
	'landing-editor.label.menuHover': `Menü (am Hover / Click) `,
	'landing-editor.label.menuTitle': `Menu-Titel `,
	'landing-editor.label.menuDesc': `Menübeschreibung `,
	'landing-editor.label.header': `Header `,
	'landing-editor.placeholder.header': `Willkommen! `,
	'landing-editor.label.desc': `Beschreibung `,
	'landing-editor.placeholder.desc': `Besuchen Sie einen beliebigen Link unten. Klicken Sie einfach in Ihren Browser zurück, um hier zurückzukehren. `,
	'landing-editor.label.font': `Schriftart `,
	'landing-editor.label.logo': `Logo `,
	'landing-editor.formTextBlocks.logo': `Laden Sie ein Logo von 1 MB oder weniger hoch! `,
	'landing-editor.button.save': `Speichern `,
	'landing-editor.button.done': `Fertig `,
	'pro-landing.back': `Zurück zum Dashboard `,
	'pro-landing.edit': `Bearbeiten `,
	'upgrade.btn.why-pro': `Warum Pro-Pro? `,
	'upgrade.desc.discover': `Entdecken `,
	'upgrade.desc.desc': `Mit unserem neuen. `,
	'upgrade.desc.desc2': `Sie können die Erfahrung Ihres Kunden anpassen, um Ihr Unternehmen anzugleichen. Neben den oben genannten erhöhten Grenzwerten können Sie die folgenden Funktionen freischalten `,
	'upgrade.desc.bullet1': `Kundenspezifische QR-Codes. `,
	'upgrade.desc.bullet1.desc': `Fügen Sie Ihr Logo hinzu, passen Sie den Look an und noch mehr! `,
	'upgrade.desc.bullet2': `Kundenspezifische Kundenseiten `,
	'upgrade.desc.bullet2.desc': `Erstellen Sie eine kundenspezifische Erfahrung, die Ihre Kunden sehen werden, wenn sie scannen. `,
	'upgrade.desc.bullet3': `Analytik `,
	'upgrade.desc.bullet3.desc': `Verstehen Sie Ihren Kunden besser mit detaillierten Analysen, um festzustellen, wann und wie Ihr Code verwendet wird. `,
	'usage-meter.desc.you-have-scanned': `Ihre Codes hatten `,
	'usage-meter.desc.times': `Gesamt Scans in diesem Monat `,
	'usage-meter.desc.low': `Gute Arbeit. `,
	'usage-meter.desc.med': `Haben Sie genug Scans, um den Monat zu beenden? Upgrade für mehr. `,
	'usage-meter.desc.high': `Sie haben nicht viele Scans übrig. Upgrade, um sicherzustellen, dass Sie nicht rausgehen! `,
	'usage-meter.desc.max': `Ach nein! Sie haben alle verfügbaren Scans für diesen Monat verwendet. Ihre Menüs werden nicht mehr angezeigt, wenn Ihre Codes gescannt werden. Upgrade jetzt, um das Scan-Limit sofort zu steigern oder bis nächsten Monat zu warten. `,
	'usage-meter.title': `Benutzerstufe `,

	// items added 2021-11-30 10:47:29.154731
	'dashboard.qr-modal.title': `Erstellen Sie einen neuen dynamischen QR-Code `,
	'dashboard.qr-modal.qr-name-label': `Geben Sie Ihrem QR-Code einen Namen `,
	'dashboard.qr-modal.qr-name-placeholder': `Lebensmittelmenüs `,
	'dashboard.qr-modal.qr-description-label': `Beschreibung (optional) `,
	'dashboard.qr-modal.qr-description-placeholder': `Weeknight Nahrungsmittelmenüs, PDF-Version ... `,
	'dashboard.qr-modal.qr-url-label': `Fügen Sie eine einzigartige personalisierte URL hinzu, um Ihren QR-Code zu generieren `,
	'dashboard.qr-modal.qr-desc1': `Diese URL identifiziert Ihren Code eindeutig und kann nur einmal pro Konto verwendet werden. Jede Änderung dieser URL ändert den QR-Code selbst. `,
	'dashboard.qr-modal.qr-desc2': `Nur Buchstaben, Zahlen, Bindestriche und Unterstriche sind erlaubt. `,
	'dashboard.qr-modal.just-a-sec': `Nur eine Sekunde... `,
	'dashboard.qr-modal.create': `Schaffen `,
	'dashboard.qr-modal.upload-success': `Erfolgreich hochgeladen! `,

	// items added 2021-12-01 18:52:34.577518
	'dashboard.button.upgrade-account': `Jetzt upgraden`,
	'dashboard.account.disabled': `begrenzt`,
	'signup-offer.btn.no-thanks': `Nein, danke, ich werde mich später entscheiden.`,
	'banner.free-month.text': `Holen Sie sich einen kostenlosen Monat!`,
	'menu-landing.limited': `Konto ist begrenzt, bitte ein Upgrade`,

	// items added 2021-12-15 14:19:03.095907
	'upgrade.free.label0': `ESC-Branding auf Lande-Seiten`,
	'upgrade.starter.label0': `Kein ESC-Branding auf der Anzeigenseite`,
	'upgrade.pro.label0': `Kein ESC-Branding auf der Anzeigenseite`,
	'upgrade.buttons.manage-account': `Konto verwalten`,

	// items added 2021-12-26 16:29:18.033693
	'print.button.download': `Herunterladen`,
	'print.templates.title': `Optisch entwerfen QR-Druck von Vorlagen`,
	'print.button.upgrade': `Upgrade auf Pro, um QR-Vorlagen zu gestalten`,
	'print.templates.description': `Wir haben Ihnen ein schönes 4 'x 6'-Vorlagen zur Verfügung gestellt, um den Code auszudrucken, sodass Sie einfach anfangen können! Machen Sie einfach Ihr eigenes Design mit Visual Builder.`,

	// items added 2021-12-26 22:24:16.882318
	'home.preview.banner4.1': `QR-Design drucken.`,
	'home.preview.banner4.2': `Kein Designer benötigt!`,
	'upgrade.pro.label9': `Visual Designer für Drucken QR`,
	'upgrade.pro.tooltip9': `Wählen Sie aus fertiggestellten QR-Vorlagen für Drucken und passen Sie sie einfach an Ihre Bedürfnisse an, kein Designer erforderlich!`,

	// items added 2021-12-26 22:33:25.554805

	// items added 2022-05-16 12:52:10.701162
	'upgrade.free.label5': `Unterstützt durch Werbung`,
};