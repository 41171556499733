import { OFFERS } from "../../../api/settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";
import { LanguageStrings } from "../lang-en";

export const itText: Partial<LanguageStrings> = {
    

	// items added 2021-11-29 13:46:21.646905
	'nav.navigation-dropdown': `Navigazione `,
	'error.contact-us-message': `Mi dispiace, qualcosa è andato storto! Ti dispiacerebbe farci sapere? Vorremmo sistemarlo al più presto. Per favore mettiti in contatto con noi direttamente a `,
	'home.title.title': `Menu del codice QR dinamico `,
	'home.title.subtitle': `Tenere i tuoi clienti al sicuro senza contatto senza contatto, i menu digitali che ruotano automaticamente. `,
	'home.title.make-code-button': `Fare un codice `,
	'home.preview.title': `Semplice. Presto. Sicuro. `,
	'home.preview.subtitle1': `Mangia i codici sicuri richiede circa due minuti per impostare. `,
	'home.preview.subtitle2': `Seriamente, è così. `,
	'home.preview.subtitle3': `Con la nostra piattaforma, puoi fare codici QR che permetteranno al tuo ristorante a `,
	'home.preview.bullet1.1': `Schermo `,
	'home.preview.bullet1.2': `Menù multipli `,
	'home.preview.bullet1.3': `Dallo stesso codice QR `,
	'home.preview.bullet2.1': `Pianifica i menu `,
	'home.preview.bullet2.2': `apparire in determinati giorni o tempi `,
	'home.preview.bullet3.1': `Personalizza e design. `,
	'home.preview.bullet3.2': `I tuoi codici QR e le pagine di atterraggio dei menu per abbinare il tuo marchio `,
	'home.preview.banner1.1': `Design `,
	'home.preview.banner1.2': `Aggiungi il tuo marchio `,
	'home.preview.banner2.1': `Menu programmati `,
	'home.preview.banner2.2': `Ruota automaticamente `,
	'home.preview.banner3.1': `Caricamento `,
	'home.preview.banner3.2': `Ospita il tuo menu `,
	'home.desc.title': `Come funziona? `,
	'home.desc.desc': `Mangia i codici sicuri consente di caricare più menu digitali (PDF, JPEG, PNG, ecc.) E renderli accessibili ai clienti tramite i codici QR scansionati al tavolo. Ciò significa che i clienti riducono la diffusione potenziale di agenti patogeni dal toccando i menu fisici. È possibile impostare pianificazioni per i menu per ruotare automaticamente in modo da poter rimanere concentrato su ciò che conta più `,
	'home.desc.desc-bold': `Gestire il tuo ristorante. `,
	'home.testimonial.text': `Siamo stati in grado di ridurre il nostro menu di carta scarti da una tonnellata, vedere come sono tutti un uso singolo. Stampa del menu utilizzata per avere più di 150 menù al giorno. Ora siamo giù per forse 100 ogni due settimane. `,
	'home.testimonial.name': `- Drew, scuola pubblica 818, Los Angeles, USA `,
	'home.photos.title': `Bisogno di piu? Vai Pro. `,
	'home.photos.body1': `Sblocca la piena potenza di mangiare i codici sicuri con il `,
	'home.photos.body2-bold': `Piano Pro. `,
	'home.photos.body3': `Scopri Scan Analytics, personalizza la progettazione dei tuoi codici QR e progettano le pagine di destinazione che i clienti vedono. Essere una parte della soluzione e aiuta a fermare la diffusione di `,
	'home.photos.body4': `COVID-19. `,
	'home.photos.button': `Dai un'occhiata ai nostri piani `,
	'home.benefits.title': `Il mondo è cambiato. `,
	'home.benefits.subtitle': `Prendi i tuoi menu con i tempi `,
	'home.benefits.block1.title': `Proteggi i clienti e il personale `,
	'home.benefits.block1.body1': `Proteggi i clienti che si aspettano un'esperienza culinaria più sicura. Proteggi lo staff chi. `,
	'home.benefits.block1.body2-bold': `rischiare la loro salute `,
	'home.benefits.block1.body3': `Ogni giorno per interagire con i commensali. `,
	'home.benefits.block2.title': `Salva alberi, risparmiare denaro e risparmia ore `,
	'home.benefits.block2.body1': `Risparmia sulla stampa di nuovi menu. Stampa e progetta i tuoi codici QR `,
	'home.benefits.block2.body2-bold': `una volta `,
	'home.benefits.block2.body3': `, quindi aggiornare i menu dal telefono o dal computer in qualsiasi momento. `,
	'home.benefits.block3.title': `Ruota automaticamente i menu `,
	'home.benefits.block3.body1': `Esporre la colazione, il pranzo e i menu della cena sugli orari settimanali flessibili. Avere un menu Happy Hour per martedì 5-7PM e venerdì 5-8pm? `,
	'home.benefits.block3.body2-bold': `Nessun problema. `,
	'home.benefits.block4.title': `Pagine di atterraggio semplici ed eleganti per più menù `,
	'home.benefits.block4.body1': `Presenta il menu della cena con una lista dei vini. Antipasti giornalieri con i menu settimanali. I nostri menu si adattano al tuo ristorante. `,
	'home.benefits.block4.body2-bold': `Tutto da un codice `,
	'home.benefits.block4.body3': `. `,
	'home.support.title': `Grazie per averci sostenuto! `,
	'home.support.subtitle': `Aiutaci a mantenere il mondo sicuro. `,
	'faq.title.largetext': `FAQ `,
	'faq.title.title': `Domande frequenti `,
	'faq.video.title': `Tutorial veloce `,
	'faq.button.to-dashboard': `Torna al cruscotto `,
	'faq.questions.q1': `Cos'è un codice QR? `,
	'faq.questions.a1': `Un codice rapido (QR) è composto da quadrati neri disposti in una griglia quadrata su uno sfondo bianco, che può essere letto da un dispositivo di imaging come la fotocamera del telefono cellulare. I nostri servizi consentono ai tuoi clienti di collegare rapidamente il menu del tuo ristorante ai codici generati in modo univoco per la tua attività. Questo crea un metodo sicuro, senza tocco per i tuoi clienti per accedere ai tuoi menu. `,
	'faq.questions.q2': `Come useranno i miei clienti il mio codice QR? `,
	'faq.questions.a2': `La maggior parte degli smartphone moderni dispone di lettori QR integrati. Per la maggior parte dei dispositivi, gli utenti dovranno solo aprire la propria fotocamera e centrare il codice (nessuna foto richiesta). Un popup appairà magicamente per shepherd i tuoi clienti al menu o al sito web. `,
	'faq.questions.q3': `Posso visualizzare più menu dallo stesso codice QR contemporaneamente? `,
	'faq.questions.a3': `Sì! Se vengono configurati più menù per visualizzare, fornirà il tuo cliente l'opzione selezionare e navigare tra di loro. Se solo uno è configurato per apparire, l'utente verrà inviato direttamente a quel menu. `,
	'faq.questions.q4': `Posso pianificare determinati menu per apparire in determinati momenti? `,
	'faq.questions.a4': `Sì! La piattaforma consente di configurare i menu da visualizzare su un programma di complessità arbitraria. Ciò significa, ad esempio, che è possibile configurare il brunch del weekend, la colazione della settimana, le offerte di bevande Happy Hour e / o le offerte speciali giornaliere per apparire nei loro tempi appropriati - tutto dallo stesso codice QR! Hai anche la possibilità di attivare / disattivare i menu ON / OFF dal Dashboard mobile o desktop del gestore per le modifiche al menu della mosca. `,
	'faq.questions.q5': `La dashboard di gestione funziona sul cellulare? `,
	'faq.questions.a5': `Sì! È possibile effettuare modifiche agli orari, attivare i menu on / off, aggiungi ed eliminare i menu dal tuo dispositivo mobile. `,
	'faq.questions.q6': `Cosa succede se il mio cliente non ha un lettore QR smartphone? `,
	'faq.questions.a6': `La maggior parte dei dispositivi moderni dispone di lettori di codici QR integrati. Detto questo, tutti gli Android e iPhone hanno app di QR Reader che possono essere scaricati gratuitamente. E come backup, è sempre utile avere un menu di riserva disponibile nel caso in cui. `,
	'faq.questions.q8': `Cosa succede se il mio menu cambia? `,
	'faq.questions.a8': `Sei in grado di aggiungere, modificare, aggiornare ed eliminare i menu in qualsiasi momento con pochi clic. La piattaforma accetta la maggior parte dei formati fotografici e fonti dinamiche come l'URL del tuo sito web. `,
	'faq.questions.q10': `Posso avere più codici QR diversi impostati su diversi ristoranti o programmi di menu? `,
	'faq.questions.a10': `Certo che si può! Attualmente non esiste un limite per il numero di codici QR che un utente può generare. `,
	'faq.questions.q11': `Cosa succede se cancellato un codice? Posso recuperarlo? `,
	'faq.questions.a11': `I codici QR possono essere recuperati finché lo stesso collegamento Vanity viene utilizzato quando viene ricreato. `,
	'faq.questions.q12': `Posso cambiare l'ordine in cui i menu sono mostrati ai clienti? `,
	'faq.questions.a12': `Decisamente! Basta trascinare e rilasciare i menu nel cruscotto per riorganizzare il loro ordine di visualizzazione. `,
	'faq.questions.pro.q1': `Come posso aggiornare? `,
	'faq.questions.pro.a1': `Puoi aggiornare il tuo account visitando la pagina Aggiorna. Si noti che avrai la possibilità di rivedere tutte le spese prima di finalizzare i pagamenti. `,
	'faq.questions.pro.q2': `Posso cancellare il mio abbonamento a Pro? `,
	'faq.questions.pro.a2': `Sì, in qualsiasi momento visitando la sezione di fatturazione del tuo profilo utente. La tua cancellazione entrerà in vigore immediatamente e i tuoi codici QR non funzionerà più. `,
	'faq.questions.pro.q3': `Quanto costa? `,
	'faq.questions.pro.a3': `È gratis! Finché rimani nei nostri generosi limiti di utilizzo, i nostri servizi sono completamente gratuiti. Includiamo annunci piccoli e non offensivi per questo livello in modo che possiamo offrirti questo servizio senza alcun costo. Se desideri eliminare questi annunci e sbloccare un sacco di funzionalità utili, considera l'aggiornamento a un account Pro. `,
	'faq.questions.pro.q4': `Cosa è incluso nella versione di avviamento? `,
	'faq.questions.pro.a4': `Tutte le funzionalità di base per visualizzare il menu sono incluse nella versione di avviamento! Per vedere i limiti di questa opzione, si prega di visualizzare i limiti di livello. `,
	'faq.questions.pro.q5': `Cosa posso personalizzare? `,
	'faq.questions.pro.a5': `La nostra opzione Pro ti consente di personalizzare quasi tutto! Puoi progettare il tuo codice, modificare l'aspetto e aggiungere loghi personalizzati. Puoi anche modificare la pagina di destinazione che i tuoi clienti vedranno in modo che sia personalizzato per la tua attività. `,
	'faq.questions.pro.q6': `Quali sono i limiti? `,
	'faq.questions.pro.a6': `Si prega di consultare la pagina Aggiorna per visualizzare i limiti di livello per la scansione, i caricamenti dei menu e altro ancora. `,
	'faq.questions.pro.q7': `Cosa succede se supererò il limite? `,
	'faq.questions.pro.a7': `Non sarai bloccato dal tuo account se supera un limite. Tuttavia, la prima volta che supera il tuo conteggio della scansione consentita, verrai dato due settimane prima che i menu saranno in pausa. Per continuare, è necessario eseguire l'aggiornamento al livello appropriato. `,
	'faq.questions.pro.q8': `C'è un limite a quanti codici posso creare? `,
	'faq.questions.pro.a8': `Sì. Si prega di fare riferimento ai livelli sulla pagina di aggiornamento. È importante notare che i codici archiviati contano verso questo limite. `,
	'faq.questions.pro.q9': `C'è un limite a quanti menu posso creare? `,
	'faq.questions.pro.a9': `Sì. Si prega di trovare il limite del menu sul tavolo del piano. Puoi cancellare i menu per rimanere al di sotto del tuo limite di livello. `,
	'faq.questions.pro.q10': `C'è un limite al numero di caricamenti file di menu che posso effettuare per codice? `,
	'faq.questions.pro.a10': `Sì. Si prega di trovare il limite di caricamento dei file sulla tabella Plan. Anche se i menu vengono successivamente cancellati, contareranno ancora per il totale complessivo. `,
	'faq.questions.pro.q11': `Come funzionano i pagamenti? `,
	'faq.questions.pro.a11': `Per le spese annuali verrà addebitato una volta ogni 365 giorni dai dati della prima abbonamento. Per le spese mensili, ti verranno addebitati ogni 30 giorni dai dati della prima abbonamento. La tua carta di credito verrà addebitata automaticamente a meno che non si annulla l'abbonamento. Non siamo in grado di fornire rimborsi parziali per periodi inutilizzati. Tutte le transazioni saranno considerate definitive. `,
	'faq.questions.pro.q12': `In quale valuta posso pagare? `,
	'faq.questions.pro.a12': `I nostri partner di pagamento supportano più valute, ma tutti i prezzi sono elencati in dollari degli Stati Uniti. `,
	'signup.title.title': `Iscriviti `,
	'signup.title.subtitle': `Non preoccuparti, il tuo account non scade, e non vogliamo la tua carta di credito. `,
	'signup.title.welcome': `Benvenuto nel club. `,
	'signup.form.email': `E-mail `,
	'signup.form.email.placeholder': `esempio@email.com. `,
	'signup.form.password': `Parola d'ordine `,
	'signup.form.password.placeholder': `Parola d'ordine `,
	'signup.form.first': `Nome di battesimo `,
	'signup.form.last': `Cognome `,
	'signup.form.company': `Società `,
	'signup.form.company.placeholder': `Il miglior ristorante in città `,
	'signup.form.submit': `Iscriviti `,
	'signup.redirect.text': `sei già un membro? Registrazione `,
	'signup.redirect.link': `qui `,
	'signin.title.title': `Ciao, vecchio amico `,
	'signin.title.subtitle': `Accedi, vorresti? `,
	'signin.form.email': `E-mail `,
	'signin.form.email.placeholder': `esempio@email.com. `,
	'signin.form.password': `Parola d'ordine `,
	'signin.form.password.placeholder': `Parola d'ordine `,
	'signin.form.submit': `Registrazione `,
	'signin.form.reset-pwd.button': `Resettare la mia password `,
	'signin-form.reset-pwd.confirmation': `Grazie! Se un account esiste per questa email, ti abbiamo inviato un'email a un collegamento per ripristinare la password. `,
	'signin-form.reset-pwd.link': `hai dimenticato la password? `,
	'signin-form.reset-pwd.link.finish': `Torna all'inizio. `,
	'signin.redirect.text': `Non un membro esistente? Iscrizione `,
	'signin.redirect.link': `qui `,
	'dashboard.title.text1': `Buon lavoro `,
	'dashboard.title.text2': `Questi codici stanno mantenendo il mondo sano. `,
	'dashboard.button.text': `Crea un nuovo codice QR `,
	'dashboard.button.upgrade': `L'aggiornamento a Pro `,
	'dashboard.get-started.get-started': `Iniziare! - `,
	'dashboard.get-started.get-started2': `Inizia creando il tuo primo codice QR e il file che dovrebbe reindirizzare. `,
	'dashboard.get-started.add-menus': `Questo codice non si collega ancora a nessun menu! - `,
	'dashboard.get-started.add-menus2': `Clicca qui per aggiungere il tuo primo menu. `,
	'dashboard.alerts.cant-verify': `Non siamo stati in grado di verificare le tue credenziali dell'utente. Si prega di disconnettersi e ri-accedere. `,
	'dashboard.table-headers.Order': `Ordine `,
	'dashboard.table-headers.Name': `Nome `,
	'dashboard.table-headers.Name.tooltip': `Questo è il nome del menu Patrons vedrà `,
	'dashboard.table-headers.Active.tooltip': `Impostare questo su Off per disabilitare questo menu `,
	'dashboard.table-headers.Schedule': `Programma `,
	'dashboard.table-headers.Version': `Versione `,
	'dashboard.table-headers.Actions': `Azioni `,
	'dashboard.pils.Always': `Sempre `,
	'dashboard.pils.Everyday': `Ogni giorno `,
	'dashboard.pils.Never': `Mai `,
	'dashboard.pils.Weekdays': `Giorni della settimana `,
	'dashboard.pils.Weekends': `Fine settimana `,
	'dashboard.tooltips.header': `Maneggio `,
	'dashboard.tooltips.pro-section.if-pro': `Pro. `,
	'dashboard.tooltips.pro-section.if-basic': `Aggiornamento per le funzioni Pro `,
	'dashboard.tooltips.test-code': `Clicca per testare il tuo codice QR (in base all'ora corrente). `,
	'dashboard.tooltips.view-code': `Visualizza o stampa codice `,
	'dashboard.tooltips.add-code': `Aggiungi un nuovo menu per il tuo codice `,
	'dashboard.tooltips.archive-code': `Archivio questo codice `,
	'dashboard.tooltips.archive-code.text1': `Sei sicuro di voler archiviare il codice `,
	'dashboard.tooltips.archive-code.text2': `? Questo codice e i suoi menu possono essere recuperati più tardi fino a quando nessun codice attivo ha lo stesso URL (che è `,
	'dashboard.tooltips.design-code': `Progetta il tuo codice QR `,
	'dashboard.tooltips.design-landing-page': `Design Codice QR Landing Page `,
	'dashboard.tooltips.view-analytics': `Visualizza l'utilizzo dell'utilizzo `,
	'dashboard.tooltips.upgrade-to-access': `Aggiornamento per accedere a questa funzione `,
	'dashboard.qr-modal.title': `Crea un nuovo codice QR dinamico `,
	'print.button.done': `Fatto `,
	'print.button.print-preview': `Anteprima di stampa `,
	'print.toggle.title': `Stampa solo il codice QR `,
	'print.toggle.description': `Ti abbiamo fornito un bel modello 4 'x 6' per stampare il codice in modo da poter iniziare facilmente! Se preferisci stampare solo il codice e progettalo da solo, abilita questo. `,
	'print.template.header': `Prima stiamo mettendo la tua salute. `,
	'print.template.subheader': `Scansione per un menu senza tocco. `,
	'print.template.instruction1': `Apri la fotocamera del telefono e centra il codice sul display. `,
	'print.template.instruction2': `Tocca il popup `,
	'add-target.title': `Crea un nuovo menu per il tuo codice QR `,
	'add-target.name': `Nome del menu (i clienti vedranno questo!) `,
	'add-target.name.placeholder': `Menu brunch. `,
	'add-target.name.desc': `Questo è il titolo di fronte al pubblico che i clienti vedranno! `,
	'add-target.description': `Descrizione (facoltativa) `,
	'add-target.description.placeholder': `Menù di cibo per la settimana, versione PDF ... `,
	'add-target.description.desc': `Questa è la descrizione rivolta al pubblico che i clienti vedranno! Consigliamo vivamente di fornire uno. `,
	'add-target.schedule': `Pianificazione del menu `,
	'add-target.schedule.dropdown.always': `Usa sempre. `,
	'add-target.schedule.dropdown.always.desc': `Il menu apparirà sempre `,
	'add-target.schedule.dropdown.weekly': `Basato su un programma settimanale `,
	'add-target.schedule.dropdown.weekly.desc': `Il codice verrà reindirizzato a questo se qualcuno esegue la scansione del codice QR all'interno del programma settimanale descritto di seguito. `,
	'add-target.schedule.dropdown.weekly.toggle': `Toggle all. `,
	'add-target.schedule.dropdown.weekly.sun': `Domenica `,
	'add-target.schedule.dropdown.weekly.mon': `lunedì `,
	'add-target.schedule.dropdown.weekly.tues': `Martedì `,
	'add-target.schedule.dropdown.weekly.wed': `mercoledì `,
	'add-target.schedule.dropdown.weekly.thurs': `giovedi `,
	'add-target.schedule.dropdown.weekly.fri': `venerdì `,
	'add-target.schedule.dropdown.weekly.sat': `il sabato `,
	'add-target.link-type.title': `Tipo di collegamento `,
	'add-target.link-type.uploaded': `Un menu caricato `,
	'add-target.link-type.uploaded.placeholder': `Carica il file in cui il codice QR dovrebbe reindirizzare. `,
	'add-target.link-type.uploaded.link': `Trascina e 'rilascia qui il file o clicca per selezionarne uno `,
	'add-target.link-type.uploaded.onDrag': `Drop il file qui `,
	'add-target.link-type.uploaded.desc1': `Non preoccuparti di ottenere questo perfetto ora. È possibile disattivare il menu di questo codice QR dinamico in qualsiasi momento. `,
	'add-target.link-type.uploaded.desc2': `Tipi di file supportati `,
	'add-target.link-type.url': `Un URL del sito Web esistente `,
	'add-target.link-type.url.desc': `URL esterno da reindirizzare `,
	'add-target.link-type.url.website-name': `Nome del sito web `,
	'add-target.link-type.url.website-name.placeholder': `Collegamento del menu del ristorante. `,
	'add-target.link-type.url.website-name.desc': `I clienti non vedranno questo. È solo per un riferimento più facile di un URL. `,
	'add-target.submit': `Creare `,
	'dashboard.archive.title': `Archivio questo codice `,
	'dashboard.archive.text1': `Sei sicuro di voler archiviare il codice `,
	'dashboard.archive.text2': `? Questo codice e i suoi menu possono essere recuperati più tardi fino a quando nessun codice attivo ha lo stesso URL (che è `,
	'dashboard.archive.cancel-button': `Annulla `,
	'dashboard.archive.archive-button': `Archivio `,
	'dashboard.archived-codes.title': `Codici archiviati `,
	'dashboard.archived-codes.name': `NOME IN CODICE `,
	'dashboard.archived-codes.url': `Codice URL. `,
	'dashboard.archived-codes.safe-recover': `Sicuro da recuperare? `,
	'dashboard.archived-codes.recover': `RECUPERARE `,
	'profile.title': `Profilo utente `,
	'profile.section.email': `E-mail `,
	'profile.section.email.desc': `Richiesto per gli aggiornamenti di e-mail o password. `,
	'profile.section.current-password': `password attuale `,
	'profile.section.current-password.placeholder': `password attuale `,
	'profile.section.current-password.desc': `Richiesto per gli aggiornamenti di e-mail o password. `,
	'profile.section.new-password': `nuova password `,
	'profile.section.new-password.placeholder': `nuova password `,
	'profile.section.new-password.desc': `Richiesto per gli aggiornamenti di e-mail o password. Deve essere di più di 6 caratteri. `,
	'profile.section.confirm-new-password': `Conferma la nuova password `,
	'profile.section.confirm-new-password.placeholder': `Conferma la nuova password `,
	'profile.section.confirm-new-password.desc': `Richiesto per gli aggiornamenti di e-mail o password. `,
	'profile.section.first-name': `Nome di battesimo `,
	'profile.section.last-name': `Cognome `,
	'profile.submit': `Aggiornare `,
	'profile.subtext': `Torna al `,
	'profile.subtext-link': `Pannello `,
	'profile.billing.section-title': `Fatturazione `,
	'profile.billing.redirect-button': `Visita il portale di fatturazione `,
	'contact.title': `Contattaci `,
	'contact.name': `Il tuo nome `,
	'contact.email': `Indirizzo email `,
	'contact.email.placeholder': `you@email.com. `,
	'contact.phone': `Telefono `,
	'contact.company': `Nome dell'azienda o del ristorante `,
	'contact.company.placeholder': `Opzionale `,
	'contact.subject.placeholder': `Ciao! `,
	'contact.message': `Messaggio `,
	'contact.message.placeholder': `Dicci come possiamo aiutare `,
	'contact.send': `Spedire `,
	'contact.send.tooltip': `Non posso aspettare il tuo messaggio `,
	'loading.20': `Caricamento in corso... `,
	'loading.40': `Caricamento dei tuoi codici ... `,
	'loading.80': `Caricamento dei dettagli del codice ... `,
	'loading.100': `Fatto! `,
	'dashboard.prompt.get-started': `Come posso iniziare? `,
	'dashboard.sign-out': `disconnessione `,
	'verify-email.title': `Si prega di controllare la tua email per un collegamento di verifica `,
	'verify-email.subtext1': `Se non sei in grado di vederlo, controlla la cartella Spam. Se hai bisogno di aggiornare la tua email, puoi farlo `,
	'verify-email.subtext2': `qui `,
	'verify-email.subtext3': `Se ritieni che la tua e-mail sia corretta e non hai ancora ricevuto la conferma che puoi inviarlo facendo clic su `,
	'verify-email.error': `Scusa, qualcosa è andato storto! Vi preghiamo di contattarci in modo che possiamo risolvere questo problema. `,
	'verify-email.resend': `Risentito con successo! `,
	'landing-page.default-title': `Accoglienza! `,
	'landing-page.default-subtitle': `Visita qualsiasi link qui sotto. Basta fare clic sul tuo browser per tornare qui. `,
	'landing-page.pro.edit-button': `Modificare `,
	'checkout.cancel.title': `Aggiornamento cancellato `,
	'checkout.cancel.subtitle': `Nessuna azione è stata presa `,
	'checkout.cancel.button': `Torna indietro `,
	'checkout.success.title': `Benvenuto in Pro! `,
	'checkout.success.subtitle1': `Hai aggiornato con successo, congratulazioni! `,
	'checkout.success.subtitle2': `Questo è un impegno per un'esperienza culinaria più sicura. `,
	'checkout.success.subtitle3': `Grazie per averti fidato con i tuoi menu digitali. `,
	'checkout.success.button': `Visualizza il tuo Dashboard Pro `,
	'upgrade.title.1': `Trovare la `,
	'upgrade.title.2': `Piano perfetto `,
	'upgrade.title.3': `per la tua attività `,
	'upgrade.free.priceNote': `Nessuna carta di credito richiesta `,
	'upgrade.free.label1': `Crea ${MAX_CODES_PER_TIER["basic"]} Menu di codice QR dinamico `,
	'upgrade.free.label2': `${MAX_SCANS_PER_TIER["basic"]} Scansioni mensili `,
	'upgrade.free.tooltip2': `Misurato in base alle scansioni su tutti i codici QR nel mese corrente `,
	'upgrade.free.label3': `Menu fino a ${MAX_TARGETS_PER_TIER["basic"]} dallo stesso codice `,
	'upgrade.free.tooltip3': `Imposta più menu da visualizzare dallo stesso codice QR. `,
	'upgrade.free.label4': `Rotazione automatica del menu `,
	'upgrade.free.tooltip4': `Impostare un programma settimanale. Menu brunch solo domenica mattina? Nessun problema. `,
	'upgrade.starter.name': `Antipasto `,
	'upgrade.starter.priceNote': `Quando si seleziona un piano annuale. Un piano mensile è disponibile per `,
	'upgrade.starter.label1': `Crea ${MAX_CODES_PER_TIER["starter"]} Menu del codice QR dinamico `,
	'upgrade.starter.label2': `${MAX_SCANS_PER_TIER["starter"]} Scansioni mensili `,
	'upgrade.starter.tooltip2': `Misurato in base alle scansioni su tutti i codici QR nel mese corrente `,
	'upgrade.starter.label3': `Menu fino a ${MAX_TARGETS_PER_TIER["starter"]} dallo stesso codice `,
	'upgrade.starter.tooltip3': `Imposta più menu da visualizzare dallo stesso codice QR. `,
	'upgrade.starter.label4': `Rotazione automatica del menu `,
	'upgrade.starter.tooltip4': `Impostare un programma settimanale. Menu brunch solo domenica mattina? Nessun problema. `,
	'upgrade.pro.name': `Pro. `,
	'upgrade.pro.priceNote': `Quando si seleziona un piano annuale. Un piano mensile è disponibile per `,
	'upgrade.pro.label1': `Tutte le caratteristiche gratuite `,
	'upgrade.pro.tooltip1': `Ottieni tutte le funzionalità gratuite senza gli annunci! `,
	'upgrade.pro.label2': `Crea ${MAX_CODES_PER_TIER["pro"]} Menu di codice QR dinamico `,
	'upgrade.pro.label3': `${MAX_SCANS_PER_TIER["pro"]} Scansioni mensili `,
	'upgrade.pro.tooltip3': `Misurato in base alle scansioni su tutti i codici QR nel mese corrente `,
	'upgrade.pro.label4': `Sempre senza pubblicità `,
	'upgrade.pro.tooltip4': `I tuoi clienti non vedranno mai gli annunci. Grazie per averci sostenuto! `,
	'upgrade.pro.label5': `Codici QR di marca `,
	'upgrade.pro.tooltip5': `Personalizza i colori di ogni QRCode, migliorare la qualità del riconoscimento della scansione e aggiungi un logo! `,
	'upgrade.pro.label6': `Pagine di atterraggio del menu di marca `,
	'upgrade.pro.tooltip6': `Personalizza il design della pagina di destinazione di ogni codice per abbinare il marchio del tuo ristorante. `,
	'upgrade.pro.label7': `Scansione Analytics. `,
	'upgrade.pro.tooltip7': `Traccia quanti clienti scannerizza il tuo codice e quali menu scelgono `,
	'upgrade.pro.label8': `Supporto dedicato `,
	'upgrade.pro.tooltip8': `Raggiungi il supporto più veloce con una piattaforma di contatto solo per gli utenti Pro `,
	'upgrade.enterprise.name': `Impresa `,
	'upgrade.enterprise.label1': `Menu del codice QR dinamico illimitato `,
	'upgrade.enterprise.label2': `Tutti i vantaggi del professionista `,
	'upgrade.enterprise.tooltip2': `Tutto di Pro senza limiti `,
	'upgrade.enterprise.label3': `Sviluppo e funzionalità personalizzate `,
	'upgrade.enterprise.tooltip3': `Ottieni il massimo dai tuoi codici QR con lo sviluppo di funzionalità personalizzate `,
	'upgrade.enterprise.label4': `Supporto VIP. `,
	'upgrade.enterprise.tooltip4': `Accesso diretto al team di codici sicuri `,
	'upgrade.misc.perMonth': `al mese `,
	'upgrade.misc.enterprise': `Raggiungi per determinare il miglior affare per la tua attività `,
	'upgrade.misc.after-trial': `Dopo la prova gratuita, annullare o modificare il piano in qualsiasi momento `,
	'upgrade.misc.current-plan': `Piano attuale `,
	'upgrade.buttons.free.purchase': `Seleziona il piano gratuito `,
	'upgrade.buttons.free.signup': `Iscriviti gratis! `,
	'upgrade.buttons.starter.purchase': `Inizia con Starter `,
	'upgrade.buttons.starter.annual': `Acquista piano annuale `,
	'upgrade.buttons.starter.monthly': `Acquista piano mensile `,
	'upgrade.buttons.pro.purchase': `Inizia con Pro `,
	'upgrade.buttons.pro.annual': `Acquista piano annuale `,
	'upgrade.buttons.pro.monthly': `Acquista piano mensile `,
	'upgrade.buttons.signup-offer.monthly': `Inizia la prova gratuita `,
	'upgrade.buttons.enterprise': `Contattaci `,
	'signup-offer.title': `Prima di portarti alla versione gratuita, prova la nostra opzione più popolare gratuita per ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} giorni! `,
	'signup-offer.desc.p1': `Non sei sicuro se Pro è giusto per te? Capiamo. Come nuovo membro, si prega di godere di un mese gratuito di caratteristiche Pro e limiti di scansione molto più elevati. Ottieni il massimo dal tuo codice QR oggi, comprese le pagine di destinazione di marca, analisi di scansione e altro ancora. `,
	'signup-offer.desc.p2-a': `Si noti che se non si avvia una prova gratuita e continua con la versione gratuita, sarai comunque `,
	'signup-offer.desc.p2-b': `giorni di esperienze senza pubblicità `,
	'signup-offer.btn.decline': `No grazie, portami alla versione gratuita. `,
	'signup-offer.starter.title': `Prova il nostro Piano Pro più popolare gratuito per ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} giorni! `,
	'signup-offer.starter.desc.p1': `Non sei sicuro se Pro è giusto per te? Capiamo. Come nuovo membro, si prega di godere di un mese gratuito di caratteristiche Pro e limiti di scansione molto più elevati. Ottieni il massimo dal tuo codice QR oggi, comprese le pagine di destinazione di marca, analisi di scansione e altro ancora. `,
	'signup-offer.starter.desc.p2-a': `Provando il piano di avviamento ti mancherà `,
	'signup-offer.starter.desc.p2-b': `giorni di esperienze senza pubblicità `,
	'signup-offer.starter.btn.decline': `Mi piacerebbe provare il piano di avviamento `,
	'pro-announcement.header.1': `Annuncio `,
	'pro-announcement.btn1': `Scopri il Pro. `,
	'pro-announcement.p1': `Grandi notizie: stiamo crescendo! Vogliamo sinceramente grazie per averci contribuito a renderci il fornitore di menu digitale n. 1 e fare ristoranti uno spazio più sicuro per cenare in questi tempi difficili. Siamo entusiasti di annunciare che oggi stiamo rilasciando il nostro nuovo livello con una tonnellata di nuove funzionalità! `,
	'pro-announcement.p2': `Il Pro Tier supporterà i nostri costi operativi e ci consente di servire meglio i nostri utenti dedicati. Ci auguriamo di notare diverse funzionalità altamente richieste quando si aggiorna, come la progettazione personalizzabile dei codici QR e delle pagine di atterraggio dei menu. Aspettatevi di vedere più funzionalità finalizzate ai membri Pro. `,
	'pro-announcement.p3': `Per il costo di un entreé al mese, puoi eseguire l'aggiornamento a pro oggi. Un abbonamento annuale è disponibile anche a uno sconto del 20%. Mangiare in sicurezza non è una tendenza, i tuoi clienti si aspettano un menu più sicuro per molto tempo per venire, e insieme saremo lì per fornire l'esperienza di alta qualità. `,
	'pro-announcement.p4': `Ogni livello avrà anche limiti sui codici QR totali e sulle scansioni mensili. Si prega di rivedere questi limiti in modo da poter decidere se Pro è giusto per te. Il 4 ottobre, i limiti di utilizzo per ogni livello saranno abilitati. Non preoccuparti, se hai già superato il limite, i tuoi codici non verranno eliminati e il tuo codice QR continuerà a funzionare. `,
	'pro-announcement.p5': `Inoltre, il 4 ottobre introdurremo pubblicità discreta sul menu Pagine di atterraggio nel livello gratuito. `,
	'pro-announcement.p6': `Se desideri rimuovere questi annunci dalla visualizzazione del cliente, sblocca preziose nuove funzionalità e investire nella piattaforma di sicurezza mangia, considera l'aggiornamento! Per dettagli completi sui prezzi, le caratteristiche e i limiti, visita la nostra pagina dei prezzi a `,
	'pro-announcement.p7': `Siamo così grati per il tuo investimento in noi e oltre entusiasmo per questo nuovo capitolo di mangiare i codici sicuri. Inviaci un feedback o semplicemente saluta in qualsiasi momento a `,
	'banner.free-trial.text': `Ottieni un mese gratis di Pro! `,
	'checkout.canceled': `Checkout cancellato `,
	'checkout.no-action': `Nessuna azione è stata presa `,
	'pro-features.1.title': `Codici QR di marca `,
	'pro-features.1.description': `I codici QR non devono apparire uguali. Fai spiccare il tuo e impressiona i tuoi clienti. Aggiungi colori, migliorare la correzione degli errori e persino aggiungere il logo della tua azienda nel codice. `,
	'pro-features.2.title': `Pagine di atterraggio del menu di marca `,
	'pro-features.2.description': `Quando vengono visualizzati più menù, fornire ai clienti una pagina di destinazione di marca che corrisponde ai tuoi temi e all'immagine. `,
	'pro-features.3.title': `Code Analytics. `,
	'pro-features.3.description': `Monitorare con quale frequenza spesso i tuoi codici vengono scansionati con metriche di scansione di un'ora. `,
	'pro-features.4.title': `Limiti di utilizzo pro. `,
	'pro-features.4.description': `Smetti di preoccuparti di colpire il tuo limite. Scansione fino a 100.000 volte all'anno. Bisogno di piu? Parliamo e capitò oggi. `,
	'pro.success.welcome': `Benvenuto a `,
	'pro.success.congrats': `Hai aggiornato con successo - Congratulazioni! `,
	'pro.success.body': `La registrazione per Pro è un impegno per un'esperienza culinaria più sicura, e vogliamo supportarti in quell'impegno ogni passo del modo. Come membro professionista, ci siamo concentrati su di te. I membri Pro meritano un'esperienza economica e costantemente migliorando, e speriamo che inizierai a notare che oggi con diverse funzionalità che hai appena sbloccato. `,
	'pro.success.button': `Visualizza il tuo Dashboard Pro `,
	'pro.limit.header': `Hai raggiunto il tuo limite. `,
	'pro.limit.upgrade': `Dovrai aggiornare per farlo. `,
	'pro.limit.button': `Aggiorna ora `,
	'menu-landing.message': `Non sembra essere un menu disponibile in questo momento. `,
	'menu-landing.scans1': `Hai superato il tuo limite di utilizzo di `,
	'menu-landing.scans2': `scansioni negli ultimi 30 giorni. Attualmente hai `,
	'menu-landing.scans3': `scansioni negli ultimi 30 giorni. Vi preghiamo di contattarci se hai bisogno di un limite di livello superiore! `,
	'menu-landing.scans4': `scansioni negli ultimi 30 giorni. Aggiorna a Pro per un limite di utilizzo più elevato! `,
	'menu-landing.exceeeded': `Hai superato il limite di scansione per il tuo livello. `,
	'edit-code.EC_DESCRIPTION': `Abilitare un codice QR più alto-fedeltà per una migliore correzione degli errori in caso di danni al codice. `,
	'edit-code.label.foreground': `Colore in primo piano `,
	'edit-code.label.background': `Colore di sfondo `,
	'edit-code.label.errorMessage': `Errore durante la creazione dell'URL dell'oggetto dal logo caricato `,
	'edit-code.label.customize': `Personalizza il tuo stile di codice. `,
	'edit-code.quality.M': `Standard `,
	'edit-code.quality.Q': `Alto `,
	'edit-code.quality.H': `Migliore `,
	'edit-code.squares': `Piazze `,
	'edit-code.dots': `Punti `,
	'edit-code.padding': `Imbottitura `,
	'edit-code.logo.title': `Aggiungi il tuo logo aziendale nel tuo codice! `,
	'edit-code.logo.warning': `Il file deve essere un'immagine (.png, .jpg, .jpeg) e avere una dimensione massima di 2 MB `,
	'edit-code.logo.sizeTitle': `Dimensione del logo `,
	'edit-code.logo.opacityTitle': `Logo opacità. `,
	'edit-code.saveError': `Qualcosa è andato storto a salvare il design del codice personalizzato `,
	'edit-code.button.save': `Salva `,
	'edit-code.button.back': `Torna indietro `,
	'landing-editor.label.background': `Sfondo `,
	'landing-editor.label.primary': `Testo primario `,
	'landing-editor.label.menu': `Menù `,
	'landing-editor.label.menuHover': `Menu (su Hover / click) `,
	'landing-editor.label.menuTitle': `Titolo del menu `,
	'landing-editor.label.menuDesc': `Descrizione del menu `,
	'landing-editor.label.header': `Intestazione `,
	'landing-editor.placeholder.header': `Accoglienza! `,
	'landing-editor.label.desc': `Descrizione `,
	'landing-editor.placeholder.desc': `Visita qualsiasi link qui sotto. Basta fare clic sul tuo browser per tornare qui. `,
	'landing-editor.label.font': `Font `,
	'landing-editor.label.logo': `Logo `,
	'landing-editor.formTextBlocks.logo': `Carica un logo di 1 MB o meno! `,
	'landing-editor.button.save': `Salva `,
	'landing-editor.button.done': `Fatto `,
	'pro-landing.back': `Torna al cruscotto `,
	'pro-landing.edit': `Modificare `,
	'upgrade.btn.why-pro': `Perché scegliere Pro? `,
	'upgrade.desc.discover': `Scoprire `,
	'upgrade.desc.desc': `Con il nostro nuovo. `,
	'upgrade.desc.desc2': `Puoi personalizzare l'esperienza del tuo cliente per abbinare la tua attività. Oltre ai limiti aumentati sopra menzionati, è possibile sbloccare le seguenti funzionalità `,
	'upgrade.desc.bullet1': `Codici QR personalizzati `,
	'upgrade.desc.bullet1.desc': `Aggiungi il tuo logo, personalizza il look e altro! `,
	'upgrade.desc.bullet2': `Pagine dei clienti personalizzate `,
	'upgrade.desc.bullet2.desc': `Crea un'esperienza personalizzata che i tuoi clienti vedranno quando scansionano. `,
	'upgrade.desc.bullet3': `Analytics. `,
	'upgrade.desc.bullet3.desc': `Comprendi il tuo cliente meglio con Analytics dettagliato per determinare quando e come viene utilizzato il tuo codice. `,
	'usage-meter.desc.you-have-scanned': `I tuoi codici hanno avuto `,
	'usage-meter.desc.times': `Scansioni totali questo mese `,
	'usage-meter.desc.low': `Bel lavoro. `,
	'usage-meter.desc.med': `Hai abbastanza scansioni lasciate per finire il mese? Aggiornamento di più. `,
	'usage-meter.desc.high': `Non hai molte scansioni rimaste. Aggiornamento per assicurarti di non esaurire! `,
	'usage-meter.desc.max': `Oh no! Hai usato tutte le scansioni disponibili per questo mese. I tuoi menu non appairanno più quando i tuoi codici sono scansionati. Aggiorna ora per aumentare immediatamente il limite di scansione o attendere il prossimo mese. `,
	'usage-meter.title': `User Tier. `,

	// items added 2021-11-30 10:47:43.195360
	'dashboard.qr-modal.qr-name-label': `Dai il tuo codice QR un nome `,
	'dashboard.qr-modal.qr-name-placeholder': `Menù alimentari `,
	'dashboard.qr-modal.qr-description-label': `Descrizione (facoltativa) `,
	'dashboard.qr-modal.qr-description-placeholder': `Menù di cibo per la settimana, versione PDF ... `,
	'dashboard.qr-modal.qr-url-label': `Aggiungi un URL personalizzato unico per generare il tuo codice QR `,
	'dashboard.qr-modal.qr-desc1': `Questo URL identifica in modo univoco il tuo codice e può essere utilizzato solo una volta per account. Qualsiasi modifica a questo URL cambierà il codice QR stesso. `,
	'dashboard.qr-modal.qr-desc2': `Sono consentite solo lettere, numeri, trattini e caratteri di sottolineati. `,
	'dashboard.qr-modal.just-a-sec': `Solo un secondo... `,
	'dashboard.qr-modal.create': `Creare `,
	'dashboard.qr-modal.upload-success': `Caricato con successo! `,

	// items added 2021-12-01 18:52:38.289609
	'dashboard.button.upgrade-account': `Aggiorna ora`,
	'signup-offer.btn.no-thanks': `No grazie, deciderò più tardi.`,
	'banner.free-month.text': `Ottieni un mese gratis!`,
	'menu-landing.limited': `L'account è limitato, si prega di aggiornare`,

	// items added 2021-12-15 14:19:05.245279
	'upgrade.free.label0': `Branding ESC sulle pagine di destinazione`,
	'upgrade.starter.label0': `Nessun marchio ESC sulla pagina di destinazione`,
	'upgrade.pro.label0': `Nessun marchio ESC sulla pagina di destinazione`,
	'upgrade.buttons.manage-account': `Gestisci profilo`,

	// items added 2021-12-26 16:29:21.736028
	'print.button.download': `Scarica`,
	'print.templates.title': `Progettazione visivamente QR Stampa da modelli`,
	'print.button.upgrade': `AGGIORNAMENTO A PRO per progettare i modelli QR`,
	'print.templates.description': `Ti abbiamo fornito un bel 4 "x 6" modelli per la stampa del codice in modo da poter iniziare facilmente! Fai facilmente il tuo design con Visual Builder.`,

	// items added 2021-12-26 22:24:22.460358
	'home.preview.banner4.1': `Stampa QR Design.`,
	'home.preview.banner4.2': `Nessun Designer necessario!`,
	'upgrade.pro.label9': `Designer visivo per stampa QR`,
	'upgrade.pro.tooltip9': `Scegli tra i modelli QR ready realizzati per la stampa e personalizzarlo facilmente alle tue esigenze, nessun designer necessario!`,

	// items added 2021-12-26 22:33:25.568519

	// items added 2022-05-16 12:52:11.206852
	'upgrade.free.label5': `AD supportato`,
};