import { ComponentType } from "react";

export function retry(fn: () => Promise<any>, retriesLeft = 5, interval = 1000) {
    return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error: Error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // try reloading - the user is likely loading an old version of the app
                        window.location.reload();
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
