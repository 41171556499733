import { OFFERS } from "../../../api/settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";
import { LanguageStrings } from "../lang-en";

export const esText: Partial<LanguageStrings> = {
    

	// items added 2021-11-29 13:51:51.343323
	'nav.navigation-dropdown': `Navegación `,
	'error.contact-us-message': `Lo siento, algo salió mal! ¿Te importaría que lo supieras? Nos gustaría arreglarlo lo antes posible. Por favor, póngase en contacto con nosotros directamente en `,
	'home.title.title': `Menús dinámico de código QR `,
	'home.title.subtitle': `Mantenga a sus clientes seguros con menús digitales que rotan automáticamente. `,
	'home.title.make-code-button': `Hacer un código `,
	'home.preview.title': `Sencillo. Rápido. A salvo. `,
	'home.preview.subtitle1': `Comer códigos seguros dura unos dos minutos para configurar. `,
	'home.preview.subtitle2': `En serio, eso es todo. `,
	'home.preview.subtitle3': `Con nuestra plataforma, puede hacer códigos QR que permitan a su restaurante `,
	'home.preview.bullet1.2': `Múltiples menús `,
	'home.preview.bullet1.3': `Del mismo código QR `,
	'home.preview.bullet2.1': `Menús de horario `,
	'home.preview.bullet2.2': `Aparecer en ciertos días o tiempos. `,
	'home.preview.bullet3.1': `Personalizar y diseñar `,
	'home.preview.bullet3.2': `Sus códigos QR y las páginas de destino del menú para que coincidan con su marca `,
	'home.preview.banner1.1': `Diseño `,
	'home.preview.banner1.2': `Añade tu marca `,
	'home.preview.banner2.1': `Menús programados `,
	'home.preview.banner2.2': `Gire automáticamente `,
	'home.preview.banner3.1': `Subir `,
	'home.preview.banner3.2': `Anfitriona tu menú `,
	'home.desc.title': `¿Como funciona? `,
	'home.desc.desc': `Coma códigos seguros Le permite cargar múltiples menús digitales (PDF, JPEG, PNG, etc.) y hacerlos accesibles a los clientes a través de códigos QR escaneados en la tabla. Esto significa que los clientes reducen la propagación potencial de patógenos de los menús físicos tocados. Puede establecer horarios para que los menús giren automáticamente para que pueda mantenerse enfocado en lo que más importa `,
	'home.desc.desc-bold': `Ejecutando su restaurante. `,
	'home.testimonial.text': `Hemos podido reducir el menú de papel desperdician una tonelada, ya que son todos usados. La impresión del menú solía ser más de 150 menús al día. Ahora estamos bajando a tal vez 100 cada dos semanas. `,
	'home.testimonial.name': `- Drew, Escuela Pública 818, Los Ángeles, Estados Unidos `,
	'home.photos.title': `¿Necesitar más? Ir Pro. `,
	'home.photos.body1': `Desbloquee la potencia completa de comer códigos seguros con el `,
	'home.photos.body2-bold': `Plan profesional. `,
	'home.photos.body3': `Descubra Analytics de escaneo, personalice el diseño de sus códigos QR, y diseñe las páginas de destino que los clientes ven. Ser parte de la solución y ayudar a detener la propagación de `,
	'home.photos.body4': `COVID-19. `,
	'home.photos.button': `Echa un vistazo a nuestros planes `,
	'home.benefits.title': `El mundo ha cambiado. `,
	'home.benefits.subtitle': `Consigue tus menús con los tiempos. `,
	'home.benefits.block1.title': `Proteger a los clientes y personal `,
	'home.benefits.block1.body1': `Protege a los clientes que esperan una experiencia gastronómica más segura. Proteger al personal que `,
	'home.benefits.block1.body2-bold': `arriesgar su salud `,
	'home.benefits.block1.body3': `Todos los días para interactuar con los comensales. `,
	'home.benefits.block2.title': `Ahorre árboles, ahorre dinero y ahorre horas `,
	'home.benefits.block2.body1': `Ahorre en la impresión de nuevos menús. Imprime y diseñe sus códigos QR `,
	'home.benefits.block2.body2-bold': `una vez `,
	'home.benefits.block2.body3': `Luego actualice los menús desde su teléfono o computadora en cualquier momento. `,
	'home.benefits.block3.title': `Girar automáticamente los menús `,
	'home.benefits.block3.body1': `Mostrar desayuno, almuerzo y menú para la cena en horarios semanales flexibles. ¿Tienes un menú de hora feliz para el martes 5-7pm y viernes 5-8pm? `,
	'home.benefits.block3.body2-bold': `No hay problema. `,
	'home.benefits.block4.title': `Páginas de aterrizaje simples y elegantes para menús múltiples. `,
	'home.benefits.block4.body1': `Presentar el menú de la cena con una carta de vinos. Aperitivos diarios con los menús semanales. Nuestros menús se adaptan con tu restaurante. `,
	'home.benefits.block4.body2-bold': `Todo de un código `,
	'home.benefits.block4.body3': `. `,
	'home.support.title': `¡Gracias por apoyarnos! `,
	'home.support.subtitle': `Ayúdanos a mantener al mundo seguro. `,
	'faq.title.largetext': `Preguntas más frecuentes `,
	'faq.title.title': `Preguntas frecuentes `,
	'faq.video.title': `Tutorial rápido `,
	'faq.button.to-dashboard': `Volver al tablero `,
	'faq.questions.q1': `¿Qué es un código QR? `,
	'faq.questions.a1': `Un código de referencia rápida (QR) consiste en cuadrados negros dispuestos en una cuadrícula cuadrada sobre un fondo blanco, que puede ser leído por un dispositivo de imágenes como la cámara en su teléfono móvil. Nuestros servicios permiten a sus clientes enlazar rápidamente el menú de su restaurante a los códigos generados de forma única para su negocio. Esto crea un método seguro y sin contacto para que sus clientes accedan a sus menús. `,
	'faq.questions.q2': `¿Cómo usarán mis clientes mi código QR? `,
	'faq.questions.a2': `La mayoría de los teléfonos inteligentes modernos tienen lectores QR incorporados. Para la mayoría de los dispositivos, los usuarios solo necesitarán abrir su cámara y centrar el código (no se requiere foto). Una ventana emergente parecerá mágicamente para pastorear a sus clientes a su menú o sitio web. `,
	'faq.questions.q3': `¿Puedo mostrar múltiples menús del mismo código QR a la vez? `,
	'faq.questions.a3': `¡Sí! Si aparecen varios menús para aparecer, proporcionará a su cliente la opción de seleccionar y navegar entre ellos. Si solo se configura uno para aparecer, el usuario se enviará directamente a ese menú. `,
	'faq.questions.q4': `¿Puedo programar ciertos menús a aparecer en ciertos momentos? `,
	'faq.questions.a4': `¡Sí! La plataforma le permite configurar los menús para mostrar en un horario de complejidad arbitraria. Esto significa, por ejemplo, que puede configurar el brunch de fin de semana, el desayuno laboral, las ofertas de bebidas de la hora feliz y / o las especiales de cena diaria para aparecer en sus épocas apropiadas, ¡todo desde el mismo código QR! También tiene la capacidad de alternar el / desactivar los menús del panel de control móvil o de escritorio del gerente para los cambios en el menú de mosca. `,
	'faq.questions.q5': `¿El tablero de administración funciona en el móvil? `,
	'faq.questions.a5': `¡Sí! Puede realizar ediciones para horarios, activar / desactivar los menús, agregar y eliminar menús desde su dispositivo móvil. `,
	'faq.questions.q6': `¿Qué pasa si mi cliente no tiene un smartphone QR Reader? `,
	'faq.questions.a6': `La mayoría de los dispositivos modernos tienen lectores de códigos QR incorporados. Dicho esto, todos Android y iPhone tienen aplicaciones QR Reader que se pueden descargar de forma gratuita. Y como una copia de seguridad, siempre es útil tener un menú de repuesto disponible por si acaso. `,
	'faq.questions.q8': `¿Qué pasa si mi menú cambia? `,
	'faq.questions.a8': `Usted puede agregar, editar, actualizar y eliminar menús en cualquier momento con solo unos pocos clics. La plataforma acepta la mayoría de los formatos de fotos, así como fuentes dinámicas, como la URL de su sitio web. `,
	'faq.questions.q10': `¿Puedo tener varios códigos QR diferentes establecidos en diferentes restaurantes o horarios de menú? `,
	'faq.questions.a10': `¡Claro puede! Actualmente no hay límite para el número de códigos QR que un usuario puede generar. `,
	'faq.questions.q11': `¿Qué pasa si borro un código? ¿Puedo recuperarlo? `,
	'faq.questions.a11': `Los códigos QR se pueden recuperar siempre que se use el mismo enlace de tocador cuando se recrean. `,
	'faq.questions.q12': `¿Puedo cambiar el orden en qué menús se muestran a los clientes? `,
	'faq.questions.a12': `¡Definitivamente! Simplemente arrastre y suelte los menús en el tablero de instrumentos para reorganizar su pedido. `,
	'faq.questions.pro.q1': `¿Cómo actualizo? `,
	'faq.questions.pro.a1': `Puede actualizar su cuenta visitando la página de actualización. Tenga en cuenta que tendrá la oportunidad de revisar todos los cargos antes de finalizar los pagos. `,
	'faq.questions.pro.q2': `¿Puedo cancelar mi suscripción a Pro? `,
	'faq.questions.pro.a2': `Sí, en cualquier momento visitando la sección de facturación de su perfil de usuario. Su cancelación entrará en vigencia de inmediato y sus códigos QR ya no funcionarán. `,
	'faq.questions.pro.q3': `¿Cuanto cuesta? `,
	'faq.questions.pro.a3': `¡Es gratis! Mientras permanezca dentro de nuestros generosos límites de uso, nuestros servicios son totalmente gratuitos. Incluyemos anuncios pequeños y discretos para este nivel para que podamos ofrecerle este servicio sin costo alguno. Si desea eliminar estos anuncios y desbloquear un montón de funciones útiles, considere actualizar a una cuenta Pro. `,
	'faq.questions.pro.q4': `¿Qué está incluido en la versión de inicio? `,
	'faq.questions.pro.a4': `¡Toda la funcionalidad básica para mostrar su menú se incluye en la versión de inicio! Para ver las limitaciones de esta opción, por favor vea los límites de los niveles. `,
	'faq.questions.pro.q5': `¿Qué puedo personalizar? `,
	'faq.questions.pro.a5': `¡Nuestra opción PRO le permite personalizarlo casi todo! Puede diseñar su propio código, editar la apariencia y agregar logotipos personalizados. También puede editar la página de destino, sus clientes verán para que esté personalizado para su negocio. `,
	'faq.questions.pro.q6': `¿Cuáles son los límites? `,
	'faq.questions.pro.a6': `Consulte la página de actualización para ver los límites de nivel para escanear, cargas de menú y más. `,
	'faq.questions.pro.q7': `¿Qué pasa si excdeo el límite? `,
	'faq.questions.pro.a7': `No se cerrará de su cuenta si excede un límite. Sin embargo, la primera vez que excede el conteo de escaneo permitido, se le dará dos semanas antes de que los menús estén pausados. Para continuar, debe actualizar al nivel apropiado. `,
	'faq.questions.pro.q8': `¿Hay un límite a cuántos códigos puedo crear? `,
	'faq.questions.pro.a8': `Si. Por favor, haga referencia a los niveles en la página de actualización. Es importante tener en cuenta que los códigos archivados cuentan a este límite. `,
	'faq.questions.pro.q9': `¿Hay un límite a cuántos menús puedo crear? `,
	'faq.questions.pro.a9': `Si. Por favor, encuentre el límite de menú en la tabla del plan. Puede eliminar menús para que se quede debajo de su límite de nivel. `,
	'faq.questions.pro.q10': `¿Hay un límite a la cantidad de cargas de menú que puedo hacer por código? `,
	'faq.questions.pro.a10': `Si. Encuentre el límite de carga del archivo en la tabla del plan. Incluso si los menús se borran más tarde, todavía contarán para su total general. `,
	'faq.questions.pro.q11': `¿Cómo funcionan los pagos? `,
	'faq.questions.pro.a11': `Para los cargos anuales, se le cobrará una vez cada 365 días a partir de los datos de la primera suscripción. Para cargos mensuales, se le cobrará cada 30 días a partir de los datos de la primera suscripción. Su tarjeta de crédito se cobrará automáticamente a menos que cancele su suscripción. No podemos proporcionar reembolsos parciales por períodos no utilizados. Todas las transacciones serán consideradas definitivas. `,
	'faq.questions.pro.q12': `¿En qué moneda puedo pagar? `,
	'faq.questions.pro.a12': `Nuestros socios de pago apoyan múltiples monedas, pero todos los precios se enumeran en dólares de los Estados Unidos. `,
	'signup.title.title': `Inscribirse `,
	'signup.title.subtitle': `No se preocupe, su cuenta no caduca, y no queremos su tarjeta de crédito. `,
	'signup.title.welcome': `Bienvenido al club. `,
	'signup.form.email': `Correo electrónico `,
	'signup.form.email.placeholder': `example@email.com `,
	'signup.form.password': `Contraseña `,
	'signup.form.password.placeholder': `Contraseña `,
	'signup.form.first': `Primer nombre `,
	'signup.form.last': `Apellido `,
	'signup.form.company': `Empresa `,
	'signup.form.company.placeholder': `El mejor restaurante de la ciudad. `,
	'signup.form.submit': `Inscribirse `,
	'signup.redirect.text': `¿Ya eres usuario? Registrarse `,
	'signup.redirect.link': `aquí `,
	'signin.title.title': `Hola viejo amigo `,
	'signin.title.subtitle': `Inicia sesión, ¿te haría? `,
	'signin.form.email': `Correo electrónico `,
	'signin.form.email.placeholder': `example@email.com `,
	'signin.form.password': `Contraseña `,
	'signin.form.password.placeholder': `Contraseña `,
	'signin.form.submit': `Iniciar sesión `,
	'signin.form.reset-pwd.button': `Restablecer mi contraseña `,
	'signin-form.reset-pwd.confirmation': `¡Gracias! Si existe una cuenta para este correo electrónico, le hemos enviado un enlace para restablecer su contraseña. `,
	'signin-form.reset-pwd.link': `¿Olvidaste tu contraseña? `,
	'signin-form.reset-pwd.link.finish': `Vuelve al inicio de sesión. `,
	'signin.redirect.text': `¿No es un miembro existente? Inscribirse `,
	'signin.redirect.link': `aquí `,
	'dashboard.title.text1': `Buen trabajo `,
	'dashboard.title.text2': `Estos códigos están manteniendo el mundo saludable. `,
	'dashboard.button.text': `Crea un nuevo código QR `,
	'dashboard.button.upgrade': `Actualizar a pro `,
	'dashboard.get-started.get-started': `¡Empezar! - `,
	'dashboard.get-started.get-started2': `Comience por crear su primer código QR y el archivo en el que debe redirigirlo. `,
	'dashboard.get-started.add-menus': `¡Este código no vincula a ningún menús todavía! - `,
	'dashboard.get-started.add-menus2': `Haga clic aquí para agregar su primer menú. `,
	'dashboard.alerts.cant-verify': `No pudimos verificar las credenciales de su usuario. Por favor, cierre sesión y vuelva a iniciar sesión. `,
	'dashboard.table-headers.Order': `Pedido `,
	'dashboard.table-headers.Name': `Nombre `,
	'dashboard.table-headers.Name.tooltip': `Este es el nombre del menú que los usuarios verán `,
	'dashboard.table-headers.Active.tooltip': `Establezca esto para desactivar este menú `,
	'dashboard.table-headers.Schedule': `Calendario `,
	'dashboard.table-headers.Version': `Versión `,
	'dashboard.table-headers.Actions': `Comportamiento `,
	'dashboard.pils.Always': `Siempre `,
	'dashboard.pils.Everyday': `Todos los días `,
	'dashboard.pils.Never': `Nunca `,
	'dashboard.pils.Weekdays': `Días laborables `,
	'dashboard.pils.Weekends': `Fines de semana `,
	'dashboard.tooltips.header': `Administrar `,
	'dashboard.tooltips.pro-section.if-pro': `Pro `,
	'dashboard.tooltips.pro-section.if-basic': `Actualización de características de Pro `,
	'dashboard.tooltips.test-code': `Haga clic para probar su código QR (según la hora actual). `,
	'dashboard.tooltips.view-code': `Ver o código de impresión `,
	'dashboard.tooltips.add-code': `Añadir un nuevo menú para su código `,
	'dashboard.tooltips.archive-code': `Archiva este código `,
	'dashboard.tooltips.archive-code.text1': `¿Estás seguro de que quieres archivar el código? `,
	'dashboard.tooltips.archive-code.text2': `? Este código y sus menús se pueden recuperar más tarde, siempre y cuando ningún código activo tenga la misma URL (que es `,
	'dashboard.tooltips.design-code': `Diseña tu código QR `,
	'dashboard.tooltips.design-landing-page': `Página de destino del código QR de diseño `,
	'dashboard.tooltips.view-analytics': `Ver análisis de uso `,
	'dashboard.tooltips.upgrade-to-access': `Actualizar para acceder a esta función `,
	'dashboard.qr-modal.title': `Crea un nuevo código QR dinámico `,
	'print.button.done': `Hecho `,
	'print.button.print-preview': `Vista previa de impresión `,
	'print.toggle.title': `Imprimir solo Código QR `,
	'print.toggle.description': `¡Le proporcionamos una plantilla agradable de 4 'x 6' para imprimir el código para que pueda comenzar fácilmente! Si prefiere imprimir solo el código y diseñarlo usted mismo, habilite esto. `,
	'print.template.header': `Estamos poniendo su salud primero. `,
	'print.template.subheader': `Escanear para un menú sin toque. `,
	'print.template.instruction1': `Abra la cámara de su teléfono y centre el código en la pantalla. `,
	'print.template.instruction2': `Toque la ventana emergente `,
	'add-target.title': `Crea un nuevo menú para tu código QR `,
	'add-target.name': `Nombre del menú (¡los clientes verán esto!) `,
	'add-target.name.placeholder': `Menú brunch `,
	'add-target.name.desc': `¡Este es el título orientado al público que los clientes verán! `,
	'add-target.description': `Descripción (opcional) `,
	'add-target.description.placeholder': `Menús de comida de la noche, versión PDF ... `,
	'add-target.description.desc': `¡Esta es la descripción orientada al público que los clientes verán! Recomendamos encarecidamente proporcionar uno. `,
	'add-target.schedule': `Horario de menú `,
	'add-target.schedule.dropdown.always': `Siempre usa `,
	'add-target.schedule.dropdown.always.desc': `El menú siempre aparecerá `,
	'add-target.schedule.dropdown.weekly': `Basado en un horario semanal `,
	'add-target.schedule.dropdown.weekly.desc': `El código redirió a esto si alguien escanea el código QR dentro del horario semanal que se describe a continuación. `,
	'add-target.schedule.dropdown.weekly.toggle': `Alternar todo `,
	'add-target.schedule.dropdown.weekly.sun': `domingo `,
	'add-target.schedule.dropdown.weekly.mon': `lunes `,
	'add-target.schedule.dropdown.weekly.tues': `martes `,
	'add-target.schedule.dropdown.weekly.wed': `miércoles `,
	'add-target.schedule.dropdown.weekly.thurs': `jueves `,
	'add-target.schedule.dropdown.weekly.fri': `viernes `,
	'add-target.schedule.dropdown.weekly.sat': `sábado `,
	'add-target.link-type.title': `Tipo de vínculo `,
	'add-target.link-type.uploaded': `Un menú cargado `,
	'add-target.link-type.uploaded.placeholder': `Sube el archivo El código QR debe redirigirse a. `,
	'add-target.link-type.uploaded.link': `Arrastre y 'Drop el archivo aquí, o haga clic para seleccionar uno `,
	'add-target.link-type.uploaded.onDrag': `Soltar el archivo aquí `,
	'add-target.link-type.uploaded.desc1': `No te preocupes por conseguir esto perfecto ahora. Puede cambiar el menú de este código QR dinámico en cualquier momento. `,
	'add-target.link-type.uploaded.desc2': `Tipos de archivos compatibles `,
	'add-target.link-type.url': `Una URL de sitio web existente `,
	'add-target.link-type.url.desc': `URL externa para redirigir a `,
	'add-target.link-type.url.website-name': `Nombre del Sitio Web `,
	'add-target.link-type.url.website-name.placeholder': `Enlace del menú del restaurante `,
	'add-target.link-type.url.website-name.desc': `Los clientes no verán esto. Es solo para una referencia más fácil que una URL. `,
	'add-target.submit': `Crear `,
	'dashboard.archive.title': `Archiva este código `,
	'dashboard.archive.text1': `¿Estás seguro de que quieres archivar el código? `,
	'dashboard.archive.text2': `? Este código y sus menús se pueden recuperar más tarde, siempre y cuando ningún código activo tenga la misma URL (que es `,
	'dashboard.archive.cancel-button': `Cancelar `,
	'dashboard.archive.archive-button': `Archivo `,
	'dashboard.archived-codes.title': `Códigos archivados `,
	'dashboard.archived-codes.name': `NOMBRE CLAVE `,
	'dashboard.archived-codes.url': `URL de código `,
	'dashboard.archived-codes.safe-recover': `Seguro para recuperar? `,
	'dashboard.archived-codes.recover': `RECUPERAR `,
	'profile.title': `Perfil del usuario `,
	'profile.section.email': `Correo electrónico `,
	'profile.section.email.desc': `Requerido para las actualizaciones de correo electrónico o contraseña. `,
	'profile.section.current-password': `Contraseña actual `,
	'profile.section.current-password.placeholder': `Contraseña actual `,
	'profile.section.current-password.desc': `Requerido para las actualizaciones de correo electrónico o contraseña. `,
	'profile.section.new-password': `Nueva contraseña `,
	'profile.section.new-password.placeholder': `Nueva contraseña `,
	'profile.section.new-password.desc': `Requerido para las actualizaciones de correo electrónico o contraseña. Debe ser 6 caracteres de más. `,
	'profile.section.confirm-new-password': `Confirmar nueva contraseña `,
	'profile.section.confirm-new-password.placeholder': `Confirmar nueva contraseña `,
	'profile.section.confirm-new-password.desc': `Requerido para las actualizaciones de correo electrónico o contraseña. `,
	'profile.section.first-name': `Primer nombre `,
	'profile.section.last-name': `Apellido `,
	'profile.submit': `Actualizar `,
	'profile.subtext': `Volver a la `,
	'profile.subtext-link': `tablero `,
	'profile.billing.section-title': `Facturación `,
	'profile.billing.redirect-button': `Visita el portal de facturación `,
	'contact.title': `Contáctenos `,
	'contact.name': `Tu nombre `,
	'contact.email': `Dirección de correo electrónico `,
	'contact.email.placeholder': `you@email.com `,
	'contact.phone': `Teléfono `,
	'contact.company': `Nombre de la empresa o del restaurante `,
	'contact.company.placeholder': `Opcional `,
	'contact.subject': `Tema `,
	'contact.subject.placeholder': `¡Hola! `,
	'contact.message': `Mensaje `,
	'contact.message.placeholder': `Dinos como podemos ayudar `,
	'contact.send': `Enviar `,
	'contact.send.tooltip': `No puedo esperar por tu mensaje `,
	'loading.20': `Cargando... `,
	'loading.40': `Cargando tus códigos ... `,
	'loading.80': `Carga de detalles de código ... `,
	'loading.100': `¡Hecho! `,
	'dashboard.prompt.get-started': `¿Cómo empiezo? `,
	'dashboard.sign-out': `desconectar `,
	'verify-email.title': `Por favor revise su correo electrónico para un enlace de verificación `,
	'verify-email.subtext1': `Si no puede verlo, por favor revise su carpeta SPAM. Si necesita actualizar su correo electrónico, puede hacerlo. `,
	'verify-email.subtext2': `aquí `,
	'verify-email.subtext3': `Si cree que su correo electrónico es correcto y aún no ha recibido la confirmación, puede reenviarlo haciendo clic en `,
	'verify-email.error': `¡Perdón, algo salió mal! Por favor contáctenos para que podamos arreglar esto. `,
	'verify-email.resend': `¡Resentido con éxito! `,
	'landing-page.default-subtitle': `Visita cualquier enlace a continuación. Simplemente haga clic en su navegador para volver aquí. `,
	'landing-page.pro.edit-button': `Editar `,
	'checkout.cancel.title': `Actualización cancelada `,
	'checkout.cancel.subtitle': `No se ha tomado ninguna acción. `,
	'checkout.cancel.button': `Regresa `,
	'checkout.success.title': `¡Bienvenido a Pro! `,
	'checkout.success.subtitle1': `¡Has mejorado con éxito, felicidades! `,
	'checkout.success.subtitle2': `Este es un compromiso con la experiencia gastronómica más segura. `,
	'checkout.success.subtitle3': `Gracias por confiarnos con sus menús digitales. `,
	'checkout.success.button': `Ver su Tablero de PRO `,
	'upgrade.title.1': `Encuentra el `,
	'upgrade.title.2': `plan perfecto `,
	'upgrade.title.3': `para tu negocio `,
	'upgrade.free.name': `Gratis `,
	'upgrade.free.priceNote': `No se requiere tarjeta de crédito `,
	'upgrade.free.label1': `Crear ${MAX_CODES_PER_TIER["basic"]} menús dinámico de código QR `,
	'upgrade.free.label2': `${MAX_SCANS_PER_TIER["basic"]} exploraciones mensuales `,
	'upgrade.free.tooltip2': `Medido basado en las exploraciones sobre todos los códigos QR en el mes actual `,
	'upgrade.free.label3': `Hasta ${MAX_TARGETS_PER_TIER["basic"]} menús del mismo código `,
	'upgrade.free.tooltip3': `Establezca varios menús para mostrar desde el mismo código QR. `,
	'upgrade.free.label4': `Rotación automática del menú `,
	'upgrade.free.tooltip4': `Establecer un horario semanal. ¿Menú de brunch solo los domingos por la mañana? No hay problema. `,
	'upgrade.starter.name': `Inicio `,
	'upgrade.starter.priceNote': `Al seleccionar un plan anual. Un plan mensual está disponible para `,
	'upgrade.starter.label1': `Crear ${MAX_CODES_PER_TIER["starter"]} menús dinámico de código QR `,
	'upgrade.starter.label2': `${MAX_SCANS_PER_TIER["starter"]} exploraciones mensuales `,
	'upgrade.starter.tooltip2': `Medido basado en las exploraciones sobre todos los códigos QR en el mes actual `,
	'upgrade.starter.label3': `Hasta ${MAX_TARGETS_PER_TIER["starter"]} menús del mismo código `,
	'upgrade.starter.tooltip3': `Establezca varios menús para mostrar desde el mismo código QR. `,
	'upgrade.starter.label4': `Rotación automática del menú `,
	'upgrade.starter.tooltip4': `Establecer un horario semanal. ¿Menú de brunch solo los domingos por la mañana? No hay problema. `,
	'upgrade.pro.name': `Pro `,
	'upgrade.pro.priceNote': `Al seleccionar un plan anual. Un plan mensual está disponible para `,
	'upgrade.pro.label1': `Todas las características gratuitas `,
	'upgrade.pro.tooltip1': `¡Consigue todas las características gratuitas sin los anuncios! `,
	'upgrade.pro.label2': `Crear ${MAX_CODES_PER_TIER["pro"]} menús dinámico de código QR `,
	'upgrade.pro.label3': `${MAX_SCANS_PER_TIER["pro"]} exploraciones mensuales `,
	'upgrade.pro.tooltip3': `Medido basado en las exploraciones sobre todos los códigos QR en el mes actual `,
	'upgrade.pro.label4': `Siempre libre de anuncios `,
	'upgrade.pro.tooltip4': `Tus clientes nunca verán anuncios. ¡Gracias por apoyarnos! `,
	'upgrade.pro.label5': `Códigos QR de marca `,
	'upgrade.pro.tooltip5': `¡Personalice los colores de cada QRCode, mejore la calidad del reconocimiento de escaneo y agregue un logotipo! `,
	'upgrade.pro.label6': `Páginas de destino del menú de marca `,
	'upgrade.pro.tooltip6': `Personalice el diseño de la página de destino de cada código para que coincida con la marca de su restaurante. `,
	'upgrade.pro.label7': `Analítica de escaneo `,
	'upgrade.pro.tooltip7': `Rastrear cuántos clientes están escaneando su código y qué menús seleccionan `,
	'upgrade.pro.label8': `Apoyo dedicado `,
	'upgrade.pro.tooltip8': `Llegue a la compatibilidad más rápida con una plataforma de contacto solo para usuarios de PRO `,
	'upgrade.enterprise.name': `Empresa `,
	'upgrade.enterprise.label1': `Menús de código QR dinámico ilimitado `,
	'upgrade.enterprise.label2': `Todos los beneficios de pro `,
	'upgrade.enterprise.tooltip2': `Todo profesional sin limitaciones. `,
	'upgrade.enterprise.label3': `Desarrollo personalizado y características `,
	'upgrade.enterprise.tooltip3': `Aproveche al máximo sus códigos QR con el desarrollo de características personalizadas `,
	'upgrade.enterprise.label4': `Soporte VIP `,
	'upgrade.enterprise.tooltip4': `Acceso directo al equipo EAT SAFE CODES `,
	'upgrade.misc.perMonth': `por mes `,
	'upgrade.misc.enterprise': `Llegar a determinar la mejor oferta para su negocio. `,
	'upgrade.misc.after-trial': `Después de la prueba gratuita, cancelar o cambiar el plan en cualquier momento `,
	'upgrade.misc.current-plan': `Plan actual `,
	'upgrade.buttons.free.purchase': `Seleccione el plan gratuito `,
	'upgrade.buttons.free.signup': `¡Registrate gratis! `,
	'upgrade.buttons.starter.purchase': `Empiece con Starter `,
	'upgrade.buttons.starter.annual': `Comprar plan anual `,
	'upgrade.buttons.starter.monthly': `Comprar plan mensual `,
	'upgrade.buttons.pro.purchase': `Empezar con pro `,
	'upgrade.buttons.pro.annual': `Comprar plan anual `,
	'upgrade.buttons.pro.monthly': `Comprar plan mensual `,
	'upgrade.buttons.signup-offer.monthly': `Empiza la prueba gratuita `,
	'upgrade.buttons.enterprise': `Contáctenos `,
	'signup-offer.title': `Antes de llevarlo a la versión gratuita, ¡pruebe nuestra opción más popular gratis para ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} días! `,
	'signup-offer.desc.p1': `¿No estás seguro si Pro es adecuado para usted? Entendemos. Como nuevo miembro, disfrute de un mes gratuito de características PRO y límites de escaneo mucho más altos. Aproveche al máximo su código QR hoy, incluidas las páginas de destino de marca, Analytics de escaneo y más. `,
	'signup-offer.desc.p2-a': `Tenga en cuenta que si no inicia una versión de prueba gratuita y continúa con la versión gratuita, todavía obtendrá `,
	'signup-offer.desc.p2-b': `Días de experiencias sin publicidad. `,
	'signup-offer.btn.decline': `No, gracias, llévame a la versión gratuita. `,
	'signup-offer.starter.title': `¡Prueba nuestro plan más popular gratuito para ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} días! `,
	'signup-offer.starter.desc.p1': `¿No estás seguro si Pro es adecuado para usted? Entendemos. Como nuevo miembro, disfrute de un mes gratuito de características PRO y límites de escaneo mucho más altos. Aproveche al máximo su código QR hoy, incluidas las páginas de destino de marca, Analytics de escaneo y más. `,
	'signup-offer.starter.desc.p2-a': `Al probar el plan de inicio, te estarás perdiendo `,
	'signup-offer.starter.desc.p2-b': `Días de experiencias sin publicidad. `,
	'signup-offer.starter.btn.decline': `Me gustaría probar el plan de inicio. `,
	'pro-announcement.header.1': `Anuncio `,
	'pro-announcement.btn1': `Aprender sobre pro `,
	'pro-announcement.p1': `Grandes noticias, estamos creciendo! Queremos agradecernos sinceramente por ayudarlo a ayudarnos al proveedor de menú digital # 1 y hacer que los restaurantes sea un espacio más seguro para cenar en estos momentos difíciles. ¡Estamos emocionados de anunciar que hoy estamos liberando nuestro nuevo Pro Tier con una tonelada de nuevas características! `,
	'pro-announcement.p2': `El Pro Tier respaldará nuestros costos operativos y nos permitirá servir mejor a nuestros usuarios dedicados. Esperamos que note varias funciones altamente solicitadas cuando se actualiza, como el diseño personalizable de los códigos QR y las páginas de destino del menú. Espere ver más características dirigidas a los miembros de Pro. `,
	'pro-announcement.p3': `Por el costo de un Entreé por mes, puede actualizar a Pro hoy. Una suscripción anual también está disponible en un 20% de descuento. Comer de manera segura no es una tendencia: sus clientes esperarán un menú más seguro durante mucho tiempo, y juntos estaremos allí para proporcionar la experiencia de alta calidad. `,
	'pro-announcement.p4': `Cada nivel también tendrá límites en los códigos QR total y las exploraciones mensuales. Revise estos límites para que pueda decidir si Pro es adecuado para usted. El 4 de octubre, los límites de uso para cada nivel se habilitarán. No se preocupe, si ya excede el límite, sus códigos no se eliminarán y su código QR continuará funcionando. `,
	'pro-announcement.p5': `Además, el 4 de octubre, estaremos introduciendo anuncios discretos en las páginas de destinatarios del menú en el nivel gratuito. `,
	'pro-announcement.p6': `Si desea eliminar estos anuncios de la vista de su cliente, desbloquee nuevas funciones valiosas e invierta en la plataforma EAT SAFE, considere la actualización. Para obtener detalles completos sobre precios, características y límites, visite nuestra página de precios en `,
	'pro-announcement.p7': `Estamos muy agradecidos por su inversión en nosotros y más allá de la emoción de este nuevo capítulo de comer códigos seguros. Envíenos comentarios o solo diga hola en cualquier momento en `,
	'banner.free-trial.text': `¡Consigue un mes gratis! `,
	'checkout.canceled': `Pago cancelado `,
	'checkout.no-action': `No se ha tomado ninguna acción. `,
	'pro-features.1.title': `Códigos QR de marca `,
	'pro-features.1.description': `Los códigos QR no tienen que mirar lo mismo. Haz que el tuyo se destaque e impresione a sus clientes. Agregue colores, mejore la corrección de errores e incluso agregue el logotipo de su empresa al código. `,
	'pro-features.2.title': `Páginas de destino del menú de marca `,
	'pro-features.2.description': `Cuando se muestran varios menús, proporcione a los clientes una página de destino de marca que coincida con sus temas e imágenes. `,
	'pro-features.3.title': `Analítica de código `,
	'pro-features.3.description': `Monitorea con qué frecuencia se escanean sus códigos con las métricas de escaneo de horas a hora. `,
	'pro-features.4.title': `Límites de uso profesional `,
	'pro-features.4.description': `Deja de preocuparse por golpear tu límite. Escanee hasta 100,000 veces cada año. ¿Necesitar más? Vamos a hablar y descubrirlo hoy. `,
	'pro.success.welcome': `Bienvenido a `,
	'pro.success.congrats': `Usted ha actualizado con éxito - ¡Felicidades! `,
	'pro.success.body': `La inscripción para Pro es un compromiso con una experiencia gastronómica más segura, y queremos apoyarlo en ese compromiso en cada paso del camino. Como miembro profesional, estamos enfocados en usted. Los miembros de Pro merecen una experiencia asequible y constante mejorando, y esperamos que comience a notar que hoy con varias características que acaba de desbloquear. `,
	'pro.success.button': `Ver su Tablero de PRO `,
	'pro.limit.header': `Has llegado a tu límite. `,
	'pro.limit.upgrade': `Tendrá que actualizar para hacer esto. `,
	'pro.limit.button': `Actualizar ahora `,
	'menu-landing.message': `No parece que no hay un menú disponible en este momento. `,
	'menu-landing.scans1': `Has superado su límite de uso de `,
	'menu-landing.scans2': `Escaneo en los últimos 30 días. Tu Actualmente tienes `,
	'menu-landing.scans3': `Escaneo en los últimos 30 días. ¡Póngase en contacto con nosotros si necesita un límite de nivel superior! `,
	'menu-landing.scans4': `Escaneo en los últimos 30 días. Actualice a Pro para un límite de uso más alto! `,
	'menu-landing.exceeeded': `Ha superado el límite de escaneo para su nivel. `,
	'edit-code.EC_DESCRIPTION': `Habilite un código QR de mayor fidelidad para mejorar la corrección de errores en caso de daños al código. `,
	'edit-code.label.foreground': `Color de primer plano `,
	'edit-code.label.background': `Color de fondo `,
	'edit-code.label.errorMessage': `Error al crear URL de objeto desde el logotipo cargado `,
	'edit-code.label.customize': `Personaliza tu estilo de código. `,
	'edit-code.quality.M': `Estándar `,
	'edit-code.quality.H': `Mejor `,
	'edit-code.squares': `Cuadrícula `,
	'edit-code.dots': `Puntos `,
	'edit-code.padding': `Relleno `,
	'edit-code.logo.title': `¡Añada su logotipo de negocio a su código! `,
	'edit-code.logo.warning': `El archivo debe ser una imagen (.png, .jpg, .jpeg) y tener un tamaño máximo de 2 MB `,
	'edit-code.logo.sizeTitle': `Tamaño del logotipo `,
	'edit-code.logo.opacityTitle': `Logo opacidad `,
	'edit-code.saveError': `Algo salió mal ahorrar el diseño de código personalizado. `,
	'edit-code.button.save': `Ahorrar `,
	'edit-code.button.back': `Regresa `,
	'landing-editor.label.background': `Fondo `,
	'landing-editor.label.primary': `Texto primario `,
	'landing-editor.label.menu': `Menú `,
	'landing-editor.label.menuHover': `Menú (en Hover / Click) `,
	'landing-editor.label.menuTitle': `Título del menú `,
	'landing-editor.label.menuDesc': `Descripción del menú `,
	'landing-editor.label.header': `Encabezamiento `,
	'landing-editor.label.desc': `Descripción `,
	'landing-editor.placeholder.desc': `Visita cualquier enlace a continuación. Simplemente haga clic en su navegador para volver aquí. `,
	'landing-editor.label.font': `Fuente `,
	'landing-editor.label.logo': `Logo `,
	'landing-editor.formTextBlocks.logo': `¡Sube un logotipo de 1MB o menos! `,
	'landing-editor.button.save': `Ahorrar `,
	'landing-editor.button.done': `Hecho `,
	'pro-landing.back': `Volver al tablero `,
	'pro-landing.edit': `Editar `,
	'upgrade.btn.why-pro': `¿Por qué elegir PRO? `,
	'upgrade.desc.discover': `Descubrir `,
	'upgrade.desc.desc': `Con nuestro nuevo `,
	'upgrade.desc.desc2': `Puede personalizar la experiencia de su cliente para que coincida con su negocio. Además de los límites incrementados mencionados anteriormente, puede desbloquear las siguientes características `,
	'upgrade.desc.bullet1': `Códigos QR personalizados `,
	'upgrade.desc.bullet1.desc': `¡Añada su logotipo, personalice el look, y más! `,
	'upgrade.desc.bullet2': `Páginas personalizadas de clientes `,
	'upgrade.desc.bullet2.desc': `Cree una experiencia personalizada que sus clientes vean cuando escanen. `,
	'upgrade.desc.bullet3': `Analítica `,
	'upgrade.desc.bullet3.desc': `Comprenda mejor a su cliente con el análisis detallado para determinar cuándo y cómo se está utilizando su código. `,
	'usage-meter.desc.you-have-scanned': `Tus códigos han tenido `,
	'usage-meter.desc.times': `Total de exploraciones este mes `,
	'usage-meter.desc.low': `Buen trabajo. `,
	'usage-meter.desc.med': `¿Tienes suficientes escaneos para terminar el mes? Actualizar para más. `,
	'usage-meter.desc.high': `No te quedan muchas exploraciones. Actualice para asegurarse de que no se agotará! `,
	'usage-meter.desc.max': `¡Oh, no! Has utilizado todos los escaneos disponibles para este mes. Sus menús ya no aparecerán cuando se escanean sus códigos. Actualice ahora para aumentar inmediatamente su límite de escaneo o esperar hasta el próximo mes. `,
	'usage-meter.title': `Nivel de usuario `,

	// items added 2021-11-30 10:48:00.935158
	'dashboard.qr-modal.qr-name-label': `Dale un nombre a tu código QR un nombre `,
	'dashboard.qr-modal.qr-name-placeholder': `Menús de alimentos `,
	'dashboard.qr-modal.qr-description-label': `Descripción (opcional) `,
	'dashboard.qr-modal.qr-description-placeholder': `Menús de comida de la noche, versión PDF ... `,
	'dashboard.qr-modal.qr-url-label': `Agregue una URL personalizada única para generar su código QR `,
	'dashboard.qr-modal.qr-desc1': `Esta URL identifica de forma única su código, y solo se puede usar una vez por cuenta. Cualquier cambio en esta URL cambiará el propio código QR. `,
	'dashboard.qr-modal.qr-desc2': `Solo se permiten letras, números, guiones y guiones bajos. `,
	'dashboard.qr-modal.just-a-sec': `Solo un segundo... `,
	'dashboard.qr-modal.create': `Crear `,
	'dashboard.qr-modal.upload-success': `¡Cargado correctamente! `,

	// items added 2021-12-01 18:52:42.261988
	'dashboard.button.upgrade-account': `Actualizar ahora`,
	'signup-offer.btn.no-thanks': `No, gracias, lo decidiré más tarde.`,
	'banner.free-month.text': `¡Consigue un mes gratis!`,
	'menu-landing.limited': `La cuenta es limitada, por favor actualiza`,

	// items added 2021-12-15 14:19:08.947063
	'signup.form.last.placeholder': `Ultimo`,
	'upgrade.free.label0': `Marca ESC en las páginas de destino`,
	'upgrade.starter.label0': `No hay marca ESC en la página de destino`,
	'upgrade.pro.label0': `No hay marca ESC en la página de destino`,
	'upgrade.buttons.manage-account': `Administrar cuenta`,

	// items added 2021-12-26 16:29:27.751015
	'print.button.download': `Descargar`,
	'print.templates.title': `Diseñe visualmente QR impresión de plantillas`,
	'print.button.upgrade': `Actualizar a Pro para diseñar plantillas QR`,
	'print.templates.description': `¡Le proporcionamos una agradable plantilla de 4 'x 6' para imprimir el código para que pueda comenzar fácilmente! Haga fácilmente su propio diseño con Visual Builder.`,

	// items added 2021-12-26 22:24:30.723048
	'home.preview.banner4.1': `Imprimir diseño QR`,
	'home.preview.banner4.2': `¡Ningún diseñador necesita!`,
	'upgrade.pro.label9': `Diseñador visual para imprimir QR`,
	'upgrade.pro.tooltip9': `¡Elija entre plantillas QR realizadas para imprimir y personalizarlo fácilmente con sus necesidades, ¡no se necesita diseñador!`,

	// items added 2021-12-26 22:33:26.655250

	// items added 2022-05-16 12:52:15.923379
	'home.preview.bullet1.1': `Mostrar`,
	'upgrade.free.label5': `Financiado por la publicidad`,
};