import { IDbModel } from "./dbModel";

export interface IQrCode extends IDbModel {
    name: string;
    description: string;
    userId: string;
    identifier: string;
    isDeleted?: boolean;
}

export const QrCodeProps: Record<keyof Required<IQrCode>, keyof Required<IQrCode>> = {
    id: "id",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    name: "name",
    description: "description",
    userId: "userId",
    identifier: "identifier",
    isDeleted: "isDeleted",
};
