export const RouteComponents = {
    /**
     * The route component used for QR code urls.
     */
    Links: "links",
    /**
     * The route component hosting the menu landing page.
     */
    Menus: "menus",
}

/**
 * The website domain.
 */
export const DOMAIN = "https://eatsafecodes.com";

 /**
 * Where the QR code points to.
 */
export function getQrLink(userId: string, identifier: string) {
    return `${DOMAIN}/${RouteComponents.Links}/${userId}/${identifier}`;
}

/**
 * The domain-relative route to the menu landing page. This should ONLY be as an internal route, NOT as the URL of a QR code.
 */
export function getQrMenuRoute(userId: string, identifier: string) {
    return `/${RouteComponents.Menus}/${userId}/${identifier}`;
}

/**
 * The link to use when the customer is testing. It skips the redirect so we don't account for this as a code scan.
 */
export function getQrLinkWithoutRedirect(userId: string, identifier: string) {
    return getFullUrl(getQrMenuRoute(userId, identifier));
}

/**
 * To go from /home -> https://eatsafecodes.com/home
 */
export function getFullUrl(route: string, testDomain?: string) {
    return `${testDomain || DOMAIN}${route}`;
}
