import { AppLanguage } from "./lang";

export type PriceId = "pro_yearly" | "pro_monthly" | "starter_yearly" | "starter_monthly";
export const billingTiers = ["basic", "starter", "pro"];
//export type BillingTier = typeof billingTiers[number];
export type BillingTier = "basic" | "starter" | "pro";
export type BillingInterval = "month" | "year";

export type CustomerId = string; // "cus_[guid]"

/**
 * Stripe has entirely separate objects for test data and live data. There's a test customer object,
 * and a live customer object, and so on. Admins can enable isTest to use the test objects.
 */
interface IBillingRelatedRequest {
    /**
     * Use all test objects.
     */
    isTest: boolean;
}

interface IBillingPricingDetails {
    tier: BillingTier;
    interval: BillingInterval;
}

interface IBillingPricing {
    MonthlyPlan: number,
    AnnualPlan: number
}

export interface IFetchCheckoutSessionRequest extends IBillingRelatedRequest {
    price_id: PriceId;
    success_url: string;
    cancel_url: string;
    locale: AppLanguage;
    userEmail: string; // to pre-populate
    /**
     * Enables the $0/month subscription price for Pro.
     */
    enableFreeSubscription: boolean;
    /**
     * Enable a free trial of X days, after which the trial ends
     * (and a new billing cycle starts).
     */
    trialPeriodDays?: number;
    billingTier: string;
    interval: string;
}

export interface ICustomerPortalRequest extends IBillingRelatedRequest {
    customerId: CustomerId;
    returnUrl: string;    
}

export interface ICustomerPortalResponse {
    portalUrl: string;
}

export interface ICustomerEmailChangeRequest extends IBillingRelatedRequest {
    newEmail: string;
}

export enum MONTHLY_PRICE {
    MonthlyPlan = 24.99,
    AnnualPlan = 19.99,
}

export const PRICE_ID_DETAILS: Record<PriceId, IBillingPricingDetails> = {
    pro_yearly: {
        tier: "pro",
        interval: "year",
    },
    pro_monthly: {
        tier: "pro",
        interval: "month",
    },
    starter_yearly: {
        tier: "starter",
        interval: "year",
    },
    starter_monthly: {
        tier: "starter",
        interval: "month",
    },
};

export const PRICE_PER_TIER: Record<BillingTier, IBillingPricing> = {
    basic: {
        MonthlyPlan: 0,
        AnnualPlan: 0,
    },
    starter: {
        MonthlyPlan: 3.99,
        AnnualPlan: 2.99,
    },
    pro: {
        MonthlyPlan: 24.99,
        AnnualPlan: 19.99,
    },
};

// Note: updating this requires re-deploying the functions that depend on it
export const MAX_SCANS_PER_TIER: Record<BillingTier, number> = {
    basic: 2000,
    starter: 10000,
    pro: 100000,
};

export const MAX_TARGETS_PER_TIER: Record<BillingTier, number> = {
    basic: 3,
    starter: 10,
    pro: 100,
};

export const MAX_CODES_PER_TIER: Record<BillingTier, number> = {
    basic: 2,
    starter: 5,
    pro: 25,
};

export const MAX_FILE_UPLOADS_PER_TIER: Record<BillingTier, number> = {
    basic: 10,
    starter: 100,
    pro: 1000,
};

export interface IGetScanInfoRequest {
    userId: string;
}

export interface IGetScanInfoResponse {
    exceededTierLimit: boolean;
    billingTier: BillingTier;
    scanCount?: number;
    shouldShowAds: boolean;
    isAccountDisabled?: boolean;
}
