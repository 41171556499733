/**
 * These functions should match the dynamic templates available in SendGrid.
 * https://mc.sendgrid.com/dynamic-templates
 */
interface IEmailTemplateData {
    id: string;
    templateId: string;
    data: any;
    campaignId?: any;
}

function currentYear() {
    return new Date().getFullYear();
}

function currentMonth() {
    return new Date().getMonth();
}

export const freeTierScanUsage: IEmailTemplateData = {
    id: "freeTierScanUsage",
    templateId: "d-b053c06e7c1d485fb7717af79c1a3a3e",
    data: (usagePercentage: number, name: string) => ({
        // NOTE: Arg names MUST match email template
        usagePercentage,
        name,
    }),
    campaignId: (thresholdPercentage: number) =>
        `free-tier-scan-usage-${thresholdPercentage}-${currentMonth()}-${currentYear()}`,
};

export const proTierScanUsage: IEmailTemplateData = {
    id: "proTierScanUsage",
    templateId: "d-3bcb663c8c224b96ba5d1c228709370c",
    data: (usagePercentage: number, name: string) => ({
        // NOTE: Arg names MUST match email template
        usagePercentage,
        name,
    }),
    campaignId: (thresholdPercentage: number) =>
        `pro-tier-scan-usage-${thresholdPercentage}-${currentMonth()}-${currentYear()}`,
};

export const welcomeEmail: IEmailTemplateData = {
    id: "welcomeEmail",
    templateId: "d-005ee673ee354801ab92b8d953265542",
    data: (name: string) => ({
        // NOTE: Arg names MUST match email template
        name,
    }),
    campaignId: () => `welcome-email-${currentMonth()}-${currentYear()}`,
};

export const getTemplateById: any = (id: string) => {
    switch (id) {
        case "freeTierScanUsage":
            return freeTierScanUsage;
        case "proTierScanUsage":
            return proTierScanUsage;
        case "welcomeEmail":
            return welcomeEmail;
        default:
            return false;
    }
};
