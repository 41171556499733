import { OFFERS } from "../../../api/settings";
import { MAX_CODES_PER_TIER, MAX_SCANS_PER_TIER, MAX_TARGETS_PER_TIER } from "../../billing";
import { LanguageStrings } from "../lang-en";

export const ptText: Partial<LanguageStrings> = {
    

	// items added 2021-11-29 14:05:56.397657
	'nav.navigation-dropdown': `Navegação `,
	'error.contact-us-message': `Sinto muito, algo deu errado! Você se importaria de nos deixar saber? Nós gostaríamos de consertá-lo o mais cedo possível. Por favor entre em contato conosco diretamente em `,
	'home.title.title': `Menus de código QR dinâmico `,
	'home.title.subtitle': `Mantenha seus clientes seguros com menus digitais sem contato, que giram automaticamente. `,
	'home.title.make-code-button': `Faça um código `,
	'home.preview.title': `Simples. Rápido. Seguro. `,
	'home.preview.subtitle1': `Comer códigos seguros leva cerca de dois minutos para configurar. `,
	'home.preview.subtitle2': `Sério, é isso. `,
	'home.preview.subtitle3': `Com a nossa plataforma, você pode fazer códigos QR que permitirão ao seu restaurante `,
	'home.preview.bullet1.1': `Exibição `,
	'home.preview.bullet1.2': `menus múltiplos. `,
	'home.preview.bullet1.3': `Do mesmo QR Code `,
	'home.preview.bullet2.1': `Programação Menus. `,
	'home.preview.bullet2.2': `para aparecer em certos dias ou horários `,
	'home.preview.bullet3.1': `Personalize e projeto `,
	'home.preview.bullet3.2': `seus códigos QR e menu pousando páginas para corresponder à sua marca `,
	'home.preview.banner1.1': `Projeto `,
	'home.preview.banner1.2': `Adicione sua marca `,
	'home.preview.banner2.1': `Menus programados. `,
	'home.preview.banner2.2': `Gire automaticamente `,
	'home.preview.banner3.1': `Envio `,
	'home.preview.banner3.2': `Host seu menu `,
	'home.desc.title': `Como funciona? `,
	'home.desc.desc': `Comer códigos seguros permite fazer upload de vários menus digitais (PDF, JPEG, PNG, etc.) e torná-los acessíveis aos clientes através de códigos QR digitalizados na tabela. Isso significa que os clientes reduzem a disseminação potencial de patógenos de tocar menus físicos. Você pode definir horários para menus para girar automaticamente para que você possa ficar focado no que mais importa `,
	'home.desc.desc-bold': `Executando seu restaurante. `,
	'home.testimonial.text': `Nós fomos capazes de reduzir o nosso menu de papel desperdiçar uma tonelada, vendo como são todo o uso único. Impressão de menu usada para ter mais de 150 menus por dia. Agora estamos até talvez 100 a cada duas semanas. `,
	'home.testimonial.name': `- Drew, Escola Pública 818, Los Angeles, EUA `,
	'home.photos.title': `Preciso de mais? GO PRO. `,
	'home.photos.body1': `Desbloquear o poder total de comer códigos seguros com o `,
	'home.photos.body2-bold': `Projeto Pro. `,
	'home.photos.body3': `Descubra a Scan Analytics, personalize o design de seus códigos QR e crie as páginas de destino que os clientes vêem. Fazer parte da solução e ajudar a parar a propagação de `,
	'home.photos.body4': `COVID-19. `,
	'home.photos.button': `Confira nossos planos `,
	'home.benefits.title': `O mundo mudou. `,
	'home.benefits.subtitle': `Obtenha seus menus com os tempos `,
	'home.benefits.block1.title': `Proteger os clientes e funcionários `,
	'home.benefits.block1.body1': `Proteja os clientes que esperam uma experiência de jantar mais segura. Proteja a equipe que `,
	'home.benefits.block1.body2-bold': `arriscar sua saúde `,
	'home.benefits.block1.body3': `todos os dias para interagir com os clientes. `,
	'home.benefits.block2.title': `Salvar árvores, economizar dinheiro e economizar horas `,
	'home.benefits.block2.body1': `Salve em imprimir novos menus. Imprima e projete seus códigos QR `,
	'home.benefits.block2.body2-bold': `uma vez `,
	'home.benefits.block2.body3': `e, em seguida, atualize os menus do seu telefone ou computador a qualquer momento. `,
	'home.benefits.block3.title': `Girar automaticamente menus. `,
	'home.benefits.block3.body1': `Exibir café da manhã, almoço e menus de jantar em horários semanais flexíveis. Tem um menu de happy hour para terça-feira 5-7pm e sexta-feira 5-8pm? `,
	'home.benefits.block3.body2-bold': `Sem problemas. `,
	'home.benefits.block4.title': `Páginas de pouso elegantes simples para menus múltiplos `,
	'home.benefits.block4.body1': `Apresente o menu de jantar com uma lista de vinhos. Aperitivos diários com os menus semanais. Nossos menus se adaptam ao seu restaurante. `,
	'home.benefits.block4.body2-bold': `Tudo de um código `,
	'home.benefits.block4.body3': `. `,
	'home.support.title': `Obrigado por nos apoiar! `,
	'home.support.subtitle': `Ajude-nos a manter o mundo seguro. `,
	'faq.title.largetext': `Perguntas frequentes `,
	'faq.title.title': `perguntas frequentes `,
	'faq.video.title': `Tutorial Rápido `,
	'faq.button.to-dashboard': `Volte ao painel `,
	'faq.questions.q1': `O que é um código QR? `,
	'faq.questions.a1': `Um código de referência rápida (QR) consiste em quadrados pretos dispostos em uma grade quadrada em um fundo branco, que pode ser lido por um dispositivo de imagem, como a câmera no seu celular. Nossos serviços permitem que seus clientes vinculem rapidamente o menu do seu restaurante a codificações geradas exclusivamente para o seu negócio. Isso cria um método seguro e livre de toque para seus clientes acessar seus menus. `,
	'faq.questions.q2': `Como meus clientes usarão meu código QR? `,
	'faq.questions.a2': `A maioria dos smartphones modernos possui leitores QR embutidos. Para a maioria dos dispositivos, os usuários só precisam abrir sua câmera e centralizar o código (nenhuma foto necessária). Um pop-up aparecerá magicamente para pastorear seus clientes para o seu menu ou site. `,
	'faq.questions.q3': `Posso exibir vários menus do mesmo código QR de uma só vez? `,
	'faq.questions.a3': `Sim! Se vários menus estiverem configurados para aparecer, ele fornecerá ao seu cliente a opção de selecionar e navegar entre eles. Se apenas um estiver configurado para aparecer, o usuário será enviado diretamente para esse menu. `,
	'faq.questions.q4': `Posso agendar certos menus para aparecer em certos momentos? `,
	'faq.questions.a4': `Sim! A plataforma permite configurar menus para exibir um cronograma de complexidade arbitrária. Isso significa, por exemplo, que você pode configurar o brunch de fim de semana, café da manhã da semana, ofertas de bebida de uma hora feliz, e / ou especialidades diárias de jantar para aparecer em seus momentos apropriados - todos do mesmo código QR! Você também tem a capacidade de alternar os menus ON / OFF do painel móvel ou desktop do gerente para as alterações do menu fly. `,
	'faq.questions.q5': `O painel de gerenciamento funciona no celular? `,
	'faq.questions.a5': `Sim! Você é capaz de fazer edições em horários, ativar / desligar os menus, adicionar e excluir menus do seu dispositivo móvel. `,
	'faq.questions.q6': `E se meu cliente não tiver um leitor QR do smartphone? `,
	'faq.questions.a6': `A maioria dos dispositivos modernos possui leitores de código QR embutidos. Dito isto, todo o Android e iPhone têm aplicativos QR Reader que podem ser baixados gratuitamente. E como backup, é sempre útil ter um menu sobressalente disponível apenas no caso. `,
	'faq.questions.q8': `E se meu menu mudar? `,
	'faq.questions.a8': `Você é capaz de adicionar, editar, atualizar e excluir menus a qualquer momento com apenas alguns cliques. A plataforma aceita a maioria dos formatos de fotos, bem como fontes dinâmicas, como o URL do seu site. `,
	'faq.questions.q10': `Posso ter vários códigos QR diferentes definidos para diferentes restaurantes ou agendamentos de menu? `,
	'faq.questions.a10': `Claro pode! Atualmente, não há limite para o número de códigos QR que um usuário pode gerar. `,
	'faq.questions.q11': `E se eu excluir um código? Posso recuperá-lo? `,
	'faq.questions.a11': `Os códigos QR podem ser recuperados desde que o mesmo link de vaidade seja usado quando recriado. `,
	'faq.questions.q12': `Posso alterar a ordem em que os menus são mostrados aos clientes? `,
	'faq.questions.a12': `Com certeza! Basta arrastar e soltar menus no painel para reorganizar sua ordem de exibição. `,
	'faq.questions.pro.q1': `Como faço para atualizar? `,
	'faq.questions.pro.a1': `Você pode atualizar sua conta visitando a página de atualização. Note que você terá a chance de rever todas as acusações antes de finalizar os pagamentos. `,
	'faq.questions.pro.q2': `Posso cancelar minha assinatura para pro? `,
	'faq.questions.pro.a2': `Sim, a qualquer momento, visitando a seção de faturamento do seu perfil de usuário. Seu cancelamento entrará em vigor imediatamente e seus códigos QR não funcionarão mais. `,
	'faq.questions.pro.q3': `Qual é o custo? `,
	'faq.questions.pro.a3': `É grátis! Enquanto você ficar dentro de nossos generosos limites de uso, nossos serviços são totalmente gratuitos. Nós incluímos anúncios pequenos e discretos para este nível para que possamos oferecer este serviço sem custo. Se você deseja eliminar esses anúncios e desbloquear um monte de recursos úteis, considere a atualização para uma conta Pro. `,
	'faq.questions.pro.q4': `O que está incluído na versão inicial? `,
	'faq.questions.pro.a4': `Toda a funcionalidade básica para exibir seu menu está incluída na versão inicial! Para ver as limitações dessa opção, por favor, veja os limites de nível. `,
	'faq.questions.pro.q5': `O que posso personalizar? `,
	'faq.questions.pro.a5': `Nossa opção Pro permite que você personalize quase tudo! Você pode projetar seu próprio código, editar a aparência e adicionar logotipos personalizados. Você também pode editar a página de destino que seus clientes verão para que seja personalizado para o seu negócio. `,
	'faq.questions.pro.q6': `Quais são os limites? `,
	'faq.questions.pro.a6': `Por favor, veja a página de atualização para visualizar os limites de nível para verificação, uploads de menu e muito mais. `,
	'faq.questions.pro.q7': `O que acontece se eu exceder o limite? `,
	'faq.questions.pro.a7': `Você não será bloqueado da sua conta se exceder um limite. No entanto, a primeira vez que você exceder sua contagem de varredura permitida, terá duas semanas antes que os menus sejam pausados. Para continuar, você deve atualizar para o nível apropriado. `,
	'faq.questions.pro.q8': `Existe um limite para quantos códigos eu posso criar? `,
	'faq.questions.pro.a8': `sim. Por favor, referência aos níveis na página de atualização. É importante notar que os códigos arquivados contam para esse limite. `,
	'faq.questions.pro.q9': `Existe um limite para quantos menus eu posso criar? `,
	'faq.questions.pro.a9': `sim. Por favor, encontre o limite de menu na tabela do plano. Você pode excluir menus para ficar abaixo do limite do seu nível. `,
	'faq.questions.pro.q10': `Existe um limite para quantos uploads de arquivo de menu eu posso fazer por código? `,
	'faq.questions.pro.a10': `sim. Por favor, encontre o limite de upload do arquivo na tabela do plano. Mesmo se os menus são mais tarde excluídos, eles ainda vão contar para o total total. `,
	'faq.questions.pro.q11': `Como os pagamentos funcionam? `,
	'faq.questions.pro.a11': `Por acusações anuais, você será cobrado uma vez a cada 365 dias a partir dos dados da primeira assinatura. Para cobranças mensais, você será cobrado a cada 30 dias dos dados da primeira assinatura. Seu cartão de crédito será cobrado automaticamente, a menos que você cancele sua assinatura. Não somos capazes de fornecer reembolsos parciais para períodos não utilizados. Todas as transações serão consideradas finais. `,
	'faq.questions.pro.q12': `Em que moeda posso pagar? `,
	'faq.questions.pro.a12': `Nossos parceiros de pagamento suportam múltiplas moedas, mas todos os preços estão listados nos dólares dos Estados Unidos. `,
	'signup.title.title': `Inscrever-se `,
	'signup.title.subtitle': `Não se preocupe, sua conta não expira e não queremos seu cartão de crédito. `,
	'signup.title.welcome': `Bem-vindo ao clube. `,
	'signup.form.email': `O email `,
	'signup.form.email.placeholder': `exemplo@email.com. `,
	'signup.form.password': `Senha `,
	'signup.form.password.placeholder': `Senha `,
	'signup.form.first': `Primeiro nome `,
	'signup.form.last': `Último nome `,
	'signup.form.company': `Empresa `,
	'signup.form.company.placeholder': `O melhor restaurante da cidade `,
	'signup.form.submit': `Inscrever-se `,
	'signup.redirect.text': `já é um membro? Entrar `,
	'signup.redirect.link': `aqui `,
	'signin.title.title': `Olá, velho amigo `,
	'signin.title.subtitle': `Entre, você? `,
	'signin.form.email': `O email `,
	'signin.form.email.placeholder': `exemplo@email.com. `,
	'signin.form.password': `Senha `,
	'signin.form.password.placeholder': `Senha `,
	'signin.form.submit': `Entrar `,
	'signin.form.reset-pwd.button': `Resetar minha senha `,
	'signin-form.reset-pwd.confirmation': `Obrigado! Se uma conta existir para este e-mail, enviamos um link para redefinir sua senha. `,
	'signin-form.reset-pwd.link': `Esqueça sua senha? `,
	'signin-form.reset-pwd.link.finish': `Volte para o login. `,
	'signin.redirect.text': `Não é um membro existente? Inscrever-se `,
	'signin.redirect.link': `aqui `,
	'dashboard.title.text1': `Bom trabalho `,
	'dashboard.title.text2': `Esses códigos estão mantendo o mundo saudável. `,
	'dashboard.button.text': `Crie um novo código QR `,
	'dashboard.button.upgrade': `Atualizar para Pro. `,
	'dashboard.get-started.get-started': `Iniciar! - `,
	'dashboard.get-started.get-started2': `Comece criando seu primeiro código QR e o arquivo que ele deve redirecionar. `,
	'dashboard.get-started.add-menus': `Este código não vincula a nenhum menus ainda! - `,
	'dashboard.get-started.add-menus2': `Clique aqui para adicionar seu primeiro menu. `,
	'dashboard.alerts.cant-verify': `Nós não fomos capazes de verificar suas credenciais de usuário. Por favor, faça o logout e re-log in. `,
	'dashboard.table-headers.Name': `Nome `,
	'dashboard.table-headers.Name.tooltip': `Este é o nome do menu que os clientes verão `,
	'dashboard.table-headers.Active.tooltip': `Defina isso para desativar este menu `,
	'dashboard.table-headers.Schedule': `Cronograma `,
	'dashboard.table-headers.Version': `Versão `,
	'dashboard.table-headers.Actions': `Ações `,
	'dashboard.pils.Always': `Sempre `,
	'dashboard.pils.Everyday': `Todo dia `,
	'dashboard.pils.Never': `Nunca `,
	'dashboard.pils.Weekdays': `Dias úteis `,
	'dashboard.pils.Weekends': `Finais de semana `,
	'dashboard.tooltips.header': `Gerenciar `,
	'dashboard.tooltips.pro-section.if-pro': `Pró `,
	'dashboard.tooltips.pro-section.if-basic': `Atualize para recursos pro `,
	'dashboard.tooltips.test-code': `Clique para testar seu código QR (com base no horário atual). `,
	'dashboard.tooltips.view-code': `Visualizar ou impressão de código `,
	'dashboard.tooltips.add-code': `Adicione um novo menu para o seu código `,
	'dashboard.tooltips.archive-code': `Arquivar este código `,
	'dashboard.tooltips.archive-code.text1': `Tem certeza de que quer arquivar o código `,
	'dashboard.tooltips.archive-code.text2': `? Este código e seus menus podem ser recuperados mais tarde, desde que nenhum código ativo tenha o mesmo URL (que é `,
	'dashboard.tooltips.design-code': `Projete seu código QR `,
	'dashboard.tooltips.design-landing-page': `Design QR Code Landing Page `,
	'dashboard.tooltips.view-analytics': `Visualizar Analytics de Uso `,
	'dashboard.tooltips.upgrade-to-access': `Atualize para acessar este recurso `,
	'dashboard.qr-modal.title': `Crie um novo código QR dinâmico `,
	'dashboard.qr-modal.qr-name-label': `Dê seu código QR um nome `,
	'dashboard.qr-modal.qr-name-placeholder': `Menus de comida `,
	'dashboard.qr-modal.qr-description-label': `Descrição (opcional) `,
	'dashboard.qr-modal.qr-description-placeholder': `Menus de comida da semana, versão de pdf ... `,
	'dashboard.qr-modal.qr-url-label': `Adicione um URL personalizado exclusivo para gerar seu código QR `,
	'dashboard.qr-modal.qr-desc1': `Este URL identifica exclusivamente seu código e só pode ser usado uma vez por conta. Qualquer alteração a este URL alterará o próprio código QR. `,
	'dashboard.qr-modal.qr-desc2': `Apenas letras, números, hífens e sublinhados são permitidos. `,
	'dashboard.qr-modal.just-a-sec': `Só um segundo... `,
	'dashboard.qr-modal.create': `Crio `,
	'print.button.print-preview': `Imprimir Preview. `,
	'print.toggle.title': `Imprimir apenas QR Code `,
	'print.toggle.description': `Nós fornecemos um bom modelo de 4 'x 6' para imprimir o código para que você possa facilmente começar! Se você preferir imprimir apenas o código e projetar-se, ative isso. `,
	'print.template.header': `Estamos colocando sua saúde primeiro. `,
	'print.template.subheader': `Digitalize para um menu sem toque. `,
	'print.template.instruction1': `Abra a câmera do seu telefone e centralize o código no visor. `,
	'print.template.instruction2': `Toque no pop-up `,
	'add-target.title': `Crie um novo menu para o seu código QR `,
	'add-target.name': `Nome do menu (os clientes verão isso!) `,
	'add-target.name.placeholder': `Menu Brunch `,
	'add-target.name.desc': `Este é o título de revestimento público que os clientes verão! `,
	'add-target.description': `Descrição (opcional) `,
	'add-target.description.placeholder': `Menus de comida da semana, versão de pdf ... `,
	'add-target.description.desc': `Esta é a descrição voltada para o público que os clientes verão! É altamente recomendável fornecendo um. `,
	'add-target.schedule': `Agendamento de menu `,
	'add-target.schedule.dropdown.always': `Sempre use `,
	'add-target.schedule.dropdown.always.desc': `Menu será sempre aparecer `,
	'add-target.schedule.dropdown.weekly': `Baseado em um horário semanal `,
	'add-target.schedule.dropdown.weekly.desc': `O código irá redirecionar para isso se alguém digitalizar o código QR dentro da programação semanal descrita abaixo. `,
	'add-target.schedule.dropdown.weekly.toggle': `Alternar tudo `,
	'add-target.schedule.dropdown.weekly.sun': `Domingo `,
	'add-target.schedule.dropdown.weekly.mon': `Segunda-feira `,
	'add-target.schedule.dropdown.weekly.tues': `terça `,
	'add-target.schedule.dropdown.weekly.wed': `quarta-feira `,
	'add-target.schedule.dropdown.weekly.thurs': `quinta-feira `,
	'add-target.schedule.dropdown.weekly.fri': `sexta-feira `,
	'add-target.schedule.dropdown.weekly.sat': `sábado `,
	'add-target.link-type.title': `Tipo de link `,
	'add-target.link-type.uploaded': `Um menu carregado `,
	'add-target.link-type.uploaded.placeholder': `Carregar o arquivo que o código QR deve redirecionar para. `,
	'add-target.link-type.uploaded.link': `Arraste e "solte o arquivo aqui ou clique para selecionar um `,
	'add-target.link-type.uploaded.onDrag': `Soltar o arquivo aqui `,
	'add-target.link-type.uploaded.desc1': `Não se preocupe em conseguir isso perfeito agora. Você pode ligar o menu desse código QR dinâmico a qualquer momento. `,
	'add-target.link-type.uploaded.desc2': `Tipos de arquivos suportados `,
	'add-target.link-type.url': `Um URL do site existente `,
	'add-target.link-type.url.desc': `URL externo para redirecionar para `,
	'add-target.link-type.url.website-name': `Nome do site `,
	'add-target.link-type.url.website-name.placeholder': `Link do menu do restaurante `,
	'add-target.link-type.url.website-name.desc': `Os clientes não verão isso. É apenas para referência mais fácil do que um URL. `,
	'add-target.submit': `Crio `,
	'dashboard.archive.title': `Arquivar este código `,
	'dashboard.archive.text1': `Tem certeza de que quer arquivar o código `,
	'dashboard.archive.text2': `? Este código e seus menus podem ser recuperados mais tarde, desde que nenhum código ativo tenha o mesmo URL (que é `,
	'dashboard.archive.cancel-button': `Cancelar `,
	'dashboard.archive.archive-button': `Arquivo `,
	'dashboard.archived-codes.title': `Códigos arquivados. `,
	'dashboard.archived-codes.name': `NOME DE CÓDIGO `,
	'dashboard.archived-codes.url': `URL de código `,
	'dashboard.archived-codes.safe-recover': `Seguro para recuperar? `,
	'dashboard.archived-codes.recover': `RECUPERAR `,
	'profile.title': `Perfil de usuário `,
	'profile.section.email': `O email `,
	'profile.section.email.desc': `Necessário para atualizações de e-mail ou senha. `,
	'profile.section.current-password': `Senha atual `,
	'profile.section.current-password.placeholder': `Senha atual `,
	'profile.section.current-password.desc': `Necessário para atualizações de e-mail ou senha. `,
	'profile.section.new-password': `Nova Senha `,
	'profile.section.new-password.placeholder': `Nova Senha `,
	'profile.section.new-password.desc': `Necessário para atualizações de e-mail ou senha. Deve ter 6 caracteres de mais. `,
	'profile.section.confirm-new-password': `Confirme a nova senha `,
	'profile.section.confirm-new-password.placeholder': `Confirme a nova senha `,
	'profile.section.confirm-new-password.desc': `Necessário para atualizações de e-mail ou senha. `,
	'profile.section.first-name': `Primeiro nome `,
	'profile.section.last-name': `Último nome `,
	'profile.submit': `Atualizar `,
	'profile.subtext': `Volte para o `,
	'profile.subtext-link': `painel de controle `,
	'profile.billing.section-title': `Cobrança `,
	'profile.billing.redirect-button': `Visite o portal de faturamento `,
	'contact.title': `Entre em contato conosco `,
	'contact.name': `Seu nome `,
	'contact.email': `Endereço de e-mail `,
	'contact.email.placeholder': `you@email.com. `,
	'contact.phone': `Telefone `,
	'contact.company': `Empresa ou nome do restaurante `,
	'contact.company.placeholder': `Opcional `,
	'contact.subject.placeholder': `Olá! `,
	'contact.message': `Mensagem `,
	'contact.message.placeholder': `Diga-nos como podemos ajudar `,
	'contact.send': `Mandar `,
	'contact.send.tooltip': `Mal posso esperar pela sua mensagem `,
	'loading.20': `Carregando... `,
	'loading.40': `Carregando seus códigos ... `,
	'loading.80': `Carregando detalhes do código ... `,
	'dashboard.prompt.get-started': `Como eu começo? `,
	'dashboard.sign-out': `Sair `,
	'verify-email.title': `Por favor, verifique seu e-mail para um link de verificação `,
	'verify-email.subtext1': `Se você não conseguir vê-lo, por favor, verifique sua pasta de spam. Se você precisar atualizar seu e-mail, você pode fazer isso `,
	'verify-email.subtext2': `aqui `,
	'verify-email.subtext3': `Se você acredita que seu e-mail está correto e ainda não recebeu a confirmação, você pode reenviar clicando `,
	'verify-email.error': `Desculpe, algo deu errado! Por favor, entre em contato conosco para que possamos consertar isso. `,
	'verify-email.resend': `Ressentir com sucesso! `,
	'landing-page.default-title': `Receber! `,
	'landing-page.default-subtitle': `Visite qualquer link abaixo. Basta clicar em seu navegador para retornar aqui. `,
	'landing-page.pro.edit-button': `Editar `,
	'checkout.cancel.title': `Atualização cancelada `,
	'checkout.cancel.subtitle': `Nenhuma ação foi tomada `,
	'checkout.cancel.button': `Volte `,
	'checkout.success.title': `Bem-vindo ao Pro! `,
	'checkout.success.subtitle1': `Você atualizou com sucesso, Parabéns! `,
	'checkout.success.subtitle2': `Este é um compromisso com a experiência de jantar mais segura. `,
	'checkout.success.subtitle3': `Obrigado por confiar em nós com seus menus digitais. `,
	'checkout.success.button': `Veja o seu Dashboard Pro `,
	'upgrade.title.1': `Encontre o `,
	'upgrade.title.2': `plano perfeito `,
	'upgrade.title.3': `para seu negócio `,
	'upgrade.free.name': `Sem custos `,
	'upgrade.free.priceNote': `Nenhum cartão de crédito requerido `,
	'upgrade.free.label1': `Criar menus de código QR dinâmico ${MAX_CODES_PER_TIER["basic"]} `,
	'upgrade.free.label2': `${MAX_SCANS_PER_TIER["basic"]} Scans mensais. `,
	'upgrade.free.tooltip2': `Medido com base em varreduras em todos os códigos QR no mês atual `,
	'upgrade.free.label3': `Menus ${MAX_TARGETS_PER_TIER["basic"]} do mesmo código `,
	'upgrade.free.tooltip3': `Defina vários menus para exibir do mesmo código QR. `,
	'upgrade.free.label4': `Rotação automática de menu `,
	'upgrade.free.tooltip4': `Configure uma programação semanal. Menu de brunch apenas nas manhãs de domingo? Sem problemas. `,
	'upgrade.starter.name': `Iniciante `,
	'upgrade.starter.priceNote': `Ao selecionar um plano anual. Um plano mensal está disponível para `,
	'upgrade.starter.label1': `Criar menus de código QR dinâmico ${MAX_CODES_PER_TIER["starter"]} `,
	'upgrade.starter.label2': `${MAX_SCANS_PER_TIER["starter"]} Scans mensais. `,
	'upgrade.starter.tooltip2': `Medido com base em varreduras em todos os códigos QR no mês atual `,
	'upgrade.starter.label3': `Menus de até ${MAX_TARGETS_PER_TIER["starter"]} do mesmo código `,
	'upgrade.starter.tooltip3': `Defina vários menus para exibir do mesmo código QR. `,
	'upgrade.starter.label4': `Rotação automática de menu `,
	'upgrade.starter.tooltip4': `Configure uma programação semanal. Menu de brunch apenas nas manhãs de domingo? Sem problemas. `,
	'upgrade.pro.name': `Pró `,
	'upgrade.pro.priceNote': `Ao selecionar um plano anual. Um plano mensal está disponível para `,
	'upgrade.pro.label1': `Todos os recursos gratuitos `,
	'upgrade.pro.tooltip1': `Obtenha todos os recursos gratuitos sem os anúncios! `,
	'upgrade.pro.label2': `Criar menus de código QR dinâmico ${MAX_CODES_PER_TIER["pro"]} `,
	'upgrade.pro.label3': `${MAX_SCANS_PER_TIER["pro"]} Scans mensais `,
	'upgrade.pro.tooltip3': `Medido com base em varreduras em todos os códigos QR no mês atual `,
	'upgrade.pro.label4': `Sempre livre de anúncios `,
	'upgrade.pro.tooltip4': `Seus clientes nunca verão anúncios. Obrigado por nos apoiar! `,
	'upgrade.pro.label5': `Códigos QR de marca `,
	'upgrade.pro.tooltip5': `Personalize as cores de cada QRCode, melhore a qualidade do reconhecimento de digitalização e adicione um logotipo! `,
	'upgrade.pro.label6': `Páginas de aterrissagem de menu de marca `,
	'upgrade.pro.tooltip6': `Personalize o design da página de destino de cada código para combinar com a marca do seu restaurante. `,
	'upgrade.pro.label7': `Scan Analytics. `,
	'upgrade.pro.tooltip7': `Acompanhe quantos patronos estão digitalizando seu código e quais menus eles selecionam `,
	'upgrade.pro.label8': `Suporte dedicado `,
	'upgrade.pro.tooltip8': `Alcance o suporte mais rápido com uma plataforma de contato apenas para usuários profissionais `,
	'upgrade.enterprise.name': `Empreendimento `,
	'upgrade.enterprise.label1': `Menus de código QR dinâmico ilimitado `,
	'upgrade.enterprise.label2': `Todos os benefícios do pro `,
	'upgrade.enterprise.tooltip2': `Tudo de pro sem limitações `,
	'upgrade.enterprise.label3': `Desenvolvimento e recursos personalizados `,
	'upgrade.enterprise.tooltip3': `Aproveite ao máximo seus códigos QR com desenvolvimento de recursos personalizados `,
	'upgrade.enterprise.label4': `Suporte VIP. `,
	'upgrade.enterprise.tooltip4': `Acesso direto ao Comer Equipe de Códigos Seguros `,
	'upgrade.misc.perMonth': `por mês `,
	'upgrade.misc.enterprise': `Estenda a mão para determinar o melhor negócio para o seu negócio `,
	'upgrade.misc.after-trial': `Após teste gratuito, cancele ou altere o plano a qualquer momento `,
	'upgrade.misc.current-plan': `Plano atual `,
	'upgrade.buttons.free.purchase': `Selecione plano gratuito `,
	'upgrade.buttons.free.signup': `Inscreva-se de graça! `,
	'upgrade.buttons.starter.purchase': `Comece com a partida `,
	'upgrade.buttons.starter.annual': `Compre Plano Anual `,
	'upgrade.buttons.starter.monthly': `Compre plano mensal `,
	'upgrade.buttons.pro.purchase': `Comece com Pro `,
	'upgrade.buttons.pro.annual': `Compre Plano Anual `,
	'upgrade.buttons.pro.monthly': `Compre plano mensal `,
	'upgrade.buttons.signup-offer.monthly': `Iniciar teste gratuito `,
	'upgrade.buttons.enterprise': `Entre em contato conosco `,
	'signup-offer.title': `Antes de levá-lo para a versão gratuita, experimente a nossa opção mais popular gratuitamente para ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} dias! `,
	'signup-offer.desc.p1': `Não tenho certeza se pro é certo para você? Nós entendemos. Como um novo membro, por favor desfrute de um mês livre de recursos profissionais e limites de varredura muito maiores. Tire o máximo proveito do seu código QR hoje, incluindo páginas de destino, analíticos de varredura e muito mais. `,
	'signup-offer.desc.p2-a': `Note que se você não iniciar uma avaliação gratuita e continuar com a versão gratuita, você ainda terá `,
	'signup-offer.desc.p2-b': `Dias de experiências livres de anúncios `,
	'signup-offer.btn.decline': `Não, obrigado, leve-me para a versão gratuita. `,
	'signup-offer.starter.title': `Experimente nosso plano pro mais popular gratuitamente para ${OFFERS.SIGNUP_OFFER_TRIAL_IN_DAYS} dias! `,
	'signup-offer.starter.desc.p1': `Não tenho certeza se pro é certo para você? Nós entendemos. Como um novo membro, por favor desfrute de um mês livre de recursos profissionais e limites de varredura muito maiores. Tire o máximo proveito do seu código QR hoje, incluindo páginas de destino, analíticos de varredura e muito mais. `,
	'signup-offer.starter.desc.p2-a': `Ao tentar o plano de partida, você estará perdendo `,
	'signup-offer.starter.desc.p2-b': `Dias de experiências livres de anúncios `,
	'signup-offer.starter.btn.decline': `Eu gostaria de experimentar o plano de partida `,
	'pro-announcement.header.1': `Anunciando `,
	'pro-announcement.btn1': `Aprenda sobre Pro. `,
	'pro-announcement.p1': `Ótima notícia - estamos crescendo! Queremos sinceramente obrigado por ajudar a nos tornar o provedor de menu digital # 1 e fazer restaurantes um espaço mais seguro para jantar nestes tempos difíceis. Estamos animados para anunciar que hoje estamos liberando nosso novo Pro nível com uma tonelada de novos recursos! `,
	'pro-announcement.p2': `O Pro nível apoiará nossos custos operacionais e nos permitirá servir melhor aos nossos usuários dedicados. Esperamos que você perceba vários recursos altamente solicitados quando você atualiza, como design personalizável de códigos QR e páginas de destino do menu. Espere ver mais recursos destinados a Pro membros. `,
	'pro-announcement.p3': `Para o custo de um Entreé por mês, você pode atualizar para pro hoje. Uma assinatura anual também está disponível em um desconto de 20%. Comer com segurança não é uma tendência - seus clientes esperam um menu mais seguro por um longo tempo vir, e juntos estaremos lá para fornecer a experiência de alta qualidade. `,
	'pro-announcement.p4': `Cada camada também terá limites no total de códigos QR e varreduras mensais. Por favor, reveja estes limites para que você possa decidir se o profissional é ideal para você. Em 4 de outubro, os limites de uso para cada camada serão ativados. Não se preocupe - se você já exceder o limite, seus códigos não serão excluídos e seu código QR continuará a funcionar. `,
	'pro-announcement.p5': `Além disso, no dia 4 de outubro, estaremos introduzindo anúncios discretos nas páginas de desembarque no menu no nível livre. `,
	'pro-announcement.p6': `Se você deseja remover esses anúncios da visualização do seu cliente, desbloquear novos recursos valiosos e invista na plataforma Segreamente, considere a atualização! Para detalhes completos sobre preços, características e limites, visite nossa página de preços em `,
	'pro-announcement.p7': `Somos tão gratos pelo seu investimento em nós e além de animado para este novo capítulo de comer códigos seguros. Envie-nos feedback ou apenas diga olá a qualquer momento em `,
	'banner.free-trial.text': `Receba um mês grátis de Pro! `,
	'checkout.canceled': `Checkout cancelado `,
	'checkout.no-action': `Nenhuma ação foi tomada `,
	'pro-features.1.title': `Códigos QR de marca `,
	'pro-features.1.description': `QR Codes não precisam parecer o mesmo. Faça o seu se destacar e impressionar seus clientes. Adicione cores, melhore a correção de erros e até mesmo adicione o logotipo da sua empresa no código. `,
	'pro-features.2.title': `Páginas de aterrissagem de menu de marca `,
	'pro-features.2.description': `Quando vários menus são mostrados, forneça aos clientes uma página de destino de marca que corresponda aos seus temas e imagem. `,
	'pro-features.3.title': `Analytics de código `,
	'pro-features.3.description': `Monitore com que frequência seus códigos estão sendo digitalizados com métricas de escaneamento de hora a hora. `,
	'pro-features.4.title': `PRO UNAGE LIMITS. `,
	'pro-features.4.description': `Pare de se preocupar em bater no seu limite. Digitalize até 100.000 vezes a cada ano. Preciso de mais? Vamos conversar e descobrir hoje. `,
	'pro.success.welcome': `Bem-vindo ao `,
	'pro.success.congrats': `Você atualizou com sucesso - Parabéns! `,
	'pro.success.body': `A inscrição para pro é um compromisso com uma experiência de jantar mais segura, e queremos apoiá-lo nesse compromisso a cada passo do caminho. Como um membro Pro, estamos focados em você. Pro membros merecem uma experiência acessível e constantemente melhorando, e esperamos que você comece a perceber que hoje com vários recursos que você acabou de desbloquear. `,
	'pro.success.button': `Veja o seu Dashboard Pro `,
	'pro.limit.header': `Você atingiu seu limite. `,
	'pro.limit.upgrade': `Você precisará atualizar para fazer isso. `,
	'pro.limit.button': `Atualize agora `,
	'menu-landing.message': `Não parece ser um menu disponível agora. `,
	'menu-landing.scans1': `Você excedeu seu limite de uso de `,
	'menu-landing.scans2': `digitaliza nos últimos 30 dias. Você tem atualmente `,
	'menu-landing.scans3': `digitaliza nos últimos 30 dias. Por favor, entre em contato conosco se você precisar de um limite de nível mais alto! `,
	'menu-landing.scans4': `digitaliza nos últimos 30 dias. Atualize para Pro para um limite de uso mais alto! `,
	'menu-landing.exceeeded': `Você excedeu o limite de digitalização para o seu nível. `,
	'edit-code.EC_DESCRIPTION': `Ative um código QR de maior fidelidade para melhorar a correção de erros em caso de dano ao código. `,
	'edit-code.label.foreground': `Cor do primeiro plano `,
	'edit-code.label.background': `Cor de fundo `,
	'edit-code.label.errorMessage': `Erro ao criar URL de objeto do logotipo enviado `,
	'edit-code.label.customize': `Personalize seu estilo de código. `,
	'edit-code.quality.M': `Padrão `,
	'edit-code.quality.H': `Melhor `,
	'edit-code.squares': `Praças `,
	'edit-code.dots': `Pontos `,
	'edit-code.padding': `Preenchimento `,
	'edit-code.logo.title': `Adicione seu logotipo de negócios em seu código! `,
	'edit-code.logo.warning': `O arquivo deve ser uma imagem (.png, .jpg, .jpeg) e ter um tamanho máximo de 2MB `,
	'edit-code.logo.sizeTitle': `Tamanho do logotipo `,
	'edit-code.logo.opacityTitle': `Opacidade do logotipo `,
	'edit-code.saveError': `Algo deu errado salvando o design de código personalizado `,
	'edit-code.button.save': `Salve  `,
	'edit-code.button.back': `Volte `,
	'landing-editor.label.background': `Fundo `,
	'landing-editor.label.primary': `Texto primário `,
	'landing-editor.label.menu': `Cardápio `,
	'landing-editor.label.menuHover': `Menu (no Hover / clique) `,
	'landing-editor.label.menuTitle': `Título do menu `,
	'landing-editor.label.menuDesc': `Descrição do menu `,
	'landing-editor.label.header': `Cabeçalho `,
	'landing-editor.placeholder.header': `Receber! `,
	'landing-editor.label.desc': `Descrição `,
	'landing-editor.placeholder.desc': `Visite qualquer link abaixo. Basta clicar em seu navegador para retornar aqui. `,
	'landing-editor.label.font': `Fonte `,
	'landing-editor.label.logo': `Logotipo `,
	'landing-editor.formTextBlocks.logo': `Carregar um logotipo de 1MB ou menos! `,
	'landing-editor.button.save': `Salve  `,
	'pro-landing.back': `De volta ao painel `,
	'pro-landing.edit': `Editar `,
	'upgrade.btn.why-pro': `Por que escolher pro? `,
	'upgrade.desc.discover': `Descobrir `,
	'upgrade.desc.desc': `Com o nosso novo. `,
	'upgrade.desc.desc2': `Você pode personalizar a experiência do seu cliente para corresponder ao seu negócio. Além dos limites aumentados mencionados acima, você pode desbloquear os seguintes recursos `,
	'upgrade.desc.bullet1': `Códigos QR personalizados `,
	'upgrade.desc.bullet1.desc': `Adicione seu logotipo, personalize a aparência e mais! `,
	'upgrade.desc.bullet2': `Páginas de clientes personalizadas `,
	'upgrade.desc.bullet2.desc': `Crie uma experiência personalizada que seus clientes verão quando eles digitalizaram. `,
	'upgrade.desc.bullet3': `Analytics. `,
	'upgrade.desc.bullet3.desc': `Entenda melhor seu cliente com analítica detalhada para determinar quando e como seu código está sendo usado. `,
	'usage-meter.desc.you-have-scanned': `Seus códigos tiveram `,
	'usage-meter.desc.times': `Total de digitalizações este mês `,
	'usage-meter.desc.low': `Bom trabalho. `,
	'usage-meter.desc.med': `Você tem digitalizações suficientes para terminar o mês? Atualize para mais. `,
	'usage-meter.desc.high': `Você não tem muitas varreduras. Atualize para garantir que você não acabe! `,
	'usage-meter.desc.max': `Oh não! Você usou todos os exames disponíveis para este mês. Seus menus não aparecerão mais quando seus códigos são digitalizados. Atualize agora para aumentar imediatamente o seu limite de digitalização ou esperar até o próximo mês. `,
	'usage-meter.title': `Nível do usuário `,

	// items added 2021-11-30 10:48:26.419901
	'dashboard.qr-modal.upload-success': `Carregado com sucesso! `,

	// items added 2021-12-01 18:52:52.269593
	'dashboard.button.upgrade-account': `Atualize agora`,
	'signup-offer.btn.no-thanks': `Não, obrigado, vou decidir mais tarde.`,
	'banner.free-month.text': `Obtenha um mês grátis!`,
	'menu-landing.limited': `Conta é limitada, por favor atualize`,

	// items added 2021-12-15 14:19:16.374385
	'signup.form.last.placeholder': `Durar`,
	'upgrade.free.label0': `ESC Branding on Landing Pages`,
	'upgrade.starter.label0': `NENHUM ESC Branding On Landing Page`,
	'upgrade.pro.label0': `NENHUM ESC Branding On Landing Page`,
	'upgrade.buttons.manage-account': `Gerenciar conta`,

	// items added 2021-12-26 16:29:44.664337
	'print.button.download': `Download`,
	'print.templates.title': `Design visualmente Impressão QR de modelos`,
	'print.button.upgrade': `Atualize para Pro para projetar modelos QR`,
	'print.templates.description': `Nós fornecemos a você um bom modelo de 4 'x 6' para imprimir o código para que você possa facilmente começar! Facilmente faça seu próprio design com o Visual Builder.`,

	// items added 2021-12-26 22:24:44.132512
	'home.preview.banner4.1': `Imprimir QR Design.`,
	'home.preview.banner4.2': `Nenhum designer necessário!`,
	'upgrade.pro.label9': `Visual Designer para Imprimir QR`,
	'upgrade.pro.tooltip9': `Escolha entre modelos QR prontos para impressão e personalizá-lo facilmente às suas necessidades, nenhum designer necessário!`,

	// items added 2021-12-26 22:33:29.611418

	// items added 2022-05-16 12:52:24.490529
	'upgrade.free.label5': `Anúncio suportado`,
};