import { Settings } from "api";
import { getLogger } from "./errors";

export const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * The website domain in dev mode.
 */
export const TEST_DOMAIN = "http://localhost:3000";

/**
 * To go from /home -> https://eatsafecodes.com/home
 */
export function getFullUrl(route: string, allowLocalhostInTestMode = false) {
    
    if(window.location.origin) {
        return Settings.URL.getFullUrl(route, window.location.origin);
    }

    if (isLocalhost && allowLocalhostInTestMode) {
        return Settings.URL.getFullUrl(route, TEST_DOMAIN);
    }
    return Settings.URL.getFullUrl(route);
}

/**
 * Takes a parameterized url and replaces params with values.
 */
export function parameterize<T>(paramRoute: string, paramValues: Record<keyof T, string>) {
    const logAlert = (msg: string | Error) => getLogger("urls:parameterize").alert(msg, { paramRoute, paramValues });
    let s = paramRoute;
    try {
        for (const param of Object.keys(paramValues)) {
            s = s.replace(`:${param}`, paramValues[param as keyof T]);
        }
        if (s.includes(":")) {
            logAlert("Not all parameters replaced");
        }
    } catch (err) {
        logAlert(err);
    }
    return s;
}
