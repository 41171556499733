import { de, routesDe, routesTextDe } from "./lang-de";
import { en, LanguageStrings, RouteDeclaration, routesEn, routesTextEn } from "./lang-en";
import { es, routesEs, routesTextEs } from "./lang-es";
import { fr, routesFr, routesTextFr } from "./lang-fr";
import { it, routesIt, routesTextIt } from "./lang-it";
import { pt, routesPt, routesTextPt } from "./lang-pt";
import { AppLanguage } from "./types";

export const DEFAULT_LANGUAGE: AppLanguage = 'en';
export const DEFAULT_LANGUAGE_STRINGS: LanguageStrings = en;

export const LANGUAGE_MAP: Record<string, AppLanguage> = {
    en: "en",
    es: "es",
    pt: "pt",
    fr: "fr",
    it: "it",
    de: "de",
};

/**
 * General text
 */
export const LANGUAGE_STRINGS_MAP: Record<AppLanguage, Partial<LanguageStrings>> = {
    en: en,
    es: es,
    pt: pt,
    fr: fr,
    it: it,
    de: de,
};

/**
 * Language display names
 */
export const APP_LANGUAGE_NAMES_MAP: Record<AppLanguage, string> = {
    en: "English",
    es: "Español",
    pt: "Português",
    fr: "Français",
    it: "Italiano",
    de: "Deutsche",
};

/**
 * Routes
 */
export const APP_ROUTES_MAP: Record<AppLanguage, RouteDeclaration> = {
    en: routesEn,
    es: routesEs,
    pt: routesPt,
    fr: routesFr,
    it: routesIt,
    de: routesDe,
};

/**
 * Route display names
 */
export const APP_ROUTES_TEXTS_MAP: Record<AppLanguage, RouteDeclaration> = {
    en: routesTextEn,
    es: routesTextEs,
    pt: routesTextPt,
    fr: routesTextFr,
    it: routesTextIt,
    de: routesTextDe,
};

