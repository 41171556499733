import { AppLanguage } from "../api";
import { IDbModel } from "./models/dbModel";
import { BillingTier } from "./billing";

export type IUserRole = "user" | "admin";

export interface IUser extends IDbModel {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    role: IUserRole;
    locale?: AppLanguage;
    tosAccepted?: boolean;
    tosLastAcceptTs?: number;
    billingTier?: BillingTier;
    billingCustomerId?: string;
    billingInterval?: string;
    emailVerified?: boolean;
    totalFileUploads?: number;
    isDisabled?: boolean;
    synced?: boolean;
}

export const UserProps: Record<keyof Required<IUser>, keyof Required<IUser>> = {
    id: "id",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    email: "email",
    firstName: "firstName",
    lastName: "lastName",
    company: "company",
    role: "role",
    locale: "locale",
    tosAccepted: "tosAccepted",
    tosLastAcceptTs: "tosLastAcceptTs",
    billingTier: "billingTier",
    billingCustomerId: "billingCustomerId",
    emailVerified: "emailVerified",
    totalFileUploads: "totalFileUploads",
    isDisabled: "isDisabled",
    billingInterval: "billingInterval",
    synced: "synced",
};
