export function daysAsMillis(days: number) {
    return days /* days */ * 24 /* hours */ * 60 /* minutes */ * 60 /* seconds */ * 1000 /* millis */;
}

export function xDaysAgo(daysAgo: number) {
    const today = new Date();
    return new Date().setDate(today.getDate() - daysAgo);
}

export function xDaysAhead(daysAhead: number) {
    const today = new Date();
    return new Date().setDate(today.getDate() + daysAhead);
}

export function getFormattedDate(timestamp: number, format: string = "MM/DD/YYY") {
    const date = new Date();

    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2, "0"));

    if (format.indexOf("yyyy") > -1) 
        format = format.replace("yyyy", year.toString());
    else if (format.indexOf("YYYY") > -1) 
        format = format.replace("YYYY", year.toString());
    else if (format.indexOf("yy") > -1) 
        format = format.replace("yy", year.toString().substr(2, 2));

    format = format.replace("dd", date.getDate().toString().padStart(2, "0"));
    format = format.replace("DD", date.getDate().toString().padStart(2, "0"));

    let hours = date.getHours();
    if (format.indexOf("t") > -1) {
        if (hours > 11) format = format.replace("t", "pm");
        else format = format.replace("t", "am");
    }
    if (format.indexOf("HH") > -1) format = format.replace("HH", hours.toString().padStart(2, "0"));
    if (format.indexOf("hh") > -1) {
        if (hours > 12) hours = hours - 12;
        if (hours == 0) hours = 12;
        format = format.replace("hh", hours.toString().padStart(2, "0"));
    }
    if (format.indexOf("mm") > -1) format = format.replace("mm", date.getMinutes().toString().padStart(2, "0"));
    if (format.indexOf("ss") > -1) format = format.replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return format;
}
