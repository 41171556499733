import * as React from "react";

class ErrorBoundary extends React.Component {

    componentDidCatch(_error: Error, _info: any) {
        // log uncaught errors
        // this.airbrake.notify({
        //     error: error,
        //     params: { info: info, uncaught: true },
        // });
    }

    render() {
        // pass-through component
        return this.props.children;
    }
}

export default ErrorBoundary;
