export const UsersCollection = "Users";
export const CodesCollection = "Codes";
export const TargetsCollection = "Targets";
export const TargetVersionsCollection = "TargetVersions";
export const MailCollection = "mail";
export const CodeDesignsCollection = "Designs";
export const LandingPageCollection = "LandingPages";
export const ScanCheckCollection = "ScanCheck";
export const CodeTreeCollection = "CodeTree";
export const EmailCampaignsCollection = "EmailCampaigns";
export const StatsCollection = "Stats";
export const StatsScanCheckCollection = "ScanCheck";
export const StatsQrLinkCodeScannedCollection = "QrLinkCodeScanned";
export const StatsQrLinkCodeTargetsLoadedCollection = "QrLinkCodeTargetsLoaded";
export const StatsQrLinkMenuOpenedCollection = "QrLinkMenuOpened";
export const StatsMenuLandingOpenedCollection = "MenuLandingOpened";
export const StatsQrLinkMenuViewCollection = "StatsQrLinkMenuView";
;