import { URL } from "../settings";
import { LanguageStrings, RouteDeclaration } from "./lang-en";
import { itText } from "./siteText/it";

export const routesIt: RouteDeclaration = {
    "routes.index": "/",
    "routes.faq": "/domande",
    "routes.contact": "/contatto",
    "routes.terms": "/condizioni",
    "routes.about": "/di",
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/Iscriviti",
    "routes.unauth.sign-in": "/sign-in",
    "routes.auth.home": "/casa",
    "routes.auth.dashboard": "/pannello-di-controllo",
    "routes.auth.user-profile": "/profilo",
    "routes.auth.sign-out": "/disconnessione",
    "routes.pricing": "/aggiornare",
    "routes.pro-announcement": "/aggiornare/annuncio",
    "routes.auth.signup-offer": "/offerta-di-registrazione",
    "routes.auth.checkout.success": "/aggiornare/successo",
    "routes.auth.checkout.cancel": "/aggiornare/annullato",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.privacy": "/privacy",
    "routes.auth.analytics": "/analisi",
    "routes.auth.verify-email": "/verificare",
};

export const routesTextIt: RouteDeclaration = {
    "routes.index": "Pagina Iniziale",
    "routes.faq": "Domande Frequenti",
    "routes.contact": "Contattaci",
    "routes.terms": "Termini di Servizio",
    "routes.about": "A Proposito di ESC",
    "routes.unauth.home": "Pagina Iniziale",
    "routes.unauth.sign-up": "Iniziare",
    "routes.unauth.sign-in": "Accesso",
    "routes.auth.home": "Pagina Iniziale",
    "routes.auth.dashboard": "Pannello di Controllo",
    "routes.auth.user-profile": "Il Mio Profilo",
    "routes.auth.sign-out": "Disconnessione",
    "routes.pricing": "Aggiornare",
    "routes.auth.checkout.success": "Successo",
    "routes.auth.checkout.cancel": "Aggiornamento Annullato",
    "routes.auth.signup-offer": "Offerta di registrazione",
    "routes.privacy": "politica sulla riservatezza",
    "routes.pro-announcement": "Annuncio di Pro",
    "routes.auth.edit-menu": "Modifica pagina menu",
    "routes.auth.analytics": "Analisi dei menu",
    "routes.auth.verify-email": "Verifica Email",
};

export const it: Partial<LanguageStrings> = {
    ...routesIt,
    ...itText,
};
