import { URL } from "../settings";
import { baseStringsEnglish } from "./siteText/en";

/**
 * While languages can be applied internally for these routes, the language will not be in the url.
 */
const globalRoutes = {
    "routes.link.redirect": `/${URL.RouteComponents.Links}/:userId/:urlIdentifier`,
    "routes.link.menu": `/${URL.RouteComponents.Menus}/:userId/:urlIdentifier`,
    "routes.link.menu.ads": `/${URL.RouteComponents.Menus}/:userId/:urlIdentifier/ads`,
};

export type GlobalRoutes = keyof typeof globalRoutes;

export const routesEn = {
    "routes.index": "/",
    "routes.faq": "/faq",
    "routes.contact": "/contact",
    "routes.terms": "/terms",
    "routes.about": "/about",
    "routes.pricing": "/upgrade",
    "routes.pro-announcement": "/upgrade/announcement",
    "routes.privacy": "/privacy",
    /** Unauth Routes */
    "routes.unauth.home": "/",
    "routes.unauth.sign-up": "/sign-up",
    "routes.unauth.sign-in": "/sign-in",
    /** Auth Routes */
    "routes.auth.signup-offer": "/signup-offer",
    "routes.auth.verify-email": "/verify",
    "routes.auth.home": "/home",
    "routes.auth.dashboard": "/dashboard",
    "routes.auth.user-profile": "/profile",
    "routes.auth.sign-out": "/sign-out",
    "routes.auth.checkout.success": "/upgrade/success",
    "routes.auth.checkout.cancel": "/upgrade/cancel",
    "routes.auth.edit-menu": `/${URL.RouteComponents.Menus}/:userId/edit/:urlIdentifier`,
    "routes.auth.analytics": `/analytics`,
};

export type RouteDeclaration = typeof routesEn;
export type AppRoutes = keyof typeof routesEn;

export const routesTextEn: RouteDeclaration = {
    "routes.index": "Home",
    "routes.faq": "FAQ",
    "routes.contact": "Contact Us",
    "routes.terms": "Terms of Service",
    "routes.about": "About ESC",
    "routes.privacy": "Privacy Policy",
    /** Unauth Routes */
    "routes.unauth.home": "Home",
    "routes.unauth.sign-up": "Get Started",
    "routes.unauth.sign-in": "Sign In",
    /** Auth Routes */
    "routes.auth.verify-email": "Verify Email",
    "routes.auth.home": "Home",
    "routes.auth.dashboard": "Dashboard",
    "routes.auth.user-profile": "My Profile",
    "routes.auth.sign-out": "Sign Out",
    "routes.pricing": "Pricing",
    "routes.auth.signup-offer": "Signup Offer",
    "routes.pro-announcement": "Announcing Pro",
    "routes.auth.checkout.success": "Success",
    "routes.auth.checkout.cancel": "Upgrade Canceled",
    "routes.auth.edit-menu": "Edit Menu Page",
    "routes.auth.analytics": "Menu Analytics",
};

export const baseStrings = {
    ...routesEn,
    ...baseStringsEnglish,
};

// Simple way to apply types
export const appRoute = (route: AppRoutes) => route;
export const globalRoute = (route: GlobalRoutes) => globalRoutes[route];
export type LanguageStrings = typeof baseStrings;

export const en = baseStrings;
